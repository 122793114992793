import { OrderHistory } from '@components/account/OrderHistory';
import { AddFunds } from '@components/AddFunds';
import EmptyCard from '@components/cards/EmptyCard';
import InvestmentCard from '@components/cards/InvestmentCard';
import PortfolioCard from '@components/cards/PortfolioCard';
import { AssetValueChart } from '@components/charts/AssetValueChart';
import PageLayout from '@components/layouts/PageLayout';
import PageHeader from '@components/PageHeader';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useInvest } from '@contexts/InvestContext';
import { useIsMobile, useIsTab } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { ORDER_STATUS } from '@utils/constants';
import { IInvestment, IPaymentMethod } from '@utils/models';
import { theme } from '@utils/theme';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export default function PortfolioPage() {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const { user } = useAuth();
  const { getInvestments } = useInvest();
  const { getPaymentMethods } = useAxios();
  const [investments, setInvestments] = useState<IInvestment[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>();

  async function fetchInvestments() {
    const investments = await getInvestments();
    setInvestments(investments);
  }

  const fetchPaymentMethods = async () => {
    const accounts = await getPaymentMethods();
    setPaymentMethods(accounts);
    accounts && accounts.length > 0 && setPaymentMethod(accounts[0]);
  };

  useEffect(() => {
    user && fetchInvestments();
    user && fetchPaymentMethods();
  }, [user]);

  const memoizedTotalValue = useMemo(
    () =>
      Array.isArray(investments) &&
      investments
        .filter(({ orderStatus }) => orderStatus === ORDER_STATUS.COMPLETE)
        .map(({ numTokens, listing }) => numTokens * listing.token.price)
        .reduce((acc, cur) => acc + cur, 0),
    [investments]
  );

  const grouped = _.groupBy(investments, (investment) => investment.listing.id);
  const memoizedAggregated: IInvestment[] = useMemo(() => {
    const aggregated: IInvestment[] = [];

    for (const investment in grouped) {
      const totalTokens = grouped[investment]
        .filter(({ orderStatus }) => orderStatus === ORDER_STATUS.COMPLETE)
        .map(({ numTokens }) => numTokens)
        .reduce((acc, cur) => acc + cur, 0);

      if (totalTokens) {
        aggregated.push({ ...grouped[investment][0], numTokens: totalTokens });
      }
    }

    return aggregated;
  }, [grouped]);

  return (
    <PageLayout pageTitle="Portfolio">
      <Grid container sx={{ paddingX: isMobile || isTab ? '1rem' : '6rem' }}>
        <PageHeader title="Portfolio" />

        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              height: '13rem',
              marginBottom: '1.25rem',
              borderRadius: '0.25rem',
              backgroundColor: theme.palette.primary.main,
              overflow: 'hidden'
            }}
          >
            <PortfolioCard totalValue={memoizedTotalValue} />
            <AssetValueChart investments={investments} />
          </Grid>
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <Box
            p="1.5rem"
            mr="1.25rem"
            sx={{ backgroundColor: '#171717', border: '2px solid #323232' }}
          >
            <AddFunds />
            {!!memoizedTotalValue && (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    borderTop: '1px solid #FFFFFF4D',
                    pt: '1rem'
                  }}
                >
                  History
                </Typography>
                <OrderHistory />
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={isMobile ? 12 : 8}>
          {memoizedAggregated.length === 0 ? (
            <Box
              p="1.5rem"
              sx={{ backgroundColor: '#171717', border: '2px solid #323232' }}
            >
              <EmptyCard />
            </Box>
          ) : (
            <Box
              p="1.5rem"
              sx={{ backgroundColor: '#171717', border: '2px solid #323232' }}
            >
              <Typography variant="h5" mb="1.5rem">
                Your Assets
              </Typography>
              <Box display="flex" flexDirection="column">
                {memoizedAggregated?.map((investment, idx) => (
                  <InvestmentCard key={idx} investment={investment} />
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
}
