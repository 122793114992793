import PrimaryButton from '@components/buttons/PrimaryButton';
import GoogleMap from '@components/listings/GoogleMap';
import PropertyProfile from '@components/listings/PropertyProfile';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { GOOGLE_MAP_DEFAULT_CENTER } from '@utils/constants';
import {
  createGoogleMapLocationURL,
  formatAddress,
  openExternalURL
} from '@utils/helpers';
import { IAddress } from '@utils/models';

interface IProps {
  address: IAddress;
  profile: any;
}

export default function TheProperty({ address, profile }: IProps) {
  const isMobile = useIsMobile();
  const { latitude, longitude, street } = address;

  const getMapCenter = () => {
    if (latitude && longitude) {
      return [Number(latitude), Number(longitude)];
    }

    return GOOGLE_MAP_DEFAULT_CENTER;
  };

  const handleSeeInGoogle = () => {
    const locationURL = createGoogleMapLocationURL(latitude, longitude);
    openExternalURL(locationURL);
  };

  return (
    <Box mb={isMobile ? '3rem' : '4.25rem'}>
      <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
        The Property
      </Typography>

      <Typography
        variant={isMobile ? 'h5' : 'h2'}
        mb={isMobile ? '1rem' : '1.5rem'}
        color="#FFFFFF"
      >
        Explore the Asset Profile
      </Typography>

      <PropertyProfile profile={profile} />

      <Box mt={1} mb={2}>
        <GoogleMap
          location={{ lat: latitude, lng: longitude }}
          label={street}
          zoom={17}
          center={getMapCenter()}
        />
      </Box>

      <Typography
        variant="body1"
        fontWeight="600"
        mb="0.2rem"
        color="rgba(255, 255, 255, 0.5)"
      >
        Location
      </Typography>

      <Box
        display={!isMobile && 'flex'}
        flexDirection="row"
        justifyContent="space-between"
        marginBottom="2rem"
      >
        <Box width={isMobile ? '100%' : '60%'}>
          <Typography variant="body1" color="#FFFFFF">
            {formatAddress(address)}
          </Typography>
        </Box>

        <PrimaryButton
          style={{ marginTop: isMobile ? '2rem' : '-1rem' }}
          btnText="View On Map"
          onClick={handleSeeInGoogle}
          isOutlined
          isWide
        />
      </Box>
    </Box>
  );
}
