import PrimaryButton from '@components/buttons/PrimaryButton';
import TextInput from '@components/inputs/TextInput';
import AuthLayout from '@components/layouts/AuthLayout';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { EMAIL_REGEX } from '@utils/constants';
import { AUTH_ROUTES } from '@utils/routes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  email: string;
}

export default function LoginAccount() {
  const navigate = useNavigate();
  const { user, login } = useAuth();
  const isMobile = useIsMobile();
  const { control, handleSubmit, formState, setError } = useForm<IFormInput>({
    defaultValues: { email: '' },
    mode: 'onChange'
  });

  useEffect(() => {
    if (user?.isEmailVerified) {
      return navigate(AUTH_ROUTES.ACCREDITATION);
    }
  }, [user]);

  const onSubmit = async ({ email }: IFormInput) => {
    try {
      await login(email);
      navigate(AUTH_ROUTES.VERIFY_EMAIL);
    } catch (err: any) {
      setError('email', {
        message: err.response.data.message
      });
    }
  };

  return (
    <AuthLayout pageTitle="Login to OwnProp">
      <Box
        sx={{
          width: isMobile ? '100%' : '30rem',
          backgroundColor: '#171717',
          border: '2px solid #323232',
          padding: '2rem',
          borderRadius: '0.25rem'
        }}
      >
        <Typography variant="h3" mb="1rem" textAlign={'center'}>
          Login to OwnProp
        </Typography>
        <Typography variant="body1" mb="1.5rem" textAlign={'center'}>
          We're glad to have you back!
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ marginBottom: '2rem' }}>
            <TextInput
              name="email"
              placeholder="Email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Email is required'
                },
                maxLength: {
                  value: 50,
                  message: 'Email is too long'
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Email is invalid'
                }
              }}
              type="email"
              isSubmitFocus={true}
            />
          </Box>
          <Box textAlign="center">
            <PrimaryButton
              btnText="Next"
              formState={formState}
              isWide={!isMobile}
              isFullWidth={isMobile}
            />
          </Box>
        </form>

        <Typography variant="body1" mt="1.5rem" textAlign={'center'}>
          Don't have an account?{' '}
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => navigate(AUTH_ROUTES.GET_STARTED)}
          >
            Get started
          </span>
        </Typography>
      </Box>
    </AuthLayout>
  );
}
