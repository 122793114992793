import PrimaryButton from '@components/buttons/PrimaryButton';
import { Box, Typography } from '@mui/material';
import { IListing } from '@utils/models';
import { useNavigate } from 'react-router-dom';

interface IProps {
  listing: IListing;
}

export default function Congratulations({ listing }: IProps) {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant="h3"
        sx={{ marginBottom: '1.5rem', textAlign: 'center' }}
      >
        Congratulations!
      </Typography>
      <Box
        paddingX="3rem"
        paddingY="1.5rem"
        style={{
          width: '100%',
          borderRadius: '0.5rem',
          backgroundColor: '#FFFFFF',
          marginBottom: '1.875rem'
        }}
      >
        <Typography variant="body1" color="black">
          You are now part owner of {listing?.property?.name}
        </Typography>
      </Box>
      <PrimaryButton
        btnText="View Portfolio"
        onClick={() => navigate(`/portfolio`)}
        isOutlined
      />
    </Box>
  );
}
