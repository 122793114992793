import { useAuth } from '@contexts/AuthContext';
import { Box, Typography } from '@mui/material';
import { ACCREDITATION_STATUS } from '@utils/constants';
import { renderListingStatus } from '@utils/helpers';
import { IListing } from '@utils/models';

interface IProps {
  listing?: IListing;
  isInvestment?: boolean;
}

export default function StatusBadge({ listing, isInvestment }: IProps) {
  const { user } = useAuth();
  const isAccredited =
    (user && user.accreditationStatus === ACCREDITATION_STATUS.APPROVED) ||
    (user && user.isAccreditationVerified);

  function renderStatus() {
    if (listing) {
      return {
        text: renderListingStatus(listing, listing.token),
        color: 'white'
      };
    } else if (isInvestment) {
      return { text: 'Pending Close', color: '#FFC130' };
    } else {
      return {
        text: isAccredited ? 'Verified Investor' : 'Verify Identity',
        color: isAccredited ? '#3152F5' : '#FFC130'
      };
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '1.5rem' }}>
      <Typography
        variant="overline"
        sx={{
          textAlign: 'center',
          p: '0.25rem 0.375rem',
          borderRadius: '0.25rem',
          backgroundColor: renderStatus().color,
          color: listing ? 'black' : 'white'
        }}
      >
        {renderStatus().text}
      </Typography>
    </Box>
  );
}
