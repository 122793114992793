import { ReactComponent as ExpandMoreIcon } from '@assets/icons/chevron-down.svg';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';

interface IProps {
  name: string;
  label?: string;
  options: { label: string; value: string }[];
  control: any;
  rules?: RegisterOptions;
}

export default function DropdownInput({
  name,
  label,
  options,
  control,
  rules
}: IProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <>
          {!!label && <InputLabel sx={{ color: 'white' }}>{label}</InputLabel>}
          <Select
            fullWidth
            value={value}
            label={label}
            onChange={onChange}
            variant="standard"
            sx={{
              '&:before': {
                borderBottom: '2px solid white'
              },
              '&:hover': {
                borderBottom: '2px solid white'
              }
            }}
            inputProps={{ color: '#989898' }}
            IconComponent={() => (
              <Box>
                <ExpandMoreIcon width={34} height={34} stroke="white" />
              </Box>
            )}
          >
            {options.map(({ label, value }, idx) => (
              <MenuItem key={idx} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    />
  );
}
