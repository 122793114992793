import { ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

interface IProps {
  children: ReactNode;
}

export default function ScrollToTop({ children }: IProps) {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [location]);

  return <>{children}</>;
}
