import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus-blue.svg';
import PaymentButton from '@components/buttons/PaymentButton';
import PrimaryButton from '@components/buttons/PrimaryButton';
import SecondaryButton from '@components/buttons/SecondaryButton';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { IconButton, Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { openExternalURL } from '@utils/helpers';
import { IPaymentMethod } from '@utils/models';
import { EXTERNAL_ROUTES } from '@utils/routes';
import { useLayoutEffect, useState } from 'react';

interface IProps {
  paymentMethods: IPaymentMethod[];
  setPaymentMethods: (paymentMethods: IPaymentMethod[]) => void;
  selectedPaymentMethod?: IPaymentMethod;
  setSelectedPaymentMethod?: (paymentMethod: IPaymentMethod) => void;
  isDarkMode?: boolean;
}

export default function StripeIntegration({
  paymentMethods,
  setPaymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  isDarkMode
}: IProps) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
  const { user } = useAuth();
  const { createPaymentIntent } = useAxios();
  const [linkToken, setLinkToken] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = {
    clientSecret: linkToken?.client_secret
  };
  const name = user?.entity ? user?.entity?.legalName : user?.fullName;
  const email = user?.email;

  const generateLinkToken = async () => {
    setIsLoading(true);
    const token = await createPaymentIntent();
    setLinkToken(token);
    setIsLoading(false);
  };

  useLayoutEffect(() => {
    user && generateLinkToken();
  }, [user]);

  return (
    linkToken && (
      <Elements stripe={stripePromise} options={options}>
        <StripeButton
          name={name}
          email={email}
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          isDarkMode={isDarkMode}
        />
      </Elements>
    )
  );
}

interface IButtonProps {
  name: string;
  email: string;
  paymentMethods: IPaymentMethod[];
  setPaymentMethods: (paymentMethods: IPaymentMethod[]) => void;
  selectedPaymentMethod?: IPaymentMethod;
  setSelectedPaymentMethod?: (paymentMethod: IPaymentMethod) => void;
  isDarkMode?: boolean;
}

const StripeButton = ({
  name,
  email,
  paymentMethods,
  setPaymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  isDarkMode = true
}: IButtonProps) => {
  const { updateUserById } = useAuth();
  const { getPaymentMethods } = useAxios();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const isMobile = useIsMobile();

  const updatePaymentMethod = async (intentPay) => {
    try {
      await updateUserById({
        paymentMethods: [
          {
            stripeToken: intentPay
          }
        ]
      });

      const accounts = await getPaymentMethods();
      setPaymentMethods(accounts);
      setSelectedPaymentMethod && setSelectedPaymentMethod(accounts[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const { setupIntent, error } = await stripe.confirmSetup({
      elements: elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: name,
            email: email
          }
        }
      },
      redirect: 'if_required'
    });

    if (setupIntent) {
      switch (setupIntent.status) {
        case 'requires_payment_method':
          break;
        case 'requires_action':
          break;
        case 'canceled':
          break;
        case 'processing':
        case 'succeeded':
          await updatePaymentMethod(setupIntent.payment_method);
          break;
        default:
          break;
      }
    }

    if (error) setErrorMessage(error.message);
    setIsLoading(false);
    setShowModal(false);
  };

  return (
    <>
      {selectedPaymentMethod ? (
        <></>
      ) : (
        <Box
          onClick={() => setShowModal(true)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isDarkMode ? '#171717' : 'white',
            border: isDarkMode ? '2px solid #323232' : '2px solid #3152F5',
            padding: '1rem',
            cursor: 'pointer',
            marginBottom: '1.5rem'
          }}
        >
          <PlusIcon />
          <Typography
            variant="body1"
            ml="0.5rem"
            color={isDarkMode ? 'white' : 'black'}
          >
            Connect Payment Method
          </Typography>
        </Box>
      )}

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Add Payment method"
        children={
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: isMobile ? '90%' : '45rem',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              border: 'none',
              boxShadow: 24,
              borderRadius: '0.25rem',
              outline: 0
            }}
          >
            <IconButton
              onClick={() => setShowModal(false)}
              size="small"
              sx={{
                position: 'absolute',
                right: '2rem',
                top: '1rem',
                marginLeft: !isMobile ? -1 : 0
              }}
            >
              <CloseIcon width={20} />
            </IconButton>
            <Box
              sx={{ padding: '3rem', textAlign: 'center', color: '#FFFFFF' }}
            >
              <Typography variant="h3" color="black" mb="1.5rem">
                Select Payment Method
              </Typography>
              <PaymentElement
                options={{
                  fields: {
                    billingDetails: { email: 'never', name: 'never' }
                  }
                }}
              />

              <Box mt="2rem">
                {errorMessage && (
                  <Typography variant="body1" color="primary" mb="1rem">
                    {errorMessage}
                  </Typography>
                )}
                <PrimaryButton
                  btnText="Confirm Payment Method"
                  onClick={handleConfirm}
                  isDisabled={!stripe}
                  isLoading={isLoading}
                />
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem'
                  }}
                >
                  <SecondaryButton
                    btnText="Send a Wire Transfer"
                    onClick={() =>
                      openExternalURL(EXTERNAL_ROUTES.WIRE_TRANSFER)
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />

      <Modal
        open={showSelectModal}
        onClose={() => setShowSelectModal(false)}
        title="Select Payment method"
        children={
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: isMobile ? '90%' : '25rem',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              border: 'none',
              boxShadow: 24,
              borderRadius: '0.25rem',
              outline: 0
            }}
          >
            <IconButton
              onClick={() => setShowSelectModal(false)}
              size="small"
              sx={{
                position: 'absolute',
                right: '2rem',
                top: '1rem',
                marginLeft: !isMobile ? -1 : 0
              }}
            >
              <CloseIcon width={20} />
            </IconButton>
            <Box
              sx={{ padding: '3rem', textAlign: 'center', color: '#FFFFFF' }}
            >
              <Typography variant="h5" color="black" mb="1.5rem">
                Select Payment Method
              </Typography>

              {paymentMethods &&
                paymentMethods.map((pay, idx) => (
                  <PaymentButton
                    key={idx}
                    paymentMethod={pay}
                    onPress={() => {
                      setSelectedPaymentMethod(pay);
                      setShowSelectModal(false);
                    }}
                    variant={'Select'}
                  />
                ))}

              <Box mt="1rem">
                <StripeIntegration
                  paymentMethods={paymentMethods}
                  setPaymentMethods={setPaymentMethods}
                />
              </Box>
            </Box>
          </Box>
        }
      />
    </>
  );
};
