import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export const useIsMobile = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  return isMobile;
};

export const useIsTab = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('md'));
  return isMobile;
};

export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};
