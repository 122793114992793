import { IFAQ, INavLink } from '@utils/models';

export enum PROPERTY_TYPE {
  COMMERCIAL = 'COMMERCIAL',
  MULTIFAMILY = 'MULTIFAMILY',
  HOSPITALITY = 'HOSPITALITY',
  INDUSTRIAL = 'INDUSTRIAL'
}

export enum LISTING_PROFILE {
  OPPORTUNISTIC = 'OPPORTUNISTIC',
  VALUE_ADD = 'VALUE_ADD',
  CORE_PLUS = 'CORE_PLUS',
  FIXED_INCOME = 'FIXED_INCOME'
}

export enum LISTING_STATUS {
  HIDDEN = 'HIDDEN',
  PRESALE = 'PRESALE',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED'
}

export enum LISTING_TYPE {
  ACCREDITED = 'ACCREDITED',
  RETAIL = 'RETAIL'
}

export enum LISTING_ICON {
  USER = 'USER',
  CHART = 'CHART',
  CLOCK = 'CLOCK',
  BUILDING = 'BUILDING',
  MAP = 'MAP'
}

export enum ORDER_STATUS {
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE'
}

export enum ACCREDITATION_STATUS {
  NONE = 'NONE',
  UNACCREDITED = 'UNACCREDITED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum ACCREDITATION_TYPE {
  ANNUAL_INCOME = 'ANNUAL_INCOME',
  NET_WORTH = 'NET_WORTH',
  FINRA_LICENSE = 'FINRA_LICENSE',
  ENTITY_ASSETS = 'ENTITY_ASSETS',
  NOT_ACCREDITED = 'NOT_ACCREDITED'
}

export enum REQUEST_PLATFORM {
  NONE = 'NONE',
  WEB = 'WEB',
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export enum USER_TYPE {
  INVESTOR = 'INVESTOR',
  SPONSOR = 'SPONSOR',
  ADMIN = 'ADMIN'
}

export enum ENTITY_TYPE {
  LLC = 'LLC',
  TRUST = 'TRUST'
}

export enum CONTENT_TYPE {
  ACCREDITATION = 'accreditation',
  INVESTMENT = 'investments'
}

export enum AUTH_TYPES {
  LOGIN = 'LOGIN',
  GET_STARTED = 'GET_STARTED'
}

export enum PAYMENT_TYPE {
  STRIPE_ACH = 'STRIPE_ACH',
  CREDIT_CARD = 'CREDIT_CARD',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
  USER_FUNDS = 'USER_FUNDS'
}

export const EMAIL_REGEX =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SUPPORT_EMAIL = 'support@ownprop.com';
export const SUPPORT_PHONE = ' 512-337-3780';
export const GOOGLE_MAP_URL = 'https://maps.google.com?q=';
export const GOOGLE_MAP_DEFAULT_CENTER = [34.0522, -118.2437];
export const GOOGLE_MAP_DEFAULT_ZOOM = 20;

export const IOS_APP_LINK =
  'https://apps.apple.com/us/app/ownprop/id1612324941';
export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.ownprop.ownprop';

export const NAV_LINKS: INavLink[] = [
  { id: 1, label: 'Discover', route: '/', isExternalLink: false },
  { id: 2, label: 'Portfolio', route: '/portfolio', isExternalLink: false },
  { id: 3, label: 'Account', route: '/account', isExternalLink: false }
];

export const FAQ: IFAQ[] = [
  {
    title: 'Who can invest using OwnProp?',
    description:
      '<p class="no-margin">You must be an accredited investor to invest presently.  In the future, we will open up investments to non-US persons and bring in non-accredited investors as well.</p>\n<p class="no-margin"></p>\n<p class="no-margin">You are accredited if <b>any of the following</b> are true:</p>\n<ul>\n<li>\n<p class="no-margin"><b>$200k+</b> Annual income for the last 2 years.</p>\n</li>\n<li>\n<p class="no-margin"><b>$300k+</b> Household income for the last 2 years.</p>\n</li>\n<li>\n<p class="no-margin"><b>$1M</b> Net-worth excluding your primary residence.</p>\n</li>\n<li>\n<p class="no-margin"><b>Series 7, 65 or 82</b> License Holder currently in good standing</p>\n</li>\n<li>\n<p class="no-margin"><b>$5M+</b> in assets as an LLC not formed for the purpose of investing</p>\n</li>\n<li>\n<p class="no-margin"><b>With all</b> accredited investors by the standards above</p>\n</li>\n</ul><p class="no-margin">For further information on Accredited Investors visit:</p>\n<p class="no-margin"><a href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3" target="_blank" class="intercom-content-link">https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3</a></p>\n<p class="no-margin"></p>\n<p class="no-margin"></p>'
  },
  {
    title: 'Is there a minimum for investment?',
    description:
      '<p class="no-margin">The minimum purchase is one token. OwnProp’s first listing was $100/token.  While we intend to keep future listings in a similar or lower range, this is subject to change depending on each property listing’s unique requirements.</p>'
  },
  {
    title: 'How will I be paid dividends?',
    description:
      '<p class="no-margin">Dividends will be paid via ACH initially and eventually will be paid via distributions of USDC to the user’s wallet.</p>'
  },
  {
    title: 'What is a token?',
    description:
      '<p class="no-margin">Tokens are digital assets created on a blockchain. Each blockchain has a native coin, for instance, Bitcoin for the Bitcoin blockchain and ether for the Ethereum blockchain. Assets built on existing blockchains are referred to as tokens. In OwnProp’s use-case, the tokens will represent physical real estate assets and this ownership will be recorded on top of the Ethereum blockchain. All transactions of these tokens will also be recorded in this immutable way.</p>'
  },
  {
    title: 'How are my tokens valued?',
    description:
      '<p class="no-margin">OwnProp tokens are backed by their corresponding real-estate asset and track to their appraised value. The price of Bitcoin and Ethereum have no impact on the value of each OwnProp token.</p>'
  },
  {
    title: 'Why does real estate need tokenization?',
    description:
      '<p class="no-margin">We at OwnProp believe that all real estate benefits from tokenization for the following reasons:</p>\n<ul>\n<li>\n<p class="no-margin">Creates the opportunity for a marketplace to buy, sell, or trade tokens of real estate</p>\n</li>\n<li>\n<p class="no-margin">Reduces/Eliminates intermediaries</p>\n</li>\n<li>\n<p class="no-margin">Increases liquidity of traditionally illiquid assets</p>\n</li>\n<li>\n<p class="no-margin">Increases access to fractional ownership</p>\n</li>\n<li>\n<p class="no-margin">Decentralization creates trust and security</p>\n</li>\n<li>\n<p class="no-margin">Allows real estate transactions to truly become peer-to-peer</p>\n</li>\n<li>\n<p class="no-margin">Allows investors to diversify their risk</p>\n</li>\n</ul>'
  }
];

export const TOKEN_PRICE = 1000;
