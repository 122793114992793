import { GOOGLE_MAP_URL, LISTING_STATUS, ORDER_STATUS } from '@utils/constants';
import { IAddress, IInvestment, IListing, IUser } from '@utils/models';
import moment from 'moment';

export const shortenNumber = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '';
};

export const formatPrice = (price: number) => {
  if (!price || price <= 0) {
    return '';
  }

  const rounded = Math.round(price * 100) / 100;
  return '$' + rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumber = (number: number) => {
  if (!number || number <= 0) {
    return '';
  }
  const rounded = Math.round(number * 100) / 100;
  return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatString = (value: any, pre?: string, post?: string) => {
  if (!value) return '';
  if (pre) return `${pre}${value}`;
  if (post) return `${value} ${post}`;
  return value;
};

export const formatPercent = (number: number) => {
  if (!number || number <= 0) {
    return '';
  }
  return `${Math.round(number * 1000) / 10}%`;
};

export const formatWallet = (address: string) => {
  const start = address.slice(0, 4);
  const end = address.slice(address.length - 4);
  return start + '...' + end;
};

export const formatAddress = (address: IAddress) => {
  return `${address.street}, ${address.suite ? address.suite + ', ' : ''}${
    address.city
  }, ${address.state} ${address.zipCode}`;
};

export const filterForStarted = (investments: IInvestment[]) => {
  const started = investments.filter(
    (investment) => investment.orderStatus === ORDER_STATUS.STARTED
  )[0];

  if (started) {
    return started;
  } else {
    return investments[0];
  }
};

export const capitalizeFirstLetter = (text: string) => {
  const lowerCase = text.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const isAuthenticated = (user: IUser | null): boolean => {
  return user?.email && user?.accreditationType ? true : false;
};

export const createGoogleMapLocationURL = (lat: number, lng: number) => {
  return [GOOGLE_MAP_URL, lat, ',', lng].join('');
};

export const openExternalURL = (url: string) => {
  window.open(url);
};
export const getCurrentURL = () => window.location.href;

export const setErrorMessage = (error: any): string => {
  return error?.response?.data?.message || 'Error Occured';
};

export const isProfileComplete = (user: IUser) => {
  if (!user) return false;

  return true;
};

export const renderListingStatus = (listing: IListing, token: any) => {
  if (listing?.status === LISTING_STATUS.PRESALE) {
    return `Coming Soon`;
  } else if (listing?.status === LISTING_STATUS.CLOSED) {
    return `Fully Funded`;
  } else if (token.amountSold / token.totalSupply > 0.5) {
    return `${formatPercent(token.amountSold / token.totalSupply)} Funded`;
  } else {
    return `${moment(listing?.closingDate).utc().format('MMM DD')} Close`;
  }
};

export const getFileName = (fileName: string, file, listingId = null) => {
  if (fileName) {
    // OVERIDE ATX FUND
    const ATXFId = '6526fcb3f7fb5d577b1bc15f';
    const isATXF = listingId === ATXFId;
    if (isATXF && fileName === 'Initial_LPA.pdf') return 'Initial LPA';

    const split = fileName?.split('.')[0].split('_');
    const capitalized = split.map((word) => capitalizeFirstLetter(word));
    const formatted = capitalized.join(' ');
    return formatted?.length > 30
      ? formatted.substr(0, 30) + '\u2026'
      : formatted;
  } else if (file) {
    return file?.name?.length > 30
      ? file.name.substr(0, 30) + '\u2026'
      : file.name;
  }
};

// calculate quarter by date
export const getQuarter = (d: Date): number => {
  const q = [1, 2, 3, 4]; // can be change according to calendar
  return q[Math.floor(d.getMonth() / 3)];
};

export const getLastQuartersByCurrentDate = () => {
  const currentYear = parseInt(
    moment(new Date('2023-12-10T06:45:34.057+00:00')).format('YY')
  );
  const lastTwoYears = [currentYear, currentYear - 1];
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  const lastTwoYearsQuarters: string[] = [];
  lastTwoYears.forEach((year) => {
    quarters.forEach((quarter) => {
      lastTwoYearsQuarters.push(`${quarter}'${year}`);
    });
  });
  lastTwoYearsQuarters.sort((a, b) => {
    const [quarter1, year1] = a.split(`'`);
    const [quarter2, year2] = b.split(`'`);
    if (year2 + quarter2 < year1 + quarter1) {
      return -1;
    } else {
      return 1;
    }
  });
  const currentQuarter = `Q${getQuarter(new Date())}'${currentYear}`;
  const index = lastTwoYearsQuarters.findIndex(
    (value) => value === currentQuarter
  );
  const arr = lastTwoYearsQuarters.splice(index, 4);
  return arr.reverse();
};
