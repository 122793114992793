import { useAuth } from '@contexts/AuthContext';
import { CssBaseline, ThemeProvider } from '@mui/material';
import RootRouter from '@routers/RootRouter';
import { IdentifyUser } from '@utils/analytics';
import { theme } from '@utils/theme';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation, useSearchParams } from 'react-router-dom';

declare global {
  interface Window {
    analytics: any;
  }
}

export default function App() {
  const { user, verifyMagicLink, refreshAuth } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isParallelmarkets = searchParams.get('parallelmarkets');

  const verifyToken = async () => {
    try {
      await verifyMagicLink(token);
      if (isParallelmarkets)
        localStorage.setItem('parallelmarkets', isParallelmarkets);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken();
    }

    if (user && process.env.REACT_APP_ENV === 'production') {
      IdentifyUser(window.analytics.identify, user);
    }
    refreshAuth();
  }, []);

  // useEffect(() => {
  //   if (isParallelmarkets && user && !parallelmarkets) {
  //     setParallelmarkets(true);
  //     localStorage.setItem('parallelmarkets', isParallelmarkets);
  //   }
  // }, [isParallelmarkets, user]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
      window.analytics.page(location.pathname);
    }
  }, [location]);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootRouter />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
