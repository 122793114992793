import { Box, Typography } from '@mui/material';

interface IProps {
  text: string;
  subText: string;
  icon: JSX.Element;
  style?: Object;
}

export default function HighlightCard({ text, subText, icon, style }: IProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '2px solid #323232',
        borderRadius: '0.25rem',
        px: '1rem',
        py: '1.25rem',
        ...style
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        flex={1}
      >
        <Typography
          textOverflow="inherit"
          variant="body1"
          fontWeight="600"
          fontSize={18}
          lineHeight="20px"
          color="#FFFFFF"
          sx={{
            display: 'inline-block',
            whiteSpace: 'pre-line',
            textAlign: 'left'
          }}
        >
          {text}
        </Typography>

        <Typography
          mt="0.5rem"
          variant="body1"
          fontSize={14}
          lineHeight="20px"
          color="#FFFFFF"
        >
          {subText}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        width="3rem"
      >
        {icon}
      </Box>
    </Box>
  );
}
