import { formatPercent } from '@utils/helpers';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

interface IProps {
  financing: any;
}

export default function CapitalStackChart({ financing }: IProps) {
  const totalCapital = financing?.equity + financing?.debt + financing?.capEx;
  const DATA = [
    { name: 'Equity', value: financing?.equity / totalCapital },
    { name: 'Debt', value: financing?.debt / totalCapital },
    { name: 'CapEx', value: financing?.capEx / totalCapital }
  ];

  const COLORS = ['#C1CAE0', '#989898', '#FFFFFF'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={100} height={100}>
        <Pie
          data={DATA}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {DATA.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          labelStyle={{ color: 'black' }}
          itemStyle={{ color: 'black' }}
          formatter={(value) => formatPercent(value as number)}
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
