import { ORDER_STATUS, TOKEN_PRICE } from '@utils/constants';
import {
  formatPrice,
  getLastQuartersByCurrentDate,
  getQuarter
} from '@utils/helpers';
import { IInvestment } from '@utils/models';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

interface IProps {
  investments: IInvestment[];
}

export const AssetValueChart = ({ investments }: IProps) => {
  const investmentData = investments
    .filter(({ orderStatus }) => orderStatus === ORDER_STATUS.COMPLETE)
    .map((investment) => ({
      ...investment,
      quarter: `Q${getQuarter(moment(investment.createdAt).toDate())}'${moment(
        investment.createdAt
      ).format('YY')}`
    }));

  let investmentsByQuarter = _.groupBy(
    investmentData,
    (investment) => investment.quarter
  );

  const lastFourQuarters = useMemo(() => {
    return getLastQuartersByCurrentDate();
  }, []);

  const DATA = lastFourQuarters.map((quarter) => {
    const investment = investmentsByQuarter?.[quarter];
    return {
      label: quarter,
      Equity:
        (investment?.reduce((acc, currentValue) => {
          return acc + currentValue.numTokens;
        }, 0) || 0) * TOKEN_PRICE
    };
  });

  return (
    <LineChart width={800} height={200} data={DATA}>
      <XAxis
        dataKey="label"
        tick={{
          fill: 'white',
          fontWeight: '500',
          fontSize: '1rem'
        }}
        axisLine={false}
        tickLine={false}
      ></XAxis>
      <YAxis
        dataKey="Equity"
        tickLine={false}
        axisLine={false}
        tickFormatter={(value) => ''}
      />
      <Tooltip
        labelStyle={{ color: 'black' }}
        itemStyle={{ color: 'black' }}
        formatter={(value) => formatPrice(value as number)}
      />
      <Line
        type="linear"
        dataKey="Equity"
        stroke="white"
        strokeWidth={2.5}
        dot={{ strokeWidth: 1, fill: 'white' }}
      />

      <Legend
        layout="vertical"
        verticalAlign="middle"
        align="right"
        wrapperStyle={{
          paddingLeft: '1rem',
          fontWeight: '700',
          fontSize: '0.875rem'
        }}
      />
      <CartesianGrid horizontal={false} strokeDasharray="3 3" />
    </LineChart>
  );
};
