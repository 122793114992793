import { Box, Divider, Radio, RadioGroup, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Controller } from 'react-hook-form';

interface IOption {
  label: string;
  value?: string;
  description?: string;
}

interface IProps {
  control: any;
  name: string;
  rules: any;
  options: IOption[];
}

export default function RadioInput({ control, name, rules, options }: IProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <RadioGroup sx={{ mb: '2rem' }}>
          {options.map(({ label, value: option, description }, idx) => {
            return (
              <Fragment key={idx}>
                <Box
                  onClick={() => onChange(option)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <Radio
                    checked={value === option}
                    value={option}
                    sx={{
                      color: 'rgba(255, 255, 255, 0.5)',
                      '&.Mui-checked': {
                        color: '#3152F5'
                      },
                      root: {
                        color: 'red',
                        '&$checked': {
                          color: 'red'
                        }
                      }
                    }}
                  />

                  <Box sx={{ marginLeft: '1rem' }}>
                    <Typography variant="h5">{label}</Typography>
                    {description && (
                      <Typography variant="body2">{description}</Typography>
                    )}
                  </Box>
                </Box>
                {idx !== options.length - 1 && (
                  <Divider
                    sx={{
                      my: '0.5rem',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)'
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </RadioGroup>
      )}
    />
  );
}
