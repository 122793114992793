import { ReactComponent as BankIcon } from '@assets/icons/bank-white.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-white.svg';
import { ReactComponent as CryptoIcon } from '@assets/icons/crypto.svg';
import EmptyCard from '@components/cards/EmptyCard';
import { useAuth } from '@contexts/AuthContext';
import { useInvest } from '@contexts/InvestContext';
import { useIsMobile } from '@hooks/useIsMobile';
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography
} from '@mui/material';
import { ORDER_STATUS } from '@utils/constants';
import { formatNumber, formatPrice } from '@utils/helpers';
import { IInvestment } from '@utils/models';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

export const OrderHistory = () => {
  const { user } = useAuth();
  const { getInvestments } = useInvest();
  const [selectedInvestment, setSelectedInvestment] = useState<IInvestment>();
  const [investments, setInvestments] = useState<IInvestment[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();

  async function fetchInvestments() {
    setIsLoading(true);
    const investments = await getInvestments();
    setInvestments(investments);
    setIsLoading(false);
  }

  useEffect(() => {
    user && fetchInvestments();
  }, [user]);

  const grouped = _.groupBy(investments, (investment) => investment.listing.id);
  const memoizedAggregated: IInvestment[] = useMemo(() => {
    const aggregated: IInvestment[] = [];

    for (const investment in grouped) {
      const totalTokens = grouped[investment]
        .filter(({ orderStatus }) => orderStatus === ORDER_STATUS.COMPLETE)
        .map(({ numTokens }) => numTokens)
        .reduce((acc, cur) => acc + cur, 0);

      if (totalTokens) {
        aggregated.push({ ...grouped[investment][0], numTokens: totalTokens });
      }
    }

    return aggregated;
  }, [grouped]);

  const INFO = [
    {
      text: 'Token Price',
      value: formatPrice(selectedInvestment?.listing.token.price)
    },
    {
      text: 'Transaction Date',
      value: moment(selectedInvestment?.createdAt).utc().format('DD/MM/yyyy')
    },
    {
      text: 'Payment Method',
      value: selectedInvestment?.paymentMethod.lastFour,
      icon: <BankIcon width={18} height={18} />
    },
    {
      text: 'Purchase',
      value: formatPrice(
        selectedInvestment?.numTokens * selectedInvestment?.listing.token.price
      )
    },
    { text: 'OwnProp Fee', value: '$0.00' },
    //{ text: 'Transaction Hash', value: selectedInvestment.id },
    {
      text: 'Total',
      value: formatPrice(
        selectedInvestment?.numTokens * selectedInvestment?.listing.token.price
      ),
      isHilight: true
    }
  ];

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={30} sx={{ color: 'white' }} />
        </Box>
      ) : (
        <>
          {memoizedAggregated.map((investment, idx) => (
            <Box
              key={idx}
              onClick={async () => {
                setSelectedInvestment(investment);
                setShowModal(true);
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: '2rem',
                height: 60,
                borderTop: idx !== 0 ? '1px solid #FFFFFF4D' : '',
                cursor: 'pointer',
                top: 0,
                position: 'relative',
                transition: 'all 300ms ease-in-out',
                '&:hover': {
                  boxShadow: '0px 16px 30px rgba(0, 0, 0, 0.1)',
                  top: ' -0.5rem'
                }
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="body1"
                  ml="1rem"
                  sx={{ fontWeight: 600, marginLeft: 0 }}
                >
                  {investment?.listing?.property?.name}
                </Typography>

                <Typography
                  variant="body2"
                  ml="1rem"
                  sx={{
                    marginLeft: 0,
                    fontFamily: 'Hellix-Regular',
                    fontWeight: 400,
                    lineHeight: '18px',
                    color: '#FFFFFF80'
                  }}
                >
                  {moment(investment?.createdAt).utc().format('DD/MM/yyyy')}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end'
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, marginLeft: 0 }}
                  >
                    {formatPrice(
                      investment.numTokens * investment.listing.token.price
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, marginLeft: 0, color: '#FFFFFF80' }}
                  >
                    {formatNumber(investment.numTokens)}
                    {investment.numTokens > 0 ? ' Tokens' : ' Token'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          {memoizedAggregated.length == 0 && (
            <Box>
              <EmptyCard />
            </Box>
          )}
        </>
      )}

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Order Details"
        children={
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: isMobile ? '90%' : '30rem',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: '0.25rem',
              outline: 0
            }}
          >
            <IconButton
              onClick={() => setShowModal(false)}
              size="small"
              sx={{
                position: 'absolute',
                right: '2rem',
                top: '1rem',
                marginLeft: !isMobile ? -1 : 0
              }}
            >
              <CloseIcon width={20} />
            </IconButton>
            {selectedInvestment && (
              <Box
                sx={{ padding: '3rem', textAlign: 'center', color: '#FFFFFF' }}
              >
                <Typography variant="body1">Order Details</Typography>
                <Typography variant="body2" mb="2rem" color={'#FFFFFF80'}>
                  {selectedInvestment?.listing.property.name}
                </Typography>

                <Box sx={{ borderBottom: '1px solid #FFFFFF4D', mb: '2rem' }}>
                  <Typography
                    variant="h1"
                    fontSize={'50px'}
                    fontWeight="600"
                    color={'#3152F5'}
                  >
                    {selectedInvestment?.numTokens}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: '0.5rem',
                      mb: '4rem'
                    }}
                  >
                    <CryptoIcon width={24} height={24} />
                    <Typography
                      variant="h3"
                      fontSize={'24px'}
                      fontWeight="600"
                      color={'#3152F5'}
                      sx={{ pl: '0.5rem', pb: '10px' }}
                    >
                      {selectedInvestment?.numTokens > 0 ? ' Tokens' : ' Token'}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  {INFO.map((info, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pb: '1rem'
                      }}
                    >
                      <Typography
                        variant={info.isHilight ? 'body1' : 'body2'}
                        fontWeight={info.isHilight ? '600' : '400'}
                      >
                        {info.text}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        {info.icon}
                        <Typography
                          variant={info.isHilight ? 'body1' : 'body2'}
                          color={info.isHilight ? '#FFFFFF' : '#FFFFFF80'}
                          fontWeight={info.isHilight ? '600' : '400'}
                          paddingLeft="5px"
                        >
                          {info.value}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        }
      />
    </Box>
  );
};
