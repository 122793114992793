import ParallelIntegration from '@components/ParallelIntegration';
import StatusBadge from '@components/StatusBadge';
import { Box } from '@mui/material';

export const Accreditation = () => {
  return (
    <>
      <Box mb="1rem">
        <StatusBadge />
      </Box>
      <ParallelIntegration />
    </>
  );
};
