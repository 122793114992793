import { formatPercent } from '@utils/helpers';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

interface IProps {
  operations: any;
  financials: any;
}

export default function DepreciationChart({ operations, financials }: IProps) {
  const avgDepreciation =
    (1 - operations?.earlyDepreciation) / (financials?.targetHold - 1);

  const DATA = [...Array(financials?.targetHold)].map((_, idx) => ({
    label: `Y${idx + 1}`,
    Value: idx
      ? operations?.earlyDepreciation + avgDepreciation * idx
      : operations?.earlyDepreciation
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={500} data={DATA}>
        <XAxis
          dataKey="label"
          axisLine={false}
          tick={{
            fill: 'rgba(255, 255, 255, 0.3)',
            fontWeight: '700',
            fontSize: '0.875rem'
          }}
        />
        <YAxis
          axisLine={false}
          tick={{
            fill: 'rgba(255, 255, 255, 0.3)',
            fontWeight: '700',
            fontSize: '0.875rem'
          }}
          tickFormatter={(value) => formatPercent(value as number)}
        />
        <Tooltip
          labelStyle={{ color: 'black' }}
          itemStyle={{ color: 'black' }}
          formatter={(value) => formatPercent(value as number)}
        />
        <Area
          type="monotone"
          dataKey="Value"
          stroke="#C1CAE0"
          fill="#C1CAE0"
          dot
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
