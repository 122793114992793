import { ReactComponent as FacebookWhite } from '@assets/icons/facebook-white.svg';
import { ReactComponent as LinkedInWhite } from '@assets/icons/linkedin-white.svg';
import { ReactComponent as TwitterWhite } from '@assets/icons/twitter-white.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import { EXTERNAL_ROUTES } from '@utils/routes';
import { theme } from '@utils/theme';
import { Fragment } from 'react';

export default function DesktopFooter() {
  const isMobile = useIsMobile();
  const isAuthFlow = location.pathname.includes('/auth/');

  const FOOTER_LINKS = [
    { text: '© OwnProp Inc.', route: EXTERNAL_ROUTES.MARKETING },
    { text: 'Terms of Service', route: EXTERNAL_ROUTES.TERMS },
    { text: 'Privacy Policy', route: EXTERNAL_ROUTES.PRIVACY },
    { text: 'FAQs', route: EXTERNAL_ROUTES.FAQS }
  ];

  const SOCIAL_LINKS = [
    {
      icon: <FacebookWhite />,
      link: 'https://www.facebook.com/OwnPropApp'
    },
    {
      icon: <TwitterWhite />,
      link: 'https://twitter.com/OwnPropApp'
    },
    {
      icon: <LinkedInWhite />,
      link: 'https://www.linkedin.com/company/OwnPropApp'
    }
  ];

  return (
    <Box
      sx={{
        width: '100%',
        paddingX: isMobile ? '1rem' : isAuthFlow ? '2.5rem' : '7.5rem',
        backgroundColor: theme.palette.secondary.dark,
        pb: '1rem',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid sx={{ width: '100%' }} component="footer">
        <Divider
          sx={{ mb: '1rem', borderColor: theme.palette.secondary.light }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
          >
            {FOOTER_LINKS.map(({ text, route }, idx) => (
              <Fragment key={idx}>
                <Link
                  href={route}
                  sx={{ textDecoration: 'none', color: '#32323280' }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.5)'
                    }}
                  >
                    {text}
                  </Typography>
                </Link>
                {idx !== FOOTER_LINKS.length - 1 && !isMobile && (
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.5)'
                    }}
                    mx="0.5rem"
                    color="secondary.dark"
                  >
                    {' • '}
                  </Typography>
                )}
              </Fragment>
            ))}
          </Box>

          <Box sx={{ display: 'flex' }}>
            {SOCIAL_LINKS.map(({ icon, link }, idx) => (
              <Link
                style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                key={idx}
                href={link}
                mr={idx !== SOCIAL_LINKS.length - 1 ? '1rem' : 0}
              >
                {icon}
              </Link>
            ))}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
