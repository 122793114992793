import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';
import { ReactComponent as ShareIcon } from '@assets/icons/share.svg';
import ImagePreview from '@components/ImagePreview';
import ShareModal from '@components/modals/ShareModal';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { IListing } from '@utils/models';
import { useState } from 'react';

interface IProps {
  listing: IListing;
  showGallery: boolean;
  closeGallery: () => void;
}

export default function Gallery({
  listing,
  showGallery,
  closeGallery
}: IProps) {
  const isMobile = useIsMobile();
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showSocialShare, setShowSocialShare] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { property } = { ...listing };
  const { imageUrls, address } = { ...property };
  const location = [address?.city, ', ', address?.state].join('').toUpperCase();

  const openImagePreview = (index: number) => {
    setSelectedIndex(index);
    setShowImagePreview(true);
  };

  const closeImagePreview = () => {
    setShowImagePreview(false);
  };

  const openSocialShare = () => {
    setShowSocialShare(true);
  };

  const closeSocialShare = () => {
    setShowSocialShare(false);
  };

  const isLargeImage = (index: number) => {
    return (
      index === 0 ||
      index % 3 === 0 ||
      (index === imageUrls?.length - 1 && index % 3 == 1)
    );
  };

  return (
    <>
      <Modal open={showGallery} onClose={closeGallery}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            minHeight: '100vh',
            width: '100%',
            backgroundColor: 'white',
            overflowY: 'scroll'
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            pt="2rem"
            px={isMobile ? '1rem' : '16rem'}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={closeGallery} size="medium">
                <ArrowLeftIcon />
              </IconButton>

              <Typography
                variant="body1"
                onClick={closeGallery}
                fontWeight="600"
                sx={{ color: 'rgba(50, 50, 50, 0.5)', cursor: 'pointer' }}
              >
                {isMobile ? 'Back' : 'Back to Property Details'}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={openSocialShare} size="medium">
                <ShareIcon />
              </IconButton>

              <Typography
                variant="body1"
                onClick={openSocialShare}
                fontWeight="600"
                sx={{ color: '#323232', cursor: 'pointer' }}
              >
                Share
              </Typography>
            </Box>
          </Box>

          <Grid
            container
            mt={isMobile ? '1rem' : '3rem'}
            justifyContent="center"
          >
            <Grid
              item
              xs={isMobile ? 11 : 6}
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              height="26rem"
              rowGap={1}
            >
              {imageUrls?.map((image, idx) => (
                <Grid
                  key={idx}
                  item
                  sx={{ cursor: 'pointer' }}
                  xs={isLargeImage(idx) || isMobile ? 12 : 5.9}
                  /**
                   * FIXME
                   * Temporarily disabled the image preview on mobile devices.
                   * Have to make the preview mobile responsive.
                   */
                  onClick={isMobile ? () => {} : () => openImagePreview(idx)}
                >
                  <img
                    src={image}
                    style={{
                      height:
                        isMobile || !isLargeImage(idx) ? '14rem' : '26rem',
                      width: '100%',
                      borderRadius: 4
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ShareModal
        listing={listing}
        showModal={showSocialShare}
        closeModal={closeSocialShare}
      />

      <ImagePreview
        selectedIndex={selectedIndex}
        listing={listing}
        showPreview={showImagePreview}
        closePreview={closeImagePreview}
      />
    </>
  );
}
