import AssetModal from '@components/modals/AssetModal';
import StatusBadge from '@components/StatusBadge';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { LISTING_STATUS } from '@utils/constants';
import { formatAddress, formatNumber, formatPrice } from '@utils/helpers';
import { IInvestment } from '@utils/models';
import { useEffect, useState } from 'react';

interface IProps {
  investment: IInvestment;
}

export default function InvestmentCard({ investment }: IProps) {
  const isMobile = useIsMobile();
  const { queryListingReportFiles } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [listingFiles, setListingFiles] = useState([]);

  const fetchFiles = async () => {
    const res = await queryListingReportFiles(investment.listing.id);
    setListingFiles(res.files);
  };

  useEffect(() => {
    fetchFiles();
  }, [showModal]);

  return (
    <>
      <Box
        onClick={() => setShowModal(true)}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          marginBottom: '1rem',
          top: 0,
          position: 'relative',
          transition: 'all 300ms ease-in-out',
          '&:hover': {
            boxShadow: '0px 16px 30px rgba(0, 0, 0, 0.1)',
            top: ' -0.5rem'
          }
        }}
      >
        <Box
          style={{ width: isMobile ? '100%' : '14rem', marginRight: '1rem' }}
        >
          <img
            style={{
              height: 100,
              width: '100%',
              borderRadius: '0.4rem',
              cursor: 'pointer'
            }}
            src={investment?.listing?.property?.imageUrls[0]}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ cursor: 'pointer' }}>
            {investment?.listing?.status === LISTING_STATUS.ACTIVE && (
              <Box mb="0.5rem">
                <StatusBadge isInvestment />
              </Box>
            )}
            <Typography variant="h5">
              {investment?.listing?.property?.name}
            </Typography>
            <Typography variant="body1" mb="0.5rem">
              {formatAddress(investment?.listing?.property?.address)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="h4">
            {formatPrice(investment.numTokens * investment.listing.token.price)}
          </Typography>

          <Typography variant="body1" textAlign="right">
            {formatNumber(investment.numTokens)}{' '}
            {investment.numTokens === 1 ? 'Token' : 'Tokens'}
          </Typography>
        </Box>
      </Box>

      <AssetModal
        investment={investment}
        listingFiles={listingFiles}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
