export const IdentifyUser = async (identify, user) => {
  if (identify && user && user.email) {
    const nameArr = user.fullName
      ? user.fullName.split(' ')
      : 'Unknown Unknown';
    let data: any = {
      firstName: nameArr[0],
      lastName: nameArr[nameArr.length - 1],
      email: user.email,
      phone: user.phone,
      name: user.fullName,
      rexCompany: 'OwnProp',
      AccountType: user.type
    };
    if (user.marketing) {
      data.gclid = user.marketing.gclid;
      data.utmSource = user.marketing.utmSource;
      data.utmMedium = user.marketing.utmMedium;
      data.utmCampaign = user.marketing.utmCampaign;
      data.utmContent = user.marketing.utmContent;
      data.utmTerm = user.marketing.utmTerm;
    }
    identify(user.id, data);
  }
};

export const SignUpCompleted = async (track, fullName, email) => {
  if (email) {
    const nameArr = fullName ? fullName.split(' ') : 'Unknown Unknown';
    track('Signup Completed', {
      firstName: nameArr[0],
      lastName: nameArr[nameArr.length - 1],
      email: email,
      phone: 'Unknown',
      status: 'Activating'
    });
  }
};
