import { CallToAction } from '@components/CallToAction';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';

interface IProps {
  title: string;
}

export default function PageHeader({ title }: IProps) {
  const isMobile = useIsMobile();

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        marginTop: isMobile ? '4rem' : '1.5rem',
        marginBottom: '1.5rem'
      }}
    >
      <Typography variant={isMobile ? 'h3' : 'h2'} color="#FFFFFF">
        {title}
      </Typography>
      <CallToAction />
    </Box>
  );
}
