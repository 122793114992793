import { formatPrice } from '@utils/helpers';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

interface IProps {
  financials: any;
  sliderValue: number;
}

export function ProjectionsChart({ financials, sliderValue }: IProps) {
  const cashReturn = financials?.cashOnCash * financials?.targetHold;
  const DATA = [...Array(financials?.targetHold)].map((_, idx) => ({
    label: `Y${idx + 1}`,
    Cash: financials?.cashOnCash * sliderValue * (idx + 1),
    Equity:
      (financials.equityMultiple - cashReturn) **
        ((idx + 1) / financials.targetHold) *
      sliderValue
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={500} height={300} data={DATA}>
        <XAxis
          dataKey="label"
          fill="red"
          axisLine={false}
          tick={{
            fill: 'rgba(255, 255, 255, 0.3)',
            fontWeight: '700',
            fontSize: '0.875rem'
          }}
          tickFormatter={(value) => value}
        />
        <YAxis
          axisLine={false}
          tick={{
            fill: 'rgba(255, 255, 255, 0.3)',
            fontWeight: '700',
            fontSize: '0.875rem'
          }}
          tickFormatter={(value) => formatPrice(value)}
        />
        <Tooltip
          labelStyle={{ color: 'black' }}
          itemStyle={{ color: 'black' }}
          formatter={(value) => formatPrice(value as number)}
          wrapperStyle={{ outline: 'none' }}
        />

        <Legend />
        <Bar
          dataKey="Cash"
          stackId="a"
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth={1}
          radius={[0, 0, 4, 4]}
        />
        <Bar
          dataKey="Equity"
          stackId="a"
          stroke="#FFFFFF"
          strokeWidth={1}
          fill="#989898"
          radius={[4, 4, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
