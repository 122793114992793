import HighlightCard from '@components/cards/HighlightCard';
import IconGenerator from '@components/IconGenerator';
import { useIsMobile } from '@hooks/useIsMobile';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IHighlight } from '@utils/models';

interface IProps {
  highlights: IHighlight[];
}

export default function Highlights({ highlights }: IProps) {
  const isMobile = useIsMobile();

  return (
    <Box mb="1.5rem">
      <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
        The Deal
      </Typography>

      <Typography
        variant={isMobile ? 'h5' : 'h2'}
        mb={isMobile ? '1rem' : '1.5rem'}
        color="#FFFFFF"
      >
        Understand the Opportunity
      </Typography>

      <Box mb={isMobile ? '1rem' : '1.5rem'}>
        {highlights.map((highlight, index) => (
          <HighlightCard
            key={index}
            style={{ marginBottom: '1rem' }}
            text={highlight?.title}
            subText={highlight?.description}
            icon={<IconGenerator icon={highlight?.icon} />}
          />
        ))}
      </Box>
    </Box>
  );
}
