import { ReactComponent as PhotosIcon } from '@assets/icons/photos.svg';
import { Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface IProps {
  imageUrls: string[];
  openGallery: () => void;
}

export default function PropertyImagesCard({ imageUrls, openGallery }: IProps) {
  return (
    <Grid
      item
      onClick={openGallery}
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-end"
      sx={{
        background: `url(${imageUrls?.[1]}) no-repeat`,
        backgroundSize: 'cover',
        height: '14rem',
        cursor: 'pointer',
        borderRadius: '0.25rem 0.25rem 0 0'
      }}
    >
      <Box
        onClick={openGallery}
        display="flex"
        m="1rem"
        py="0.2rem"
        px="0.8rem"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderRadius: '2.5rem',
          background: 'rgba(255, 255, 255, 0.7)',
          borderWidth: '#020101',
          cursor: 'pointer'
        }}
      >
        <IconButton sx={{ ml: -0.5 }} size="small" disabled>
          <PhotosIcon height="1rem" width="1rem" />
        </IconButton>

        <Typography variant="overline" color="black">
          VIEW MORE PHOTOS
        </Typography>
      </Box>
    </Grid>
  );
}
