import { ReactComponent as DownloadIcon } from '@assets/icons/download-circle-white.svg';
import { ReactComponent as WhiteFileIcon } from '@assets/icons/file-white.svg';
import EmptyCard from '@components/cards/EmptyCard';
import { useAxios } from '@contexts/AxiosContext';
import { Box, CircularProgress, Grid, IconButton } from '@mui/material';
import { CONTENT_TYPE } from '@utils/constants';
import { getFileName, openExternalURL } from '@utils/helpers';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const Documents = () => {
  const { queryUserFiles, getUserFileDownloadUrl } = useAxios();
  const [investFiles, setInvestFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInvestFiles = async () => {
    setIsLoading(true);
    const res = await queryUserFiles(CONTENT_TYPE.INVESTMENT);
    setInvestFiles(res.files);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInvestFiles();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={30} sx={{ color: 'white' }} />
        </Box>
      ) : (
        <>
          {' '}
          {investFiles.length == 0 ? (
            <EmptyCard />
          ) : (
            <>
              <Grid
                container
                spacing={2}
                sx={{ borderBottom: '1px solid #FFFFFF4D', pb: '10px' }}
              >
                <Grid item xs={6}>
                  Filename
                </Grid>
                <Grid item xs={4}>
                  Date
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>

              {investFiles.map((file, idx) => (
                <Grid container spacing={2} sx={{ pt: '10px' }} key={idx}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <WhiteFileIcon width={24} height={24} />
                      <Box sx={{ pl: '5px' }}>
                        {getFileName(file.key, null)}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    {moment(file.lastModified).utc().format('DD/MM/yyyy')}
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      size="medium"
                      onClick={async () => {
                        const res = await getUserFileDownloadUrl(
                          file.key,
                          CONTENT_TYPE.INVESTMENT
                        );
                        openExternalURL(res.url);
                      }}
                    >
                      <DownloadIcon width={24} height={24} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};
