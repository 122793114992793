import { ReactComponent as ExpandMoreIcon } from '@assets/icons/chevron-down.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import { IFAQ } from '@utils/models';
import { useState } from 'react';

interface IProps {
  data?: IFAQ[];
}

export const Accordion = ({ data }: IProps): JSX.Element | null => {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleChange = (selected: number) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? selected : null);
  };

  const renderItems = () => {
    return data?.map(({ title, description }, idx) => {
      const borderColor = '#323232';

      return (
        <MUIAccordion
          key={idx}
          disableGutters
          elevation={0}
          square
          expanded={expanded === idx}
          onChange={handleChange(idx)}
          sx={{
            paddingY: isMobile ? '0.1rem' : '0.2rem',
            marginX: isMobile ? '-2rem' : 0,
            backgroundColor: '#000000',
            borderTop:
              idx === 0
                ? {
                    borderTop: `1px solid ${borderColor}`,
                    borderBottom: `1px solid ${borderColor}`
                  }
                : { borderBottom: `1px solid ${borderColor}` }
          }}
        >
          <AccordionSummary
            sx={{ marginX: isMobile ? 0 : '-1rem' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography variant="body1" color="#FFFFFF">
              {title}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography
              color="#FFFFFF"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </AccordionDetails>
        </MUIAccordion>
      );
    });
  };

  return <>{renderItems()}</>;
};
