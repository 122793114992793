import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

interface IProps {
  name: string;
  label: string;
  control: any;
  rules: any;
  options: any[];
  getOptionLabel: (option: any) => any;
  renderOption: (props: any, option: any) => any;
  changeHandler: (e: any, value: any, onChange: any) => any;
  inputChangeHandler: (e: any, value: any) => any;
  open: boolean;
  onOpen: () => any;
  onClose: () => any;
  style?: Object;
}

export default function AutocompleteInput({
  name,
  label,
  rules,
  control,
  options,
  getOptionLabel,
  renderOption,
  changeHandler,
  inputChangeHandler,
  open,
  onOpen,
  onClose,
  style
}: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          style={style}
          freeSolo
          getOptionLabel={getOptionLabel}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          onChange={(event, newValue) => {
            changeHandler(event, newValue, onChange);
          }}
          onInputChange={(event, newInputValue) => {
            inputChangeHandler(event, newInputValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label=""
                placeholder={label}
                color="primary"
                variant="standard"
                fullWidth
              />
            );
          }}
          renderOption={renderOption}
        />
      )}
    />
  );
}
