import PrimaryButton from '@components/buttons/PrimaryButton';
import CapitalStackChart from '@components/charts/CapitalStackChart';
import DepreciationChart from '@components/charts/DepreciationChart';
import { ProjectionsChart } from '@components/charts/ProjectionsChart';
import { FinancialsTable } from '@components/listings/FinancialsTable';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Slider, Typography } from '@mui/material';
import {
  formatNumber,
  formatPercent,
  formatPrice,
  openExternalURL
} from '@utils/helpers';
import { IListing } from '@utils/models';
import { useState } from 'react';

interface IProps {
  listing: IListing;
  financials: any;
  operations: any;
  financing: any;
  token: any;
}

export default function TheInvestment({
  listing,
  financials,
  operations,
  financing,
  token
}: IProps) {
  const isMobile = useIsMobile();
  const MAX_VALUE = 50000;
  const [sliderValue, setSliderValue] = useState(MAX_VALUE / 2);
  const DOMAIN = MAX_VALUE * financials?.equityMultiple;

  const handleSliderChange = (event: any) => {
    setSliderValue(event.target.value);
  };

  const TOKENOMICS = [
    { label: 'Ticker Symbol', value: token?.symbol },
    { label: 'Market Cap', value: formatPrice(token?.marketCap) },
    { label: 'Total Supply', value: formatNumber(token?.totalSupply) },
    { label: 'Token Price', value: formatPrice(token?.price) }
  ];
  let FINANCIALS = [
    { label: 'Asset Value', value: formatPrice(operations?.assetValue) },
    {
      label: 'Annual Income',
      value: formatPrice(operations?.operatingIncome)
    },
    { label: 'Cap Rate', value: formatPercent(operations?.capRate) },
    { label: 'Loan-to-Value', value: formatPercent(operations?.loanToValue) }
  ];

  // OVERIDE ATX FUND
  const ATXFId = '6526fcb3f7fb5d577b1bc15f';
  if (listing.id === ATXFId) {
    FINANCIALS = [
      {
        label: 'Fund Size',
        value: '$50M'
      },
      {
        label: 'Net IRR',
        value: '17-20%'
      },
      {
        label: 'Assets',
        value: '10-15'
      },
      {
        label: 'Preferred Return',
        value: '8%'
      }
    ];
  }
  const hideProjection = listing.id === ATXFId;

  return (
    <Box mb={isMobile ? '3rem' : '4.25rem'}>
      <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
        The Investment
      </Typography>
      <Typography
        variant={isMobile ? 'h5' : 'h2'}
        mb={isMobile ? '1rem' : '1.5rem'}
        color="#FFFFFF"
      >
        {hideProjection ? 'Financials & Tokenomics' : "How You'll Make Money"}
      </Typography>
      {!hideProjection && (
        <>
          <Grid
            container
            mb="0.5rem"
            padding="1.5rem"
            sx={{ backgroundColor: '#3152F5', borderRadius: '0.25rem' }}
            justifyContent="space-between"
          >
            <Grid item xs={isMobile ? 12 : 4.5} mb="2rem">
              <Typography variant="h2" color="#FFFFFF">
                {formatPrice(sliderValue / 1000)}k
              </Typography>

              <Typography
                variant="body1"
                mt="0.2rem"
                mb="0.5rem"
                color="#FFFFFF"
              >
                Investment Amount
              </Typography>

              <Slider
                min={1000}
                max={MAX_VALUE}
                color="secondary"
                step={1000}
                value={sliderValue}
                onChange={(event) => handleSliderChange(event)}
              />

              <Box
                mt="1rem"
                width="100%"
                borderRadius="0.25rem"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: '#323232', padding: '1.5rem' }}
              >
                <Typography variant="body1" color="rgba(255, 255, 255, 0.5)">
                  Projected Return
                </Typography>

                <Typography variant="h1" color="#FFFFFF">
                  {formatPrice(financials?.equityMultiple * sliderValue)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={isMobile ? 12 : 6.5}>
              <Box
                mt="1rem"
                width="100%"
                borderRadius="0.25rem"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{
                  backgroundColor: 'transparent',
                  padding: '0.5rem 0.5rem'
                }}
              >
                <Typography variant="h5" color="rgba(255, 255, 255, 0.5)">
                  Projected Return
                </Typography>

                <Box sx={{ width: '100%', height: 280 }}>
                  <ProjectionsChart
                    financials={financials}
                    sliderValue={sliderValue}
                  />
                </Box>
              </Box>
            </Grid>

            {financing?.equity && operations?.earlyDepreciation && (
              <>
                <Grid item xs={isMobile ? 12 : 4.5}>
                  <Box
                    mt="1rem"
                    width="100%"
                    borderRadius="0.25rem"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{
                      backgroundColor: '#323232',
                      padding: '0.5rem 0.5rem'
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="rgba(255, 255, 255, 0.5)"
                      textAlign="left"
                    >
                      Capital Stack
                    </Typography>
                    <Box sx={{ width: '100%', height: 300 }}>
                      <CapitalStackChart financing={financing} />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={isMobile ? 12 : 6.5}>
                  <Box
                    mt="1rem"
                    width="100%"
                    borderRadius="0.25rem"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{
                      backgroundColor: 'transparent',
                      padding: '0.5rem 0.5rem'
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="rgba(255, 255, 255, 0.5)"
                      textAlign="left"
                    >
                      Depreciation Schedule
                    </Typography>
                    <Box sx={{ width: '100%', height: 300, marginTop: '1rem' }}>
                      <DepreciationChart
                        financials={financials}
                        operations={operations}
                      />
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          <Typography
            variant="subtitle1"
            color="rgba(255, 255, 255, 0.5)"
            mb="1.5rem"
          >
            *The projections above are forward-looking statements that should
            not be relied upon as actual. Real estate investments come with risk
            as market conditions and business strategies may change, impacting
            financials considerably.
          </Typography>
        </>
      )}

      <Grid
        container
        justifyContent="space-between"
        pt={hideProjection ? (isMobile ? '1rem' : '1.5rem') : null}
      >
        <Grid item xs={isMobile ? 12 : 5.5} mt={isMobile ? '2rem' : 0}>
          <FinancialsTable
            listing={listing}
            title="Financials"
            data={FINANCIALS}
            isLeft
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 5.5} mt={isMobile ? '2rem' : 0}>
          <FinancialsTable
            listing={listing}
            title="Tokenomics"
            data={TOKENOMICS}
          />
        </Grid>

        <Box textAlign="center" width="100%">
          <PrimaryButton
            btnText="View On Arbiscan"
            onClick={() =>
              openExternalURL(`https://arbiscan.io/token/${token.address}`)
            }
            isOutlined
            isWide
          />
        </Box>
      </Grid>
    </Box>
  );
}
