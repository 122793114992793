import ScrollToTop from '@components/ScrollToTop';
import { AuthProvider } from '@contexts/AuthContext';
import { AxiosProvider } from '@contexts/AxiosContext';
import { InvestProvider } from '@contexts/InvestContext';
import { ParallelProvider } from '@parallelmarkets/react';
import { loadParallel } from '@parallelmarkets/vanilla';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV !== 'localhost') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
const parallel = loadParallel({
  client_id: process.env.REACT_APP_PARALLEL_MARKET_CLIENT_ID,
  environment: process.env.REACT_APP_PARALLEL_MARKET_ENV,
  scopes: ['identity', 'accreditation_status', 'profile'],
  flow_type: 'overlay',
  show_dismiss_button: true
});

root.render(
  <AuthProvider>
    <AxiosProvider>
      <InvestProvider>
        <ParallelProvider parallel={parallel}>
          <BrowserRouter>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </BrowserRouter>
        </ParallelProvider>
      </InvestProvider>
    </AxiosProvider>
  </AuthProvider>
);

reportWebVitals();
