import PrimaryButton from '@components/buttons/PrimaryButton';
import InvestModal from '@components/modals/InvestModal';
import StripeIntegration from '@components/StripeIntegration';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useInvest } from '@contexts/InvestContext';
import { Box, Divider, TextField, Typography } from '@mui/material';
import {
  ACCREDITATION_STATUS,
  LISTING_STATUS,
  LISTING_TYPE,
  ORDER_STATUS,
  PAYMENT_TYPE
} from '@utils/constants';
import { formatNumber, formatPrice, renderListingStatus } from '@utils/helpers';
import { IListing, IPaymentMethod } from '@utils/models';
import { AUTH_ROUTES } from '@utils/routes';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface IFormInput {
  numTokens: number;
}

interface IProps {
  listing: IListing;
}

export default function OrderInput({ listing }: IProps) {
  const { user } = useAuth();
  const { token, type } = listing;
  const navigate = useNavigate();
  const MAXIMUM = token.totalSupply - token.amountSold;
  // Allow to by available tokens when maximum token is less tham minimum allowed
  const minCal = 1000 / token.price;
  const minAvailable =
    type === LISTING_TYPE.ACCREDITED ? (minCal < 1 ? 1 : minCal) : 1;
  const MINIMUM = MAXIMUM < minAvailable ? MAXIMUM : minAvailable;
  const { getPaymentMethods } = useAxios();
  const {
    investment,
    createInvestment,
    updateInvestmentById,
    getInvestmentByListing,
    updateSubscriptionStatus
  } = useInvest();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>();
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>();
  const [isLoading, setIsLoading] = useState(false);
  const [minTokens, setMinTokens] = useState(MINIMUM);
  const [showModal, setShowModal] = useState(false);
  const isAccredited =
    user?.accreditationStatus === ACCREDITATION_STATUS.APPROVED ||
    user?.isAccreditationVerified;

  const { control, handleSubmit, formState, watch, setError, setValue } =
    useForm<IFormInput>({
      defaultValues: {
        numTokens:
          investment && investment.orderStatus === ORDER_STATUS.STARTED
            ? investment.numTokens
            : minTokens
      },
      mode: 'onChange'
    });
  const error =
    formState.errors.numTokens && formState.errors.numTokens.message;
  const [searchParams] = useSearchParams();

  const fetchPaymentMethods = async () => {
    const accounts = await getPaymentMethods();
    if (user?.accountBalance && user.accountBalance > 0) {
      accounts.push({
        stripeToken: '9999',
        bankName: 'Available Funds',
        lastFour: `$ ${user.accountBalance}`,
        type: PAYMENT_TYPE.USER_FUNDS
      });
    }
    if (accounts.length > 0) {
      setPaymentMethod(accounts[0]);
    }

    setPaymentMethods(accounts);
  };

  const setMinInvestments = async (listingId) => {
    if (user?.type === 'ADMIN') {
      setMinTokens(1);
    } else if (type === LISTING_TYPE.ACCREDITED) {
      const investments = await getInvestmentByListing(listingId);
      if (
        investments.length > 0 &&
        investments.some((inv) => inv.orderStatus === ORDER_STATUS.COMPLETE)
      ) {
        setMinTokens(1);
      }
    }
  };

  // useEffect(() => {
  //   setFocus('numTokens');
  // }, [setFocus]);

  useEffect(() => {
    fetchPaymentMethods();
    setMinInvestments(listing.id);
  }, [listing]);

  useEffect(() => {
    const investmentId = searchParams.get('investment');
    const event = searchParams.get('event');
    if (event === 'signing_complete' || event === 'viewing_complete') {
      setTimeout(() => {
        updateSubscriptionStatus(investmentId);
      }, 900);
    }
  }, []);

  useEffect(() => {
    const investmentId = searchParams.get('investment');
    const event = searchParams.get('event');
    if (
      investment &&
      investment.id === investmentId &&
      (event === 'signing_complete' || event === 'viewing_complete') &&
      !showModal
    ) {
      setValue('numTokens', investment.numTokens);
      handlePress({ numTokens: investment.numTokens });
    }
  }, [investment]);

  async function handlePress({ numTokens }: IFormInput) {
    try {
      setIsLoading(true);
      if (investment && investment.orderStatus === ORDER_STATUS.STARTED) {
        await updateInvestmentById(investment.id, {
          paymentMethod,
          numTokens
        });
      } else {
        await createInvestment({
          listing: listing.id,
          paymentMethod,
          numTokens
        });
      }
      setIsLoading(false);
      setShowModal(true);
    } catch (err: any) {
      setError('numTokens', {
        message: err.response.data.message
      });
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '1rem',
          paddingX: '1rem',
          mb: '1rem'
        }}
      >
        {listing.status !== LISTING_STATUS.CLOSED && (
          <Box>
            <Controller
              name={'numTokens'}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Number of tokens is required'
                },
                min: {
                  value: minTokens,
                  message: `${minTokens} Token Minimum`
                },
                max: {
                  value: MAXIMUM,
                  message: `${MAXIMUM} Token Maximum`
                }
              }}
              render={({ field: { ref, onChange } }) => (
                <TextField
                  defaultValue={watch('numTokens')}
                  variant="standard"
                  type="number"
                  onChange={onChange}
                  inputRef={ref}
                  InputProps={{
                    sx: {
                      color: 'primary.main',
                      fontFamily: 'Hellix-Semibold',
                      fontSize: '52px',
                      lineHeight: '56px',
                      fontWeight: 700,
                      margin: '0 auto',
                      width: '50%',
                      cursor: 'pointer'
                    },
                    inputProps: {
                      style: { textAlign: 'center', paddingBottom: 0 }
                    }
                  }}
                />
              )}
            />
            <Typography variant="body1" color="#FFFFFF" mb="2rem">
              {formatNumber(watch('numTokens'))}{' '}
              {watch('numTokens') > 1 ? 'tokens' : 'token'} will cost{' '}
              {formatPrice(watch('numTokens') * listing?.token?.price)}
            </Typography>
            {error && (
              <Typography variant="subtitle1" color="error">
                {error}
              </Typography>
            )}
            <StripeIntegration
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              selectedPaymentMethod={paymentMethod}
              setSelectedPaymentMethod={setPaymentMethod}
            />
            <Divider
              sx={{ borderColor: 'rgba(255, 255, 255, 0.5)', mb: '1rem' }}
            />
          </Box>
        )}

        <Typography variant="h5" color="#FFFFFF" mb="1rem" textAlign="center">
          {renderListingStatus(listing, token)} | $
          {(MINIMUM * token.price) / 1000}k Minimum
        </Typography>

        {user ? (
          listing.type === LISTING_TYPE.ACCREDITED && isAccredited ? (
            <PrimaryButton
              btnText={
                listing.status === LISTING_STATUS.CLOSED ? 'Sold Out' : 'Invest'
              }
              onClick={handleSubmit(handlePress)}
              isLoading={isLoading}
              isDisabled={
                !paymentMethod || listing.status === LISTING_STATUS.CLOSED
              }
              isFullWidth
            />
          ) : listing.type === LISTING_TYPE.RETAIL ? (
            <PrimaryButton
              btnText={
                listing.status === LISTING_STATUS.CLOSED ? 'Sold Out' : 'Invest'
              }
              onClick={handleSubmit(handlePress)}
              isLoading={isLoading}
              isDisabled={
                !paymentMethod || listing.status === LISTING_STATUS.CLOSED
              }
              isFullWidth
            />
          ) : (
            <PrimaryButton
              btnText={
                listing.status === LISTING_STATUS.CLOSED
                  ? 'Sold Out'
                  : 'Get Accredited'
              }
              onClick={() => navigate(`/account`)}
              isFullWidth
            />
          )
        ) : (
          <PrimaryButton
            btnText="Get Started"
            onClick={() => navigate(AUTH_ROUTES.GET_STARTED)}
            isFullWidth
          />
        )}
      </Box>

      <InvestModal
        listing={listing}
        showModal={showModal}
        onDismiss={() => setShowModal(false)}
        paymentMethods={paymentMethods}
      />
    </>
  );
}
