import PrimaryButton from '@components/buttons/PrimaryButton';
import { useAuth } from '@contexts/AuthContext';
import { Box, Switch, Typography } from '@mui/material';
import { useState } from 'react';

export const Notifications = () => {
  const { user, updateUserById } = useAuth();
  const [isChanged, setIsChanged] = useState(false);
  const [isPhoneNotifications, setIsPhoneNotifications] = useState(
    user?.isPhoneNotifications
  );
  const [isEmailNotifications, setIsEmailNotifications] = useState(
    user?.isEmailNotifications
  );

  const onSubmit = async () => {
    try {
      await updateUserById({
        isPhoneNotifications: isPhoneNotifications,
        isEmailNotifications: isEmailNotifications
      });
    } catch {}
  };

  const OPTIONS = [
    {
      title: 'Push Notifications',
      text: ' Receive app reminders about your investments',
      status: isPhoneNotifications,
      onclick: (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChanged(true);
        setIsPhoneNotifications(event.target.checked);
      }
    },
    {
      title: 'Emails',
      text: 'Receive email reminders about your investments',
      status: isEmailNotifications,
      onclick: (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChanged(true);
        setIsEmailNotifications(event.target.checked);
      }
    }
  ];

  return (
    <Box>
      {OPTIONS.map((option, idx) => (
        <Box
          key={idx}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: '3rem',
            height: 60,
            borderTop: idx !== 0 ? '1px solid #FFFFFF4D' : ''
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="body1"
              ml="1rem"
              sx={{ fontWeight: 600, marginLeft: 0 }}
            >
              {option.title}
            </Typography>

            <Typography
              variant="body2"
              ml="1rem"
              sx={{
                marginLeft: 0,
                pt: '5px',
                fontFamily: 'Hellix-Regular',
                fontWeight: 400,
                lineHeight: '18px'
              }}
            >
              {option.text}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" ml={'2rem'}>
            <Switch checked={option.status} onChange={option.onclick} />
          </Box>
        </Box>
      ))}
      <PrimaryButton
        btnText="Save"
        isDisabled={!isChanged}
        onClick={onSubmit}
        isOutlined
      />
    </Box>
  );
};
