import { ReactComponent as AnchorIcon } from '@assets/icons/anchor.svg';
import { ReactComponent as DollarIcon } from '@assets/icons/dollar.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/location-pin.svg';
import { ReactComponent as OfficeIcon } from '@assets/icons/office.svg';
import { ReactComponent as RatesIcon } from '@assets/icons/rates.svg';
import { ReactComponent as ResizeIcon } from '@assets/icons/resize.svg';
import { ReactComponent as ScheduleIcon } from '@assets/icons/schedule.svg';
import { ReactComponent as HammerIcon } from '@assets/icons/tools-hammer.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { formatNumber, formatPercent, formatPrice } from '@utils/helpers';

interface IProps {
  profile: any;
}

export default function PropertyProfile({ profile }: IProps) {
  const isMobile = useIsMobile();

  const PROFILE = [
    {
      icon: <ScheduleIcon />,
      label: 'Year Built',
      value: `${profile?.yearBuilt}`
    },
    {
      icon: <HammerIcon />,
      label: 'Year Renovated',
      value: `${profile?.yearRenovated}`
    },
    {
      icon: <OfficeIcon />,
      label: 'Total Units',
      value: `${formatNumber(profile?.numUnits)}`
    },
    {
      icon: <RatesIcon />,
      label: 'Occupancy Rate',
      value: `${formatPercent(profile?.occupancyRate)}`
    },
    {
      icon: <ResizeIcon />,
      label: 'Rentable Area',
      value: `${formatNumber(profile?.squareFootage)} SF`
    },
    {
      icon: <LocationIcon />,
      label: 'Land Area',
      value: `${formatNumber(profile?.landAcreage)} AC`
    },
    {
      icon: <AnchorIcon />,
      label: 'Avg Unit Size',
      value: `${formatNumber(profile?.avgUnitSize)}`
    },
    {
      icon: <DollarIcon />,
      label: 'Avg Unit Rent',
      value: `${formatPrice(profile?.avgUnitRent)}`
    }
  ];

  return (
    <Grid container display="flex" flexWrap="wrap">
      {PROFILE.map(({ icon, label, value }, idx) => (
        <Grid
          key={idx}
          item
          xs={isMobile ? 6 : 3}
          mb="1.5rem"
          sx={{ display: 'flex' }}
        >
          {icon}
          <Box ml="0.5rem">
            <Typography variant="h5" color="#FFFFFF">
              {value ? value : 'N/A'}
            </Typography>
            <Typography variant="body2" color="rgba(255, 255, 255, 0.5)">
              {label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
