import { useIsMobile } from '@hooks/useIsMobile';
import { Box } from '@mui/material';
import { theme } from '@utils/theme';
import { ReactNode } from 'react';
import DesktopFooter from './DesktopFooter';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

interface IProps {
  pageTitle: string;
  children: ReactNode;
}

export default function AuthLayout({ pageTitle, children }: IProps) {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.dark
      }}
    >
      {!isMobile ? (
        <DesktopNavbar />
      ) : (
        <Box sx={{ position: 'fixed', width: '100%', zIndex: 999 }}>
          <MobileNavbar />
        </Box>
      )}
      <Box sx={{ marginTop: isMobile && '4rem' }}>{children}</Box>
      <DesktopFooter />
    </Box>
  );
}
