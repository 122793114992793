import { Accordion } from '@components/Accordion';
import PrimaryButton from '@components/buttons/PrimaryButton';
import ListingCard from '@components/cards/ListingCard';
import PageLayout from '@components/layouts/PageLayout';
import PageHeader from '@components/PageHeader';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { FAQ, LISTING_STATUS, USER_TYPE } from '@utils/constants';
import { openExternalURL } from '@utils/helpers';
import { IListing } from '@utils/models';
import { EXTERNAL_ROUTES } from '@utils/routes';
import { useEffect, useState } from 'react';

export default function DiscoverPage() {
  const [listings, setListings] = useState<IListing[] | null>(null);
  const { getAllListings } = useAxios();
  const isMobile = useIsMobile();
  const { user } = useAuth();

  const fetchListings = async () => {
    const listings = await getAllListings();
    setListings(listings);
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const filteredListings = listings?.filter((listing) =>
    user?.type !== USER_TYPE.ADMIN
      ? listing?.status !== LISTING_STATUS.HIDDEN
      : listing
  );

  return (
    <PageLayout pageTitle="Discover">
      <Grid container px={isMobile ? '1rem' : '6rem'}>
        <PageHeader title="Discover" />

        <Grid container spacing={2}>
          {filteredListings?.map((listing, idx) => (
            <Grid item lg={4} md={6} sm={12} key={idx}>
              <ListingCard listing={listing} />
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          sx={{ marginTop: '4rem', marginBottom: '4rem' }}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="overline"
              color="rgba(255, 255, 255, 0.5)"
              mb="0.75rem"
            >
              Knowledge Base
            </Typography>

            <Typography
              variant={isMobile ? 'h3' : 'h1'}
              color="rgba(255, 255, 255)"
              mb="2.625rem"
            >
              Help when you need it
            </Typography>
          </Grid>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb="2.5rem"
          >
            <Grid item container spacing={2} width="85%">
              <Grid item xs={isMobile ? 12 : 6}>
                <Accordion data={isMobile ? FAQ : FAQ.slice(0, 3)} />
              </Grid>

              {!isMobile && (
                <Grid item xs={isMobile ? 12 : 6}>
                  <Accordion data={FAQ.slice(3)} />
                </Grid>
              )}
            </Grid>
          </Box>

          <PrimaryButton
            onClick={() => openExternalURL(EXTERNAL_ROUTES.FAQS)}
            btnText="View the FAQ"
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
