import { ReactComponent as ChevronIcon } from '@assets/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import FileButton from '@components/buttons/FileButton';
import PortfolioCard from '@components/cards/PortfolioCard';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile, useIsTab } from '@hooks/useIsMobile';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { formatAddress, openExternalURL } from '@utils/helpers';
import { IInvestment } from '@utils/models';
import { theme } from '@utils/theme';
import { useNavigate } from 'react-router-dom';

interface IProps {
  investment: IInvestment;
  listingFiles: any[];
  showModal: boolean;
  closeModal: () => void;
}

export default function AssetModal({
  investment,
  listingFiles,
  showModal,
  closeModal
}: IProps) {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const navigate = useNavigate();
  const { getListingReportFileDownloadUrl } = useAxios();
  const { listing, numTokens } = investment;

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignSelf="center"
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          width: isMobile ? '100%' : isTab ? '50%' : '40%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#323232',
          color: theme.palette.primary.main,
          border: 'none',
          boxShadow: 24,
          textAlign: 'center',
          px: '2rem',
          py: '1.5rem',
          borderRadius: '0.25rem',
          outline: 0
        }}
      >
        <Box display="flex" flexDirection="row">
          <IconButton
            onClick={closeModal}
            size="small"
            sx={{
              position: 'absolute',
              right: '2rem',
              marginLeft: !isMobile ? -1 : 0
            }}
          >
            <CloseIcon width={20} stroke="white" />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          py="2rem"
          onClick={() => navigate(`/listings/${investment?.listing?.id}`)}
          sx={{ cursor: 'pointer' }}
        >
          <img
            style={{ borderRadius: '50%', marginRight: '1rem' }}
            src={listing?.property?.imageUrls[0]}
            width={54}
            height={54}
          />
          <Box display="flex" flexDirection="column" textAlign="left">
            <Typography variant="body1" color="white">
              {listing?.property?.name}
            </Typography>

            <Typography variant="body1" color="white" textAlign="left">
              {formatAddress(listing?.property?.address)}
            </Typography>
          </Box>
          <ChevronIcon />
        </Box>

        <PortfolioCard totalValue={numTokens * listing?.token?.price} />

        {listingFiles.length > 0 && (
          <>
            <Typography
              variant="body1"
              color="white"
              mt="1rem"
              textAlign="left"
              mb="0.5rem"
            >
              Quarterly Reports
            </Typography>

            {listingFiles.map((file, idx) => (
              <Box key={idx}>
                <FileButton
                  fileName={file.key}
                  textColor="#FFFFFF"
                  listingId={listing.id}
                  downloadOnly
                  onClick={async () => {
                    const res = await getListingReportFileDownloadUrl(
                      file.key,
                      listing.id
                    );
                    openExternalURL(res.url);
                  }}
                />
                {idx !== listingFiles.length - 1 && (
                  <Box
                    style={{
                      width: '100%',
                      borderBottomColor: 'white',
                      borderBottomWidth: '1px'
                    }}
                  />
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
    </Modal>
  );
}
