import { useAuth } from '@contexts/AuthContext';
import AccountPage from '@pages/AccountPage';
import DiscoverPage from '@pages/DiscoverPage';
import ListingPage from '@pages/ListingPage';
import PortfolioPage from '@pages/PortfolioPage';
import AuthRouter from '@routers/AuthRouter';
import { isAuthenticated } from '@utils/helpers';
import { ReactNode } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

interface IProps {
  redirectPath: string;
  isAllowed: boolean;
  children: ReactNode;
}

export default function Router() {
  const { user } = useAuth();

  const ProtectedRoute = ({ isAllowed, redirectPath, children }: IProps) => {
    if (!isAllowed) {
      // redirect back to the path once user is available from app
      if (!window.location.pathname.includes('/auth')) {
        localStorage.setItem('redirectPath', window.location.pathname);
      }

      return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
  };

  return (
    <Routes>
      <Route
        element={
          // @ts-ignore
          <ProtectedRoute
            redirectPath="/auth/login"
            isAllowed={isAuthenticated(user)}
          />
        }
      />
      <Route path="/auth/*" element={<AuthRouter />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/portfolio" element={<PortfolioPage />} />
      <Route path="/listings/:id" element={<ListingPage />} />
      <Route path="/*" element={<DiscoverPage />} />
    </Routes>
  );
}
