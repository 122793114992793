export enum ROOT_ROUTES {
  HOME = '/',
  AUTH = '/auth'
}

export enum AUTH_ROUTES {
  LOGIN = '/auth/login',
  GET_STARTED = '/auth/get-started',
  VERIFY_EMAIL = '/auth/verify-email',
  ACCREDITATION = '/auth/accreditation'
}

export enum EXTERNAL_ROUTES {
  MARKETING = 'https://www.ownprop.com',
  TERMS = 'https://www.ownprop.com/terms',
  PRIVACY = 'https://www.ownprop.com/privacy-policy',
  FAQS = 'https://www.ownprop.com/reference/faq',
  PARALLEL_MARKETS = 'https://bridge.parallelmarkets.com/ownprop-atx',
  WIRE_TRANSFER = 'https://ownprop-api-public-prod.s3.amazonaws.com/ownprop/OwnProp+Wire+Instructions.pdf',
  RISK_FATORS = 'https://ownprop-api-public-prod.s3.amazonaws.com/ownprop/OwnProp+Risk+Factors.pdf',
  SUBSCRIPTION = 'https://ownprop-api-public-prod.s3.amazonaws.com/ownprop/OwnProp+Subscription+Agreement.pdf'
}
