import { useAuth } from '@contexts/AuthContext';
import { filterForStarted } from '@utils/helpers';
import { IInvestment, ServiceResponse } from '@utils/models';
import { createContext, ReactNode, useContext, useState } from 'react';

export interface IInvestContext {
  investment: IInvestment;
  createInvestment: (payload: any) => Promise<void>;
  getInvestments: () => Promise<IInvestment[]>;
  getInvestmentByListing: (listingId: string) => Promise<IInvestment[]>;
  updateInvestmentById: (investmentId: string, payload: any) => Promise<void>;
  getSubscriptionUrl: (
    investmentId: string,
    listingId: string,
    hostUrl: string
  ) => Promise<any>;
  updateSubscriptionStatus: (investmentId: string) => Promise<IInvestment>;
}

const DefaultValues: IInvestContext = {
  investment: null,
  createInvestment: () => null,
  getInvestments: () => null,
  getInvestmentByListing: () => null,
  updateInvestmentById: () => null,
  getSubscriptionUrl: () => null,
  updateSubscriptionStatus: () => null
};

const InvestContext = createContext<IInvestContext>(DefaultValues);

export function useInvest() {
  return useContext(InvestContext);
}

interface IProps {
  children: ReactNode;
}

export function InvestProvider({ children }: IProps) {
  const [investment, setInvestment] = useState<IInvestment | null>(null);
  const { apiClient } = useAuth();

  const createInvestment = async (payload: any): Promise<void> => {
    const res = await apiClient.post<IInvestment>(`/investments`, payload);
    if (res.data) {
      setInvestment(res.data);
    }
  };

  const getInvestments = async (): Promise<IInvestment[]> => {
    const limit = 50;
    let page = 1;
    let investments: IInvestment[] = [];
    let resultPage = -1;
    do {
      const query = `?limit=${limit}&page=${page}`;
      const res = await apiClient.get<ServiceResponse<IInvestment>>(
        `/investments${query}`
      );
      page++;
      if (res.data && res.data.results && res.data.results.length > 0) {
        resultPage = res.data.totalPages;
        investments = [...investments, ...res.data.results];
      } else {
        break;
      }
    } while (resultPage >= page);

    return investments;
  };

  const getInvestmentByListing = async (
    listingId: string,
    investmentId?: string
  ): Promise<IInvestment[]> => {
    const limit = 50;
    let page = 1;
    let investments: IInvestment[] = [];
    let resultPage = -1;
    do {
      const query = `&limit=${limit}&page=${page}`;
      const res = await apiClient.get<ServiceResponse<IInvestment>>(
        `/investments?listing=${listingId}${query}`
      );
      page++;
      if (res.data && res.data.results && res.data.results.length > 0) {
        resultPage = res.data.totalPages;
        investments = [...investments, ...res.data.results];
      } else {
        break;
      }
    } while (resultPage >= page);

    if (investments.length > 0 && !investmentId)
      setInvestment(filterForStarted(investments));
    if (investments.length > 0 && investmentId)
      setInvestment(investments.find((x) => x.id === investmentId));
    return investments;
  };

  const updateInvestmentById = async (
    investmentId: string,
    payload: any
  ): Promise<void> => {
    const res = await apiClient.put<IInvestment>(
      `/investments/${investmentId}`,
      payload
    );
    if (res.data.id) setInvestment(res.data);
  };

  const getSubscriptionUrl = async (
    investmentId: string,
    listingId: string,
    hostUrl: string
  ) => {
    const redirectUrl = `${hostUrl}/listings/${listingId}?investment=${investmentId}`;
    const res = await apiClient.get(
      `/investments/${investmentId}/subscription?redirectUrl=${redirectUrl}&pingUrl=${hostUrl}`
    );
    return res.data;
  };

  const updateSubscriptionStatus = async (investmentId: string) => {
    const res = await apiClient.put(
      `/investments/${investmentId}/subscription`
    );
    if (res.data) setInvestment(res.data);
    return res.data;
  };

  const value = {
    investment,
    createInvestment,
    getInvestments,
    getInvestmentByListing,
    updateInvestmentById,
    getSubscriptionUrl,
    updateSubscriptionStatus
  };

  return (
    <InvestContext.Provider value={value}>{children}</InvestContext.Provider>
  );
}
