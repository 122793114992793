import { IUser } from '@utils/models';
import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig
} from 'axios-auth-refresh';
import { createContext, ReactNode, useContext, useState } from 'react';

interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
  user: IUser;
}

export interface IAuthContext {
  apiClient: AxiosInstance;
  user: IUser | null;
  setUser: (user: IUser | null) => void;
  email: string;
  setEmail: (email: string | null) => void;
  refreshUser: () => Promise<IUser>;
  updateUserById: (payload: any, listingId?: string) => void;
  deleteUserAccount: () => void;
  accessToken: string;
  refreshAuth: () => Promise<string>;
  signUp: (payload: any) => void;
  login: (email: string) => void;
  logout: () => void;
  setAuth: (authRes: IAuthResponse) => void;
  verifyMagicLink: (token: string) => Promise<IUser>;
  parallelmarkets: boolean;
  setParallelmarkets: (status: boolean) => void;
}

const DefaultValues: IAuthContext = {
  apiClient: axios,
  user: null,
  setUser: () => null,
  email: null,
  setEmail: () => null,
  refreshUser: () => null,
  updateUserById: () => null,
  deleteUserAccount: () => null,
  accessToken: '',
  refreshAuth: () => null,
  signUp: () => null,
  login: () => null,
  logout: () => null,
  setAuth: () => null,
  verifyMagicLink: () => null,
  parallelmarkets: null,
  setParallelmarkets: () => null
};

const AuthContext = createContext<IAuthContext>(DefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}

interface IProps {
  children: ReactNode;
}

export function AuthProvider({ children }: IProps) {
  const [user, setUser] = useState<IUser | null>(null);
  const [email, setEmail] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [parallelmarkets, setParallelmarkets] = useState(false);

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
  });

  createAuthRefreshInterceptor(
    apiClient,
    async (failedRequest) => {
      return refreshAuth()
        .then((token) => {
          if (token) {
            failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
            return Promise.resolve();
          }
          return Promise.reject();
        })
        .catch((error) => {
          return Promise.reject();
        });
    },
    { pauseInstanceWhileRefreshing: true }
  );

  apiClient.interceptors.request.use(function (config) {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  const refreshAuth = async (): Promise<string> => {
    const options: AxiosAuthRefreshRequestConfig = {
      skipAuthRefresh: true
    };
    return apiClient
      .post('/auth/refresh-tokens', null, options)
      .then((res) => {
        if (res.data) return setAuth(res.data);
        if (res.status === 401 || res.status === 204) resetUser();
        return null;
      })
      .catch((error) => {
        resetUser();
        return null;
      });
  };

  const signUp = async (payload: any) => {
    await apiClient.post<IUser>('/auth/sign-up', payload);
    setEmail(email);
  };

  const login = async (email: string) => {
    await apiClient.post<IUser>('/auth/log-in', { email });
    setEmail(email);
  };

  const logout = async () => {
    await apiClient.post('/auth/log-out');
    resetUser();
  };

  const resetUser = (): void => {
    setAccessToken('');
    setUser(null);
  };

  const setAuth = async ({ user, accessToken }: IAuthResponse) => {
    try {
      apiClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`;
      setAccessToken(accessToken);
      await setUserInfo(user);
    } catch (err) {
      console.error(err);
    } finally {
      return accessToken;
    }
  };

  const setUserInfo = async (user: IUser) => {
    setUser(user);
  };

  const refreshUser = async (): Promise<IUser> => {
    const res = await apiClient.get<IUser>(`/users`);
    if (res.data) await setUserInfo(res.data);
    return res.data;
  };

  const updateUserById = async (payload: any, listingId?: string) => {
    let path = '/users';
    if (listingId) {
      path = path + `?listingId=${listingId}`;
    }
    const res = await apiClient.put<IUser>(path, payload);
    if (res.data) setUser(res.data);
  };

  const deleteUserAccount = async () => {
    await apiClient.delete<IUser>(`/users`);
    setAccessToken('');
    setUser(null);
  };

  const verifyMagicLink = async (token: string): Promise<IUser> => {
    const res = await apiClient.post(`/auth/verify-magic-link?token=${token}`);
    if (res.data) await setAuth(res.data);
    return res?.data?.user;
  };

  const value = {
    apiClient,
    user,
    setUser,
    email,
    setEmail,
    refreshUser,
    updateUserById,
    deleteUserAccount,
    accessToken,
    refreshAuth,
    signUp,
    login,
    logout,
    setAuth,
    verifyMagicLink,
    parallelmarkets,
    setParallelmarkets
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
