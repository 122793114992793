import { ReactComponent as PortfolioIcon } from '@assets/icons/price-rise.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import { Box, Typography } from '@mui/material';
import { ROOT_ROUTES } from '@utils/routes';
import { useNavigate } from 'react-router-dom';

export default function EmptyCard() {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '25rem', textAlign: 'center', paddingTop: '4.25rem' }}>
      <Box
        sx={{
          width: '8rem',
          height: '8rem',
          backgroundColor: 'whitesmoke',
          borderRadius: '100%',
          margin: '0 auto 1.5rem auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <PortfolioIcon />
      </Box>

      <Typography
        variant="h5"
        sx={{ margin: '0 auto', marginBottom: '0.5rem' }}
      >
        Start Investing
      </Typography>
      <Typography
        variant="body1"
        sx={{ margin: '0 auto', marginBottom: '0.5rem' }}
      >
        Build a diversified portfolio of real estate assets
      </Typography>
      <PrimaryButton
        btnText="Browse Investments"
        onClick={() => navigate(ROOT_ROUTES.HOME)}
      />
    </Box>
  );
}
