import PrimaryButton from '@components/buttons/PrimaryButton';
import AddressInput from '@components/inputs/AddressInput';
import TextInput from '@components/inputs/TextInput';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { EMAIL_REGEX } from '@utils/constants';
import { theme } from '@utils/theme';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IFormInput {
  fullName: string;
  email: string;
  phone: string;
  address: {};
}

export const InvestorProfile = () => {
  const isMobile = useIsMobile();
  const { user, updateUserById } = useAuth();

  const { control, handleSubmit, formState, setError, setValue, clearErrors } =
    useForm<IFormInput>({
      defaultValues: {
        fullName: '',
        email: '',
        phone: '',
        address: {}
      },
      mode: 'onChange'
    });

  useEffect(() => {
    if (user) {
      setValue('fullName', user?.fullName);
      setValue('email', user?.email);
      setValue('phone', user?.phone);
      setValue('address', {
        street: user?.address?.street ?? '',
        suite: user?.address?.suite ?? '',
        city: user?.address?.city ?? '',
        state: user?.address?.state ?? '',
        country: user?.address?.country ?? '',
        zipCode: user?.address?.zipCode ?? '',
        latitude: user?.address?.latitude ?? '',
        longitude: user?.address?.longitude ?? ''
      });
    }
  }, [user]);

  const onSubmit = async (inputs: IFormInput) => {
    try {
      if (formState.isValid) {
        await updateUserById(inputs);
      }
    } catch {}
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} mb="1.5rem">
            <TextInput
              name="fullName"
              placeholder="Full Name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Full name is required'
                },
                maxLength: {
                  value: 50,
                  message: 'Full name is too long'
                }
              }}
              isSubmitFocus={true}
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} mb="1.5rem">
              <TextInput
                name="phone"
                placeholder="Phone"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Phone is required'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Phone is too long'
                  }
                }}
                mask={'(999) 999-9999'}
                type="tel"
                isSubmitFocus={true}
              />
              {user && user.isPhoneVerified && (
                <Typography sx={{ color: theme.palette.success.main }}>
                  Verified
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} mb="1.5rem">
              <TextInput
                name="email"
                placeholder="Email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required'
                  },
                  maxLength: {
                    value: 50,
                    message: 'Email is too long'
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Email is invalid'
                  }
                }}
                type="email"
                isSubmitFocus={true}
              />
              {user && user.isEmailVerified && (
                <Typography sx={{ color: theme.palette.success.main }}>
                  Verified
                </Typography>
              )}
              {/* <Typography sx={{ paddingTop: '0.5rem' }}>
                We received a request to update your email. Click on the
                confirmation link we sent to your new email address to finalize
                this change. If you do not recognize this request, please reach
                our to us at support@ownprop.com.
              </Typography> */}
            </Grid>
          </Grid>

          <Typography variant="h5" paddingBottom={'1rem'}>
            Mailing Address
          </Typography>

          <AddressInput
            clearErrors={clearErrors}
            control={control}
            setValue={setValue}
          />

          <PrimaryButton
            btnText="SAVE"
            formState={formState}
            isWide={!isMobile}
            isFullWidth={isMobile}
            isOutlined
          />
        </Grid>
      </form>
    </Box>
  );
};
