import { useIsMobile } from '@hooks/useIsMobile';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  btnText: string;
  onClick?: () => void;
  formState?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOutlined?: boolean;
  isFullWidth?: boolean;
  isModal?: boolean;
  isWide?: boolean;
  style?: any;
  isDarkMode?: boolean;
}

export default function PrimaryButton({
  btnText,
  onClick,
  formState,
  isDisabled,
  isLoading,
  isOutlined,
  isFullWidth,
  isModal,
  isWide,
  style,
  isDarkMode = true
}: IProps) {
  // eslint-disable-next-line
  formState
    ? formState
    : (formState = { isDirty: true, isValid: true, isSubmitting: false });
  const { isDirty, isValid, isSubmitting } = formState;
  const [disabled, setDisabled] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isValid || isDisabled) return setDisabled(true);
    setDisabled(false);
  }, [isDirty, isValid, isDisabled]);

  return (
    <Button
      variant={isOutlined ? 'outlined' : 'contained'}
      disableElevation
      size="large"
      type="submit"
      onClick={onClick}
      color={isOutlined ? 'secondary' : 'primary'}
      disabled={disabled}
      sx={{
        backgroundColor: isOutlined && isDisabled && '#323232',
        height: '2.625rem',
        width: isMobile || isFullWidth ? '100%' : undefined,
        border: isOutlined ? '1px solid #FFFFFF' : undefined,
        lineHeight: '2.625rem',
        borderRadius: '3.75rem',
        paddingX: isWide ? '5rem' : '2rem',
        '&:hover': {
          color: isOutlined && '#3152F5',
          border: isOutlined
            ? isModal
              ? '1px solid #323232'
              : '1px solid #3152F5'
            : undefined
        },
        '&.Mui-disabled': {
          backgroundColor: '#000 !important'
        },
        ...style
      }}
    >
      <>
        {isSubmitting || isLoading ? (
          <CircularProgress size={30} sx={{ color: 'white' }} />
        ) : (
          <Typography variant="button" color="white">
            {btnText}
          </Typography>
        )}
      </>
    </Button>
  );
}
