import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 708,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#3152F5'
    },
    secondary: {
      light: '#323232',
      main: '#171717',
      dark: '#000000'
    },
    warning: {
      main: '#FFC130'
    },
    success: {
      main: '#86CC6E'
    },
    error: {
      main: '#F82B2B'
    },
    action: {
      disabledBackground: 'rgba(255, 255, 255, 0.5)',
      disabled: '#323232'
    }
  },
  typography: {
    fontFamily: ['Hellix', 'Roboto'].join(','),
    h1: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Bold',
      fontSize: '52px',
      lineHeight: '56px',
      fontWeight: 700
    },
    h2: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Bold',
      fontSize: '42px',
      fontWeight: 700
    },
    h3: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Semibold',
      fontSize: '32px',
      fontWeight: 600
    },
    h4: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Semibold',
      fontSize: '32px',
      fontWeight: 700
    },
    h5: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Semibold',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600
    },
    body1: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Regular',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400
    },
    body2: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Regular',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400
    },
    subtitle1: {
      color: '#FFFFFF',
      fontFamily: 'Hellix-Semibold',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700
    },
    subtitle2: {
      color: '#FFFFFF',
      fontSize: '10px',
      lineHeight: '18px',
      fontWeight: 400
    },
    overline: {
      fontFamily: 'Hellix-Semibold',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 700,
      letterSpacing: '1.25px',
      textTransform: 'uppercase'
    },
    button: {
      fontFamily: 'Hellix-Semibold',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      letterSpacing: '1.25px',
      textTransform: 'uppercase'
    }
  }
});
