import ActionCard from '@components/cards/ActionCard';
import ModalWrapper from '@components/ModalWrapper';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { formatNumber } from '@utils/helpers';
import { useEffect, useState } from 'react';
import AppStore from '../../assets/images/appstore.png';
import GoogleStore from '../../assets/images/googleplay.png';
import QrCodeImage from '../../assets/images/qr-code.png';

export default function WaitlistModal() {
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const { getUserWaitListPosition } = useAxios();
  const [position, setPosition] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchPosition = async () => {
    const res = await getUserWaitListPosition();
    setPosition(res);
  };

  useEffect(() => {
    if (user) {
      fetchPosition();
    }
  }, [user]);

  return (
    <>
      <ActionCard
        title="You're on the List"
        description="Download mobile to skip the line"
        onClick={() => setShowModal(true)}
        position={position}
      />
      <ModalWrapper
        title={
          <Typography
            variant="h5"
            sx={{
              color: 'black',
              marginBottom: '1.25rem',
              borderBottomStyle: 'solid',
              borderBottomColor: '#3152F5',
              borderBottomWidth: '2px',
              padding: '0.5rem'
            }}
          >
            You’re <a style={{ color: '#3152F5' }}>#{formatNumber(position)}</a>{' '}
            on the list
          </Typography>
        }
        description={
          <Typography
            variant="body1"
            sx={{
              color: 'black',
              fontFamily: 'Hellix-Semibold',
              textAlign: 'center',
              marginBottom: '1.25rem'
            }}
          >
            We’re gradually opening investment accounts to new users. We’ll
            notify you when your account is ready.
          </Typography>
        }
        callToAction={
          <>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                color: '#FFFFFF',
                marginBottom: '1.5rem',
                maxWidth: '16.375rem'
              }}
            >
              Download the mobile app for opportunities to move up the list.
            </Typography>

            {isMobile ? (
              <Box display="flex">
                <img
                  src={AppStore}
                  style={{
                    height: '4rem',
                    width: '10rem',
                    marginRight: '0.5rem'
                  }}
                />
                <img
                  src={GoogleStore}
                  style={{
                    height: '4rem',
                    width: '10rem'
                  }}
                />
              </Box>
            ) : (
              <img
                src={QrCodeImage}
                style={{
                  height: '10rem',
                  width: '10rem'
                }}
              />
            )}
          </>
        }
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
