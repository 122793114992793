import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus-blue.svg';
import { ReactComponent as USDCIcon } from '@assets/icons/usdc-blue.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import DropdownInput from '@components/inputs/DropdownInput';
import WithdrawalModel from '@components/modals/WithdrawalModel';
import StripeIntegration from '@components/StripeIntegration';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { formatPrice } from '@utils/helpers';
import { IPaymentMethod } from '@utils/models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from './inputs/TextInput';

interface IFormInput {
  amount: number;
  token: string;
}

export const AddFunds = () => {
  const { user } = useAuth();
  const { addFunds, getPaymentMethods } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);

  const {
    control,
    handleSubmit,
    formState,
    watch,
    setError,
    setValue,
    getValues
  } = useForm<IFormInput>({
    defaultValues: {
      amount: '50.00' as any,
      token: ''
    },
    mode: 'onChange'
  });
  const error = formState.errors.amount && formState.errors.amount.message;

  async function handlePress({ amount, token }: IFormInput) {
    setIsLoading(true);
    await addFunds(amount, token);
    setShowModal(false);
    setIsLoading(false);
  }

  const fetchPaymentMethods = async () => {
    try {
      const accounts = await getPaymentMethods();
      setPaymentMethods(accounts);
    } catch (error) {
      user && (await fetchPaymentMethods());
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    if (user?.paymentMethods) {
      const token =
        user.paymentMethods.filter((p) => p.stripeToken).length > 0
          ? user.paymentMethods.filter((p) => p.stripeToken)[0].stripeToken
          : '';
      if (token) setValue('token', token);
    }
  }, [user]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1.5rem'
        }}
      >
        <Typography variant="h5">Available Funds</Typography>
        <Box sx={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>
          <PlusIcon widths={34} height={34} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '1rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <USDCIcon widths={34} height={34} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'space-between',
              pl: '0.5rem'
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600, marginLeft: 0 }}>
              USDC
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginLeft: 0,
                fontFamily: 'Hellix-Regular',
                fontWeight: 400,
                lineHeight: '18px',
                color: '#FFFFFF80'
              }}
            >
              USD Coin
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600, marginLeft: 0 }}>
            {formatPrice(user?.accountBalance) || '$0'}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginLeft: 0,
              fontFamily: 'Hellix-Regular',
              fontWeight: 400,
              lineHeight: '18px',
              color: '#FFFFFF80'
            }}
          >
            {user?.accountBalance || 0} Tokens
          </Typography>
        </Box>
      </Box>
      <WithdrawalModel />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title=""
        children={
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: isMobile ? '90%' : '25rem',
              transform: 'translate(-50%, -50%)',
              bgcolor: '#323232',
              border: 'none',
              boxShadow: 24,
              borderRadius: '0.25rem',
              outline: 0
            }}
          >
            <IconButton
              onClick={() => {
                setShowModal(false);
              }}
              size="small"
              sx={{
                position: 'absolute',
                right: '2rem',
                top: '1rem',
                marginLeft: !isMobile ? -1 : 0
              }}
            >
              <CloseIcon width={20} stroke="white" />
            </IconButton>
            <Box
              sx={{
                padding: '3rem'
              }}
            >
              {paymentMethods.length > 0 ? (
                <form onSubmit={handleSubmit(handlePress)}>
                  <Typography variant="h5" mb="1.5rem">
                    Add Funds
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DropdownInput
                        name="token"
                        options={paymentMethods.map(
                          ({ bankName, stripeToken }) => ({
                            label: bankName,
                            value: stripeToken
                          })
                        )}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Token is required'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        name={'amount'}
                        placeholder={'Deposit Amount'}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Amount is required'
                          },
                          min: {
                            value: 50,
                            message: `${50} USD Minimum`
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    mt="2rem"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <PrimaryButton
                      btnText="Deposit"
                      isLoading={isLoading}
                      isWide={!isMobile}
                      isFullWidth={isMobile}
                      formState={formState}
                    />
                  </Box>
                </form>
              ) : (
                <>
                  <Typography variant="h5" mb="1.5rem" color={'black'}>
                    No Payment Methods
                  </Typography>
                  <StripeIntegration
                    setPaymentMethods={setPaymentMethods}
                    paymentMethods={paymentMethods}
                    isDarkMode={false}
                  />
                </>
              )}
            </Box>
          </Box>
        }
      />
    </>
  );
};
