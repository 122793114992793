import Gallery from '@components/Gallery';
import TradeWidget from '@components/invest/TradeWidget';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { IListing, ISection } from '@utils/models';
import { useState } from 'react';
import PropertyImagesWidget from './PropertyImagesWidget';

interface IProps {
  listing: IListing;
  sections: any[];
}

export default function ListingSidebar({ listing, sections }: IProps) {
  const isMobile = useIsMobile();
  const [showGallery, setShowGallery] = useState(false);
  const [active, setActive] = useState(sections[0].name);

  const openGallery = () => {
    setShowGallery(true);
  };

  const closeGallery = () => {
    setShowGallery(false);
  };

  const handleSectionClick = (section: ISection) => {
    const item = section.ref.current;

    item.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    setActive(section.name);
  };

  const property = listing?.property;
  const imageUrls = property?.imageUrls;

  return (
    <>
      <Box
        minWidth={350}
        style={{
          top: '2rem',
          position: isMobile ? 'relative' : 'sticky'
        }}
      >
        <Box
          mb="2rem"
          style={{
            backgroundColor: 'black',
            borderRadius: '0.25rem',
            marginBottom: '2.625rem'
          }}
        >
          <PropertyImagesWidget
            imageUrls={imageUrls}
            openGallery={openGallery}
          />
          <Box
            sx={{
              border: '2px solid #323232',
              borderTopWidth: 0,
              borderRadius: '0 0 0.25rem 0.25rem'
            }}
          >
            <TradeWidget listing={listing} />
          </Box>
        </Box>

        {false && (
          <Box paddingX="1rem">
            <Box>
              {sections.map((section) => (
                <Typography
                  key={section.name}
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    marginBottom: '0.8rem'
                  }}
                  color={
                    active === section.name
                      ? 'white'
                      : 'rgba(255, 255, 255, 0.5)'
                  }
                  onClick={() => handleSectionClick(section)}
                >
                  {section.value}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      <Gallery
        listing={listing}
        showGallery={showGallery}
        closeGallery={closeGallery}
      />
    </>
  );
}
