import { ReactComponent as ChevronRightIcon } from '@assets/icons/chevron-right.svg';
import { useIsMobile, useIsTab } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import Rive from '@rive-app/react-canvas';

interface IProps {
  title: string;
  description: string;
  position?: number;
  onClick?: () => void;
}

export default function ActionCard({
  title,
  description,
  position,
  onClick
}: IProps) {
  const isMobile = useIsMobile();
  const isTab = useIsTab();

  return (
    <Box
      onClick={onClick}
      my={isMobile ? '1rem' : 0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        borderRadius: '3.75rem',
        px: '0.8rem',
        py: '1rem',
        height: 60,
        width: isMobile ? '100%' : isTab ? '65%' : undefined
      }}
    >
      {position ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#DDEEFF',
            borderRadius: '1.625rem',
            paddingRight: '1rem'
          }}
        >
          <Rive
            src="https://ownprop.mo.cloudinary.net/s3/public/ownprop/assets/ownprop-logo.riv"
            style={{ width: '3rem', height: '3rem' }}
          />

          <Typography variant="h3" ml="0.5rem" color="black">
            #{position}
          </Typography>
        </Box>
      ) : (
        <Rive
          src="https://ownprop.mo.cloudinary.net/s3/public/ownprop/assets/ownprop-logo.riv"
          style={{ width: '3rem', height: '3rem' }}
        />
      )}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        flex={1}
      >
        <Typography
          variant="body2"
          ml="1rem"
          color="#000000"
          sx={{ fontFamily: 'Hellix-SemiBold' }}
        >
          {title}
        </Typography>

        <Typography
          variant="body2"
          ml="1rem"
          color="#000000"
          sx={{ fontFamily: 'Hellix-Regular' }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        ml={isMobile ? '1rem' : isTab ? undefined : '2rem'}
      >
        <ChevronRightIcon width={24} />
      </Box>
    </Box>
  );
}
