import { ReactComponent as CloseGreyIcon } from '@assets/icons/close-grey.svg';
import { ReactComponent as WhiteDownloadIcon } from '@assets/icons/download-white.svg';
import { ReactComponent as WhiteFileIcon } from '@assets/icons/file-white.svg';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { CONTENT_TYPE } from '@utils/constants';
import { getFileName } from '@utils/helpers';

interface IProps {
  file?: any;
  fileName?: string;
  onClick?: () => void;
  contentType?: CONTENT_TYPE;
  isUploading?: boolean;
  listingId?: string;
  iconBackColor?: string;
  downloadOnly?: boolean;
  canRemove?: boolean;
  viewOnly?: boolean;
  textColor?: string;
  style?: Object;
  borderColor?: string;
  hideBox?: boolean;
}

export default function FileButton({
  file,
  fileName,
  onClick,
  isUploading,
  canRemove,
  downloadOnly,
  viewOnly,
  style,
  textColor,
  borderColor,
  hideBox,
  iconBackColor = 'none',
  listingId
}: IProps) {
  const DownloadIcon = WhiteDownloadIcon;
  const FileIcon = WhiteFileIcon;

  function renderBtnIcon() {
    if (canRemove) {
      return (
        <Box>
          <DownloadIcon
            onClick={onClick}
            style={{ margin: 2, cursor: 'pointer' }}
          />
          <CloseGreyIcon
            onClick={onClick}
            style={{ margin: 2, cursor: 'pointer' }}
          />
        </Box>
      );
    }

    if (downloadOnly) {
      return (
        <DownloadIcon
          onClick={onClick}
          style={{ margin: 2, cursor: 'pointer' }}
        />
      );
    }

    if (isUploading) {
      return <CircularProgress size={20} />;
    }

    if (viewOnly) return null;

    return <CloseGreyIcon />;
  }

  const border = borderColor || 'rgba(0, 0, 0, 0.08)';

  return (
    <Button
      sx={{
        height: !hideBox ? '3rem' : '3.6rem',
        width: '100%',
        border: !hideBox ? '1px solid  rgba(0, 0, 0, 0.08)' : undefined,
        borderBottom: hideBox ? `2px solid ${border}` : undefined,
        boxShadow: !hideBox ? '0px 4px 10px rgba(0, 0, 0, 0.05)' : undefined,
        borderRadius: !hideBox ? '0.25rem' : 0,
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'none',
        alignItems: 'center',
        px: !hideBox ? '1rem' : 0,
        cursor: 'pointer',
        ...style
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'white'
        }}
      >
        <FileIcon fill={iconBackColor} />

        <Box sx={{ textAlign: 'left', ml: '0.5rem' }}>
          <Typography
            variant="body1"
            color={textColor || 'black'}
            fontFamily="Hellix-SemiBold"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '12rem'
            }}
          >
            {getFileName(fileName, file, listingId)}
          </Typography>
        </Box>
      </Box>

      {renderBtnIcon()}
    </Button>
  );
}
