import { ReactComponent as ShareIcon } from '@assets/icons/share-light-mode.svg';
import OrderInput from '@components/invest/OrderInput';
import ShareModal from '@components/modals/ShareModal';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { IListing } from '@utils/models';
import { useState } from 'react';

interface IProps {
  listing: IListing;
}

export default function TradeWidget({ listing }: IProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <OrderInput listing={listing} />
      <Divider
        sx={{
          mx: '1rem',
          mb: '0.5rem',
          borderColor: 'rgba(255, 255, 255, 0.5)'
        }}
      />

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => setShowModal(true)}
        paddingLeft="0.5rem"
        paddingBottom="0.5rem"
      >
        <IconButton size="medium">
          <ShareIcon />
        </IconButton>

        <Typography
          variant="body1"
          sx={{
            cursor: 'pointer',
            color: 'rgba(255, 255, 255, 0.5)',
            transition: 'color 0.3s ease',
            '&:hover': {
              color: '#3152F5'
            }
          }}
        >
          Share Investment Details
        </Typography>
      </Box>

      <ShareModal
        listing={listing}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
