import { ReactComponent as CloseIcon } from '@assets/icons/close-white.svg';
import { Box, IconButton, Modal } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';

interface IProps {
  title: ReactNode;
  description: ReactNode;
  callToAction: ReactNode;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

export default function ModalWrapper({
  title,
  description,
  callToAction,
  showModal,
  setShowModal
}: IProps) {
  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Box
        sx={{
          width: '28.5rem',
          padding: '1rem',
          marginBottom: '1.5rem',
          paddingBottom: '2.375rem',
          borderRadius: '0.5rem',
          backgroundColor: '#3152F5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 0
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mb: '1rem'
          }}
        >
          <IconButton onClick={() => setShowModal(false)} size="small">
            <CloseIcon width={20} />
          </IconButton>
        </Box>
        <Box
          paddingX="3rem"
          paddingY="1.5rem"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '0.5rem',
            backgroundColor: '#FFFFFF',
            marginBottom: '1.875rem'
          }}
        >
          {title}
          {description}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {callToAction}
        </Box>
      </Box>
    </Modal>
  );
}
