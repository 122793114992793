import { ReactComponent as ParallelIcon } from '@assets/icons/parallel-button.svg';
import { ReactComponent as ParalleContlIcon } from '@assets/icons/parallel-continue-button.svg';
import { useAuth } from '@contexts/AuthContext';
import { Box, Typography } from '@mui/material';
import { useParallel } from '@parallelmarkets/react';
import { ENTITY_TYPE } from '@utils/constants';
import { useEffect } from 'react';

export default function ParallelIntegration() {
  const { parallel, loginStatus, getAccreditations, getIdentity } =
    useParallel();
  const { user, updateUserById, setParallelmarkets, parallelmarkets } =
    useAuth();
  const isParallelmarkets = localStorage.getItem('parallelmarkets');

  async function VerifyUser() {
    const parallelRes = await getAccreditations();
    let isVerified = false;
    if (parallelRes.accreditations.some(({ status }) => status === 'current')) {
      isVerified = true;
    }

    const parallelIRes = await getIdentity();
    const userInfo = getUserInfo(parallelIRes);
    await updateUserById({
      ...userInfo,
      parallelMarkets: {
        id: parallelRes.user_id,
        type: parallelRes.type,
        token: {
          accessToken: loginStatus.authResponse.access_token,
          refreshToken: loginStatus.authResponse.refresh_token
        }
      },
      isAccreditationVerified: isVerified
    });
  }

  function getUserInfo(identityInfo) {
    try {
      if (!identityInfo.identity_details) {
        return {};
      }

      const type = identityInfo.type;
      if (type === 'individual') {
        let info: any = {};

        if (identityInfo.identity_details.first_name) {
          info = {
            fullName: `${identityInfo.identity_details.first_name} ${identityInfo.identity_details.last_name}`
          };
        }

        if (identityInfo.identity_details.phone) {
          info = {
            ...info,
            phone: identityInfo.identity_details.phone.startsWith('+1')
              ? identityInfo.identity_details.phone.slice(2)
              : identityInfo.identity_details.phone.slice(-9)
          };
        }

        if (
          identityInfo.identity_details.us_tax_id ||
          identityInfo.identity_details.foreign_tax_id
        ) {
          info = {
            ...info,
            socialSecurity: identityInfo.identity_details.us_tax_id
              ? identityInfo.identity_details.us_tax_id.replace(/\D/g, '')
              : identityInfo.identity_details.foreign_tax_id.replace(/\D/g, '')
          };
        }

        if (identityInfo.identity_details.residence_location) {
          info = {
            ...info,
            address: {
              street:
                identityInfo.identity_details.residence_location.address_one,
              suite:
                identityInfo.identity_details.residence_location.address_two,
              city: identityInfo.identity_details.residence_location.city,
              state: identityInfo.identity_details.residence_location.state,
              country: identityInfo.identity_details.residence_location.country,
              zipCode:
                identityInfo.identity_details.residence_location.postal_code
            }
          };
        }

        return info;
      }

      if (type === 'business') {
        let info: any = {};

        if (identityInfo.identity_details.primary_contact) {
          info = {
            fullName: `${identityInfo.identity_details.primary_contact.first_name} ${identityInfo.identity_details.primary_contact.last_name}`
          };
        }

        let entity: any = {
          isSignatory: false
        };

        if (identityInfo.identity_details) {
          entity = { legalName: identityInfo.identity_details.name };
        }

        if (identityInfo.identity_details.business_type.business_type) {
          entity = {
            ...entity,
            type: identityInfo.identity_details.business_type.business_type.includes(
              'Trust'
            )
              ? ENTITY_TYPE.TRUST
              : ENTITY_TYPE.LLC
          };
        }

        if (
          identityInfo.identity_details.us_tax_id ||
          identityInfo.identity_details.foreign_tax_id
        ) {
          entity = {
            ...entity,
            taxId: identityInfo.identity_details.us_tax_id
              ? identityInfo.identity_details.us_tax_id.replace(/\D/g, '')
              : identityInfo.identity_details.foreign_tax_id.replace(/\D/g, '')
          };
        }

        if (identityInfo.identity_details.principal_location) {
          entity = {
            ...entity,
            address: {
              street:
                identityInfo.identity_details.principal_location.address_one,
              suite:
                identityInfo.identity_details.principal_location.address_two,
              city: identityInfo.identity_details.principal_location.city,
              state: identityInfo.identity_details.principal_location.state,
              country: identityInfo.identity_details.principal_location.country,
              zipCode:
                identityInfo.identity_details.principal_location.postal_code
            }
          };
        }
        info = { ...info, entity };
        return info;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  const renderCustomPassportButton = () => {
    if (!parallel) return null;

    return (
      <Box
        sx={{
          cursor: 'grab'
        }}
        onClick={() =>
          parallel.login({
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName
          })
        }
      >
        {loginStatus?.status === 'connected' && <ParalleContlIcon />}
        {loginStatus?.status != 'connected' && <ParallelIcon />}
      </Box>
    );
  };

  useEffect(() => {
    // user isn't authenticated or library isn't loaded yet
    if (loginStatus?.status === 'connected') {
      VerifyUser();
    }
  }, [parallel, loginStatus]);

  useEffect(() => {
    if (isParallelmarkets === 'true' || parallelmarkets) {
      localStorage.removeItem('parallelmarkets');
      setParallelmarkets(false);
    }
  }, [isParallelmarkets, parallelmarkets]);

  return (
    <>
      <Box sx={{ width: '30rem' }}>
        <Typography variant="body1" textAlign="center" mb="1.5rem">
          Accredited investors will receive immediate access to investment
          apportunities on OwnProp. We’ve partnered with Parallel Markets - a
          trusted identity partner - to make accreditation verification painless
          and confidential.
        </Typography>
        <Typography variant="body1" textAlign="center" mb="2rem">
          You will be taken to Parallel Market’s accreditation onboarding. When
          you have completed accreditation, you will be returned to OwnProp.
        </Typography>
        {renderCustomPassportButton()}
      </Box>
    </>
  );
}
