import InvestmentCard from '@components/cards/InvestmentCard';
import PageLayout from '@components/layouts/PageLayout';
import Documents from '@components/listings/Documents';
import Highlights from '@components/listings/Highlights';
import ListingSidebar from '@components/listings/ListingSidebar';
import PropertySummary from '@components/listings/PropertySummary';
import TheInvestment from '@components/listings/TheInvestment';
import TheProperty from '@components/listings/TheProperty';
import TheSponsor from '@components/listings/TheSponsor';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useInvest } from '@contexts/InvestContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { ORDER_STATUS } from '@utils/constants';
import { IInvestment, IListing, ISection } from '@utils/models';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';

export default function ListingPage() {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const { getInvestmentByListing } = useInvest();
  const { getListingById } = useAxios();
  const [listing, setListing] = useState<IListing | null>(null);
  const [investments, setInvestments] = useState<IInvestment[]>([]);

  const summaryRef = useRef(null);
  const investmentRef = useRef(null);
  const sponsorRef = useRef(null);
  const propertyRef = useRef(null);
  const marketRef = useRef(null);
  const documentsRef = useRef(null);

  const sections: ISection[] = [
    { name: 'summary', value: 'The Deal', ref: summaryRef },
    { name: 'sponsor', value: 'The Sponsor', ref: sponsorRef },
    { name: 'investment', value: 'The Investment', ref: investmentRef },
    { name: 'property', value: 'The Property', ref: propertyRef },
    { name: 'market', value: 'The Market', ref: marketRef },
    { name: 'documents', value: 'Documents', ref: documentsRef }
  ];

  const fetchlisting = async () => {
    const listing = await getListingById(id);
    setListing(listing);
  };

  async function fetchInvestment() {
    const allInvestments = await getInvestmentByListing(id);
    if (allInvestments && allInvestments.length > 0) {
      const filtered = allInvestments.filter(
        ({ orderStatus }) => orderStatus === ORDER_STATUS.COMPLETE
      );

      setInvestments(filtered);
    } else {
      setInvestments([]);
    }
  }

  useEffect(() => {
    fetchlisting();
    fetchInvestment();
  }, [user]);

  const grouped = _.groupBy(investments, (investment) => investment.listing.id);
  const memoizedAggregated: IInvestment[] = useMemo(() => {
    const aggregated: IInvestment[] = [];
    for (const investment in grouped) {
      const totalTokens = grouped[investment]
        .filter(({ orderStatus }) => orderStatus === ORDER_STATUS.COMPLETE)
        .map(({ numTokens }) => numTokens)
        .reduce((acc, cur) => acc + cur, 0);

      if (totalTokens) {
        aggregated.push({ ...grouped[investment][0], numTokens: totalTokens });
      }
    }

    return aggregated;
  }, [grouped]);

  if (!listing) return null;
  const { property, highlights, sponsor, token } = listing;
  const { address, profile, imageUrls } = property;
  // OVERIDE ATX
  const hideProperty = listing.id === '6526fcb3f7fb5d577b1bc15f';

  return (
    <PageLayout pageTitle="Property Details">
      <Grid item xs={12}>
        <Box
          style={{
            marginTop: isMobile && '10rem',
            height: '40rem',
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.20), rgba(0, 0, 0)), url(${imageUrls?.[0]})`,
            position: 'relative',
            bottom: '6rem'
          }}
        />
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          position: 'relative',
          paddingX: isMobile ? '1rem' : '6rem',
          bottom: '20rem',
          marginBottom: '-20rem'
        }}
      >
        <Grid item xs={isMobile ? 12 : 7}>
          <Box>
            <PropertySummary property={property} listing={listing} />
            {isMobile && (
              <ListingSidebar listing={listing} sections={sections} />
            )}
          </Box>

          {memoizedAggregated.length > 0 && (
            <Grid item mb="2rem">
              <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
                Your Assets
              </Typography>
              <Box mt="1rem">
                <InvestmentCard investment={memoizedAggregated[0]} />
              </Box>
            </Grid>
          )}

          <Grid item ref={summaryRef} mb={isMobile ? '3rem' : '4.25rem'}>
            <Highlights highlights={highlights} />
            {listing?.videoUrl && (
              <ReactPlayer width="100%" url={listing.videoUrl} controls />
            )}
          </Grid>

          <Box ref={sponsorRef}>
            <TheSponsor sponsor={sponsor} />
          </Box>

          <Box ref={investmentRef}>
            <TheInvestment
              listing={listing}
              financials={listing?.financials}
              operations={property?.financials}
              financing={property?.financing}
              token={token}
            />
          </Box>

          {!hideProperty && (
            <Box ref={propertyRef}>
              <TheProperty address={address} profile={profile} />
            </Box>
          )}

          <Box ref={documentsRef}>
            <Documents listingId={listing?.id} />
          </Box>
        </Grid>

        {!isMobile && <Grid item xs={1.5} />}

        {!isMobile && (
          <Grid item xs={3}>
            <ListingSidebar listing={listing} sections={sections} />
          </Grid>
        )}
      </Grid>
    </PageLayout>
  );
}
