import { ReactComponent as CloseIcon } from '@assets/icons/close-white.svg';
import Congratulations from '@components/invest/Congratuations';
import OrderReview from '@components/invest/OrderReview';
import { Box, IconButton, Modal } from '@mui/material';
import { IListing, IPaymentMethod } from '@utils/models';
import { useState } from 'react';

interface IProps {
  listing: IListing;
  showModal: boolean;
  onDismiss: () => void;
  paymentMethods: IPaymentMethod[];
}

export default function InvestModal({
  listing,
  showModal,
  onDismiss,
  paymentMethods
}: IProps) {
  const [active, setActive] = useState(0);
  const incrementStep = () => setActive(active + 1);

  function renderContent() {
    if (!active) {
      return (
        <OrderReview
          listing={listing}
          incrementStep={incrementStep}
          paymentMethods={paymentMethods}
        />
      );
    } else {
      return <Congratulations listing={listing} />;
    }
  }

  return (
    <Modal open={showModal} onClose={onDismiss}>
      <Box
        sx={{
          width: '32.5rem',
          padding: '1rem',
          paddingBottom: '1.5rem',
          borderRadius: '0.5rem',
          backgroundColor: '#3152F5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 0
        }}
      >
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <IconButton onClick={onDismiss} size="small">
            <CloseIcon width={20} />
          </IconButton>
        </Box>
        {renderContent()}
      </Box>
    </Modal>
  );
}
