import { Box, Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

interface IProps {
  name: string;
  label: ReactNode;
  control: any;
  checked?: boolean;
}

export default function CheckboxInput({
  name,
  label,
  control,
  checked
}: IProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: '-0.75rem',
            mb: '1.5rem'
          }}
        >
          <Checkbox
            color="secondary"
            onClick={() => onChange(!value)}
            checked={checked}
            sx={{
              color: '#FFFFFF',
              '&.Mui-checked': {
                color: '#FFFFFF'
              }
            }}
          />
          <Box>{label}</Box>
        </Box>
      )}
    />
  );
}
