import { ReactComponent as ChartGrey } from '@assets/icons/chart-grey.svg';
import { ReactComponent as ClockGrey } from '@assets/icons/clock-grey.svg';
import { ReactComponent as RealEstate } from '@assets/icons/real-estate-grey.svg';
import { ReactComponent as UserGrey } from '@assets/icons/user-grey.svg';
import { LISTING_ICON } from '@utils/constants';

interface IProps {
  icon: string;
}

export default function IconGenerator({ icon }: IProps) {
  function getImageFromKey(icon: string) {
    switch (icon) {
      case LISTING_ICON.CHART:
        return <ChartGrey />;
      case LISTING_ICON.USER:
        return <UserGrey />;
      case LISTING_ICON.CLOCK:
        return <ClockGrey />;
      case LISTING_ICON.BUILDING:
        return <RealEstate />;
    }
  }

  return getImageFromKey(icon);
}
