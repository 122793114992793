import PrimaryButton from '@components/buttons/PrimaryButton';
import RadioInput from '@components/inputs/RadioInput';
import AuthLayout from '@components/layouts/AuthLayout';
import { useAuth } from '@contexts/AuthContext';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ACCREDITATION_TYPE } from '@utils/constants';
import { isAuthenticated } from '@utils/helpers';
import { AUTH_ROUTES, ROOT_ROUTES } from '@utils/routes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  accreditationType: ACCREDITATION_TYPE;
}

export default function Accreditation() {
  const navigate = useNavigate();
  const { updateUserById, user } = useAuth();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { control, handleSubmit, watch } = useForm<IFormInput>({
    defaultValues: { accreditationType: null },
    mode: 'onChange'
  });

  async function handleClick({ accreditationType }) {
    await updateUserById({ accreditationType });
    navigate(ROOT_ROUTES.HOME);
  }

  useEffect(() => {
    if (!user) {
      return navigate(AUTH_ROUTES.GET_STARTED);
    } else if (isAuthenticated(user)) {
      return navigate(ROOT_ROUTES.HOME);
    }
  }, [user]);

  return (
    <AuthLayout pageTitle="Are you an accredited investor?">
      <Box
        sx={{
          width: isMobile ? '100%' : '37rem',
          backgroundColor: '#171717',
          border: '2px solid #323232',
          padding: '2rem',
          marginY: '2rem',
          borderRadius: '0.25rem'
        }}
      >
        <Typography variant="h3" mb="1.5rem" textAlign={'center'}>
          Are you an accredited investor?
        </Typography>
        <Typography variant="body1" mb="2rem" textAlign={'center'}>
          Accredited investors can begin investing immediately. If you are
          unsure about your accreditation status, please review the listed
          categories and select which applies.
        </Typography>

        <RadioInput
          name="accreditationType"
          control={control}
          rules={{ require: true }}
          options={[
            {
              label: 'Annual Income',
              value: ACCREDITATION_TYPE.ANNUAL_INCOME,
              description:
                'I made more than $200k annually (or $300k combined with my spouse) for the past 2 years, and expect the same this year.'
            },
            {
              label: 'Net Worth',
              value: ACCREDITATION_TYPE.NET_WORTH,
              description:
                'I currently have an individual (or joint with my spouse) net worth of over $1 million, excluding my primary residence.'
            },
            {
              label: 'FINRA License',
              value: ACCREDITATION_TYPE.FINRA_LICENSE,
              description:
                'I currently hold a Series 7, Series 65, or Series 82 securities license.'
            },
            {
              label: 'LLC or Trust',
              value: ACCREDITATION_TYPE.ENTITY_ASSETS,
              description:
                'I am the authorized signatory for an entity that hold $5 million in assets or all members are accredited.'
            },
            {
              label: 'I`m not Accredited',
              value: ACCREDITATION_TYPE.NOT_ACCREDITED,
              description:
                'That`s ok. You will still be able to invest with OwnProp in the near future.'
            }
          ]}
        />
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            paddingX: !isMobile && '8rem'
          }}
        >
          <PrimaryButton
            btnText="Submit"
            onClick={handleSubmit(handleClick)}
            isDisabled={!watch('accreditationType')}
            isFullWidth
          />
        </Box>
      </Box>
    </AuthLayout>
  );
}
