import Accreditation from '@pages/auth/Accreditation';
import GetStarted from '@pages/auth/GetStarted';
import LoginAccount from '@pages/auth/LoginAccount';
import VerifyEmail from '@pages/auth/VerifyEmail';
import { Route, Routes } from 'react-router-dom';

export default function AuthRouter() {
  return (
    <Routes>
      <Route path="/login" element={<LoginAccount />} />
      <Route path="/get-started" element={<GetStarted />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/accreditation" element={<Accreditation />} />
    </Routes>
  );
}
