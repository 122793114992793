import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { formatAddress, getCurrentURL } from '@utils/helpers';
import { IListing } from '@utils/models';
import { useNavigate } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';

interface IProps {
  listing: IListing;
  showModal: boolean;
  closeModal: () => void;
}

export default function ShareModal({ listing, showModal, closeModal }: IProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const currentURL = getCurrentURL();
  const tags = [listing?.property?.type, listing?.profile];
  const shareBtnStyles = {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    padding: isMobile ? '0.6rem' : '1.2rem',
    border: '1px solid rgba(50, 50, 50, 0.2)',
    borderRadius: '0.4rem'
  };

  function renderShare(text, Icon) {
    return (
      <>
        <Icon size={40} style={{ borderRadius: '0.25rem' }} />
        <Typography
          variant="body1"
          color="black"
          ml={isMobile ? '0.8rem' : '2rem'}
        >
          {text}
        </Typography>
      </>
    );
  }

  const SOCIAL_LINKS = [
    {
      id: 1,
      Button: (
        <FacebookShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('Facebook', FacebookIcon)}
        </FacebookShareButton>
      )
    },
    {
      id: 2,
      Button: (
        <FacebookMessengerShareButton
          url={currentURL}
          style={shareBtnStyles}
          appId=""
        >
          {renderShare('Messenger', FacebookMessengerIcon)}
        </FacebookMessengerShareButton>
      )
    },
    {
      id: 3,
      Button: (
        <TwitterShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('Twitter', TwitterIcon)}
        </TwitterShareButton>
      )
    },
    {
      id: 4,
      Button: (
        <TelegramShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('Telegram', TelegramIcon)}
        </TelegramShareButton>
      )
    },
    {
      id: 5,
      Button: (
        <LinkedinShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('LinkedIn', LinkedinIcon)}
        </LinkedinShareButton>
      )
    },
    {
      id: 6,
      Button: (
        <RedditShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('Reddit', RedditIcon)}
        </RedditShareButton>
      )
    },
    {
      id: 7,
      Button: (
        <EmailShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('Email', EmailIcon)}
        </EmailShareButton>
      )
    },
    {
      id: 8,
      Button: (
        <WhatsappShareButton url={currentURL} style={shareBtnStyles}>
          {renderShare('Whatsapp', WhatsappIcon)}
        </WhatsappShareButton>
      )
    }
  ];

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          border: 'none',
          boxShadow: 24,
          textAlign: 'center',
          px: '2rem',
          py: '1.5rem',
          borderRadius: '0.25rem',
          outline: 0
        }}
      >
        <Box display="flex" flexDirection="row">
          <IconButton
            onClick={closeModal}
            size="small"
            sx={{
              position: 'absolute',
              right: '2rem',
              marginLeft: !isMobile ? -1 : 0
            }}
          >
            <CloseIcon width={20} />
          </IconButton>
        </Box>

        <Box display="flex" mb="2rem" mt="2rem">
          <Typography variant="h5" color="black">
            Don't miss out on investing in {listing?.property?.name}!
          </Typography>
        </Box>

        <Box my="1rem">
          <Box display="flex" alignItems="center" mb="1.5rem">
            <img
              style={{
                borderRadius: '0.4rem',
                marginRight: '1.5rem',
                marginBottom: '1rem',
                cursor: 'pointer'
              }}
              onClick={() => navigate(`/listings/${listing?.id}`)}
              src={listing?.property?.imageUrls[0]}
              width={isMobile ? '100%' : 200}
              height={140}
            />

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box mb="1rem">
                {tags.map((tag, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'inline-flex',
                      color: 'black',
                      border: '2px solid black',
                      padding: '0.5rem 0.75rem',
                      borderRadius: '2.5rem',
                      marginRight: '0.5rem'
                    }}
                  >
                    <Typography variant="button">
                      {tag?.split('_').join(' ')}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/listings/${listing?.id}`)}
              >
                <Typography variant="h5" mb="0.5rem" color="black">
                  {listing?.property?.name}
                </Typography>
                <Typography variant="body1" mb="0.5rem" color="black">
                  {formatAddress(listing?.property?.address)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Grid
            item
            xs={12}
            sx={{
              display: 'grid',
              rowGap: 2,
              columnGap: 2,
              gridTemplateColumns: 'repeat(4, 1fr)'
            }}
          >
            {SOCIAL_LINKS.map(({ Button }, idx) => {
              return <Box key={idx}>{Button}</Box>;
            })}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
