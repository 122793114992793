import { ReactComponent as CloseIcon } from '@assets/icons/close-white.svg';
import { ReactComponent as NextArrow } from '@assets/icons/next.svg';
import { ReactComponent as PrevArrow } from '@assets/icons/previous.svg';
import { ReactComponent as ShareIcon } from '@assets/icons/share-white.svg';
import ShareModal from '@components/modals/ShareModal';
import { useIsTab, useKeyPress } from '@hooks/useIsMobile';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { IListing } from '@utils/models';
import { theme } from '@utils/theme';
import { useEffect, useState } from 'react';

interface IProps {
  selectedIndex: number;
  listing: IListing;
  showPreview: boolean;
  closePreview: () => void;
}

export default function ImagePreview({
  selectedIndex,
  listing,
  showPreview,
  closePreview
}: IProps) {
  const [showSocialShare, setShowSocialShare] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  const leftPress = useKeyPress('ArrowLeft');
  const rightPress = useKeyPress('ArrowRight');
  const istab = useIsTab();

  const { property } = { ...listing };
  const { imageUrls, address } = { ...property };
  const location = [address?.city, ', ', address?.state].join('').toUpperCase();

  useEffect(() => {
    setCurrentIndex(selectedIndex);
  }, [selectedIndex]);

  const currentImageText = [currentIndex + 1, 'of', imageUrls?.length].join(
    ' '
  );

  const openSocialShare = () => {
    setShowSocialShare(true);
  };

  const closeSocialShare = () => {
    setShowSocialShare(false);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex) => currentIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex) => currentIndex + 1);
  };

  const renderArrows = () => {
    if (imageUrls?.length < 2) return null;

    return (
      <Box sx={{ position: 'absolute', height: '100%', width: '100%' }}>
        {currentIndex !== 0 && (
          <IconButton
            sx={{ top: '45%', zIndex: 1, float: 'left', marginLeft: '-1.5rem' }}
            onClick={handlePrev}
          >
            <PrevArrow />
          </IconButton>
        )}

        {currentIndex + 1 !== imageUrls.length && (
          <IconButton
            sx={{
              top: '45%',
              zIndex: 1,
              float: 'right',
              marginRight: '-1.5rem'
            }}
            onClick={handleNext}
          >
            <NextArrow />
          </IconButton>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (leftPress && currentIndex > 0) {
      handlePrev();
    }
  }, [leftPress]);

  useEffect(() => {
    if (rightPress && imageUrls?.length != currentIndex + 1) {
      handleNext();
    }
  }, [rightPress]);

  return (
    <>
      <Modal open={showPreview} onClose={closePreview}>
        <Box
          display="flex"
          flexDirection="column"
          px={istab ? '5rem' : '20rem'}
          sx={{
            width: '100%',
            height: '100vh',
            backgroundColor: 'black'
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            pt="2rem"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                onClick={closePreview}
                size="medium"
                sx={{ marginLeft: -2 }}
              >
                <CloseIcon />
              </IconButton>

              <Typography
                variant="body2"
                onClick={closePreview}
                fontWeight="600"
                sx={{ color: theme.palette.secondary.main, cursor: 'pointer' }}
              >
                Close
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={openSocialShare} size="medium">
                <ShareIcon />
              </IconButton>

              <Typography
                variant="body2"
                onClick={openSocialShare}
                fontWeight="600"
                sx={{ color: theme.palette.secondary.main, cursor: 'pointer' }}
              >
                Share
              </Typography>
            </Box>
          </Box>

          <Box display="flex" pt="2rem" sx={{ position: 'relative' }}>
            {renderArrows()}
            <img
              src={imageUrls[currentIndex]}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 4
              }}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt="2rem"
          >
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{ color: '#FFFFFF', width: '100px' }}
            >
              {currentImageText}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <ShareModal
        listing={listing}
        showModal={showSocialShare}
        closeModal={closeSocialShare}
      />
    </>
  );
}
