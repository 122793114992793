import { useAuth } from '@contexts/AuthContext';
import { CONTENT_TYPE, REQUEST_PLATFORM } from '@utils/constants';
import { IListing, IPaymentMethod, ServiceResponse } from '@utils/models';
import { createContext, ReactNode, useContext } from 'react';

export interface IAxiosContext {
  getAllListings: () => Promise<IListing[]>;
  getListingById: (listingId: string) => Promise<IListing>;
  createPaymentIntent: () => Promise<any>;
  createLinkToken: (platform: REQUEST_PLATFORM) => Promise<string>;
  exchangePublicToken: (
    publicToken: string,
    accountId: string
  ) => Promise<IPaymentMethod>;
  getPaymentMethods: () => Promise<IPaymentMethod[]>;
  removePaymentMethod: (stripeToken: string) => Promise<void>;
  purchaseTokens: (investmentId: string) => void;
  queryListingFiles: (listingId: string) => Promise<any>;
  queryUserFiles: (contentType: CONTENT_TYPE) => Promise<any>;
  getFileUploadUrl: (
    fileName: string,
    fileType: string,
    contentType: CONTENT_TYPE
  ) => Promise<any>;
  getUserFileDownloadUrl: (
    fileName: string,
    contentType: CONTENT_TYPE
  ) => Promise<any>;
  queryListingReportFiles: (listingId: string) => Promise<any>;
  getListingReportFileDownloadUrl: (
    fileName: string,
    listingId: string
  ) => Promise<any>;
  deleteFile: (fileName: string, contentType: CONTENT_TYPE) => Promise<any>;
  getListingFileDownloadUrl: (
    fileName: string,
    listingId: string
  ) => Promise<any>;
  getLocations: (searchText: string) => Promise<any>;
  getUserWaitListPosition: () => Promise<number>;
  addFunds: (amount: number, token: string) => Promise<any>;
  withdrawFunds: (
    accountNumber: string,
    routingNumber: string,
    amount: string
  ) => Promise<any>;
}

const DefaultValues: IAxiosContext = {
  getAllListings: () => null,
  getListingById: () => null,
  createLinkToken: () => null,
  createPaymentIntent: () => null,
  exchangePublicToken: () => null,
  getPaymentMethods: () => null,
  removePaymentMethod: () => null,
  purchaseTokens: () => null,
  queryListingFiles: () => null,
  queryUserFiles: () => null,
  getFileUploadUrl: () => null,
  getUserFileDownloadUrl: () => null,
  deleteFile: () => null,
  getListingFileDownloadUrl: () => null,
  queryListingReportFiles: () => null,
  getListingReportFileDownloadUrl: () => null,
  getLocations: () => null,
  getUserWaitListPosition: () => null,
  addFunds: () => null,
  withdrawFunds: () => null
};

const AxiosContext = createContext<IAxiosContext>(DefaultValues);

export function useAxios() {
  return useContext(AxiosContext);
}

interface IProps {
  children: ReactNode;
}

export function AxiosProvider({ children }: IProps) {
  const { apiClient, setUser } = useAuth();

  const getAllListings = async (): Promise<IListing[]> => {
    const res = await apiClient.get<ServiceResponse<IListing>>('/listings');
    return res.data.results;
  };

  const getListingById = async (listingId: string) => {
    const res = await apiClient.get<IListing>(`/listings/${listingId}`);
    return res.data;
  };

  const createPaymentIntent = async (): Promise<any> => {
    const res = await apiClient.post<string>(`/payments/create_intent`);
    return res.data;
  };

  const createLinkToken = async (
    platform: REQUEST_PLATFORM
  ): Promise<string> => {
    let payLoad: any = {
      platform,
      type: 'IDENTITY_VERIFICATION'
    };
    const res = await apiClient.post<string>(
      `/payments/create_link_token`,
      payLoad
    );
    return res.data;
  };

  const exchangePublicToken = async (
    publicToken: string,
    accountId: string
  ): Promise<IPaymentMethod> => {
    let payLoad: any = {
      publicToken,
      accountId
    };
    const res = await apiClient.post(
      `/payments/exchange_public_token`,
      payLoad
    );
    return res.data;
  };

  const getPaymentMethods = async () => {
    const res = await apiClient.get<IPaymentMethod[]>(`/payments`);
    return res.data;
  };

  const removePaymentMethod = async (stripeToken: string) => {
    const res = await apiClient.delete<void>(`/payments?token=${stripeToken}`);
    return res.data;
  };

  const purchaseTokens = async (investmentId: string) => {
    let payLoad: any = {
      investmentId
    };
    const res = await apiClient.post(`/payments`, payLoad);
    return res.data;
  };

  const queryListingFiles = async (listingId: string) => {
    const res = await apiClient.get(
      `/storage/search/listings?listingId=${listingId}`
    );
    return res.data;
  };

  const queryUserFiles = async (contentType: CONTENT_TYPE) => {
    const res = await apiClient.get(
      `/storage/search?contentType=${contentType}`
    );
    return res.data;
  };

  const getFileUploadUrl = async (
    fileName: string,
    fileType: string,
    contentType: CONTENT_TYPE
  ) => {
    const res = await apiClient.get(
      `/storage/upload?fileName=${fileName}&fileType=${fileType}&contentType=${contentType}`
    );
    return res.data;
  };

  const getUserFileDownloadUrl = async (
    fileName: string,
    contentType: CONTENT_TYPE
  ) => {
    const res = await apiClient.get(
      `/storage/download?fileName=${fileName}&contentType=${contentType}`
    );
    return res.data;
  };

  const deleteFile = async (fileName: string, contentType: CONTENT_TYPE) => {
    const res = await apiClient.delete(
      `/storage/delete?fileName=${fileName}&contentType=${contentType}`
    );
    return res.data;
  };

  const getListingFileDownloadUrl = async (
    fileName: string,
    listingId: string
  ) => {
    const res = await apiClient.get(
      `/storage/download/listings?fileName=${fileName}&listingId=${listingId}`
    );
    return res.data;
  };

  const getLocations = async (searchText: string) => {
    const res = await apiClient.get(
      `/locations/search?searchText=${searchText}`
    );
    return res.data;
  };

  const queryListingReportFiles = async (listingId: string) => {
    const res = await apiClient.get(
      `/storage/search/listings/reports?listingId=${listingId}`
    );
    return res.data;
  };

  const getListingReportFileDownloadUrl = async (
    fileName: string,
    listingId: string
  ) => {
    const res = await apiClient.get(
      `/storage/download/listings/reports?fileName=${fileName}&listingId=${listingId}`
    );
    return res.data;
  };

  const getUserWaitListPosition = async (): Promise<number> => {
    const res = await apiClient.get<number>(`/users/waitlist`);
    return res.data;
  };

  const addFunds = async (amount: number, token: string) => {
    let payLoad: any = {
      amount,
      token
    };
    const res = await apiClient.post(`/payments/add-funds`, payLoad);
    res.data && setUser(res.data);
    return res.data;
  };

  const withdrawFunds = async (
    accountNumber: string,
    routingNumber: string,
    amount: string
  ) => {
    let payLoad: any = {
      accountNumber,
      routingNumber,
      amount
    };
    const res = await apiClient.post(`/payments/withdraw-funds`, payLoad);
    res.data && setUser(res.data);
    return res.data;
  };

  const value = {
    getAllListings,
    getListingById,
    createPaymentIntent,
    createLinkToken,
    exchangePublicToken,
    getPaymentMethods,
    removePaymentMethod,
    purchaseTokens,
    queryListingFiles,
    queryUserFiles,
    getFileUploadUrl,
    getUserFileDownloadUrl,
    getListingFileDownloadUrl,
    queryListingReportFiles,
    getListingReportFileDownloadUrl,
    deleteFile,
    getLocations,
    getUserWaitListPosition,
    addFunds,
    withdrawFunds
  };

  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
}
