import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';

interface IProps {
  title: string;
  description: string;
  showModal: boolean;
  setShowModal: any;
}

export default function GlossaryModal({
  title,
  description,
  showModal,
  setShowModal
}: IProps) {
  const isMobile = useIsMobile();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      title={title}
      children={
        <Grid
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: isMobile ? '90%' : '45rem',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            border: 'none',
            boxShadow: 24,
            borderRadius: '0.25rem',
            outline: 0,
            padding: '3rem',
            paddingTop: '2rem'
          }}
        >
          <Box display="flex" flexDirection="row">
            <IconButton
              onClick={() => setShowModal(false)}
              size="small"
              sx={{
                position: 'absolute',
                right: '2rem',
                marginLeft: !isMobile ? -1 : 0
              }}
            >
              <CloseIcon width={20} />
            </IconButton>
          </Box>

          <Typography
            variant="h4"
            color="black"
            sx={{
              maxWidth: '15rem',
              paddingTop: '2rem',
              marginBottom: '0.5rem'
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1" color="black">
            {description}
          </Typography>
        </Grid>
      }
    />
  );
}
