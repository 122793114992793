import { ReactComponent as ExpandMoreIcon } from '@assets/icons/chevron-down.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import CheckboxInput from '@components/inputs/CheckboxInput';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useInvest } from '@contexts/InvestContext';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import {
  ACCREDITATION_STATUS,
  LISTING_TYPE,
  PAYMENT_TYPE
} from '@utils/constants';
import { formatPrice } from '@utils/helpers';
import { IListing, IPaymentMethod } from '@utils/models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface IFormInput {
  isSubscribed: boolean;
}

interface IProps {
  listing: IListing;
  incrementStep: () => void;
  paymentMethods: IPaymentMethod[];
}

export default function OrderReview({
  incrementStep,
  listing,
  paymentMethods
}: IProps) {
  const { investment } = useInvest();
  const { purchaseTokens } = useAxios();
  const { updateInvestmentById, getSubscriptionUrl } = useInvest();
  const { refreshUser, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { control, watch } = useForm<IFormInput>({
    defaultValues: { isSubscribed: false },
    mode: 'onChange'
  });
  const isAccredited =
    user.accreditationStatus === ACCREDITATION_STATUS.APPROVED ||
    user.isAccreditationVerified ||
    listing.type === LISTING_TYPE.RETAIL;

  async function handlePress() {
    try {
      setIsLoading(true);
      await purchaseTokens(investment?.id);
      await refreshUser();
      await window.analytics.track('Purchase Completed', {
        token: listing?.property?.name
      });
      setIsLoading(false);
      incrementStep();
    } catch (err) {
      console.error(err);
    }
  }

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedMethod = paymentMethods.find(
      (p) => p.stripeToken === event.target.value
    );
    if (selectedMethod) {
      await updateInvestmentById(investment.id, {
        paymentMethod: selectedMethod
      });
    }
  };

  useEffect(() => {
    if (watch('isSubscribed') && !investment.isSubscribed) {
      setIsLoading(true);
      getSubscriptionUrl(investment?.id, listing.id, window.location.origin)
        .then((res) => {
          if (res.redirectUrl) {
            window.open(res.redirectUrl, '_self');
          }
        })
        .catch(() => setIsLoading(false));
    }
  }, [watch('isSubscribed')]);

  return (
    <>
      <Typography
        variant="h3"
        sx={{ marginBottom: '1.5rem', textAlign: 'center', color: 'white' }}
      >
        {listing?.property?.name}
      </Typography>

      <Box
        paddingX="3rem"
        paddingY="1.5rem"
        style={{
          width: '100%',
          borderRadius: '0.5rem',
          backgroundColor: '#FFFFFF',
          marginBottom: '1.875rem'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: '1.25rem',
            borderBottomStyle: 'solid',
            borderBottomColor: '#3152F5',
            borderBottomWidth: '2px',
            padding: '0.5rem',
            textAlign: 'center',
            color: 'black'
          }}
        >
          {investment?.numTokens} Tokens
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '1rem'
          }}
        >
          <Typography variant="body1" color="black">
            Token Price
          </Typography>
          <Typography variant="body1" color="black">
            {formatPrice(listing?.token?.price)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '1rem'
          }}
        >
          <Typography variant="body1" color="black">
            Payment Method
          </Typography>
          {/* <Typography variant="body1" color="black">
            ...{investment?.paymentMethod?.lastFour}
          </Typography> */}
          {paymentMethods && paymentMethods.length > 0 && (
            <Select
              variant="standard"
              sx={{
                color: 'black',
                '&:before': {
                  borderBottom: '2px solid black'
                },
                '&:hover': {
                  borderBottom: '2px solid black'
                }
              }}
              value={investment?.paymentMethod?.stripeToken}
              label="Age"
              IconComponent={() => (
                <Box>
                  <ExpandMoreIcon width={34} height={34} />
                </Box>
              )}
              onChange={handleChange}
              SelectDisplayProps={{
                style: {
                  paddingRight: 0
                }
              }}
            >
              {paymentMethods.map((p, idx) => (
                <MenuItem key={idx} value={p.stripeToken}>
                  {p.type === PAYMENT_TYPE.USER_FUNDS
                    ? `${p.bankName}  ${p.lastFour}`
                    : `${p.bankName}  ...${p.lastFour}`}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '1rem'
          }}
        >
          <Typography variant="body1" color="black">
            Purchase Total
          </Typography>
          <Typography variant="body1" color="black">
            {formatPrice(listing?.token?.price * investment?.numTokens)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2" mb="1rem">
          *The purchase of this property is pending and has not yet closed. In
          the event it fails to close, your entire amount will be credited to
          your OwnProp account and available for reinvestment.
        </Typography>

        <CheckboxInput
          name="isSubscribed"
          label={
            <Typography variant="body2">
              I have reviewed the{' '}
              <span
              //style={{ textDecoration: 'underline', cursor: 'pointer' }}
              //onClick={() => openExternalURL(EXTERNAL_ROUTES.SUBSCRIPTION)}
              >
                Subscription Agreement
              </span>{' '}
              per my selected investment and understand the{' '}
              <span
              //style={{ textDecoration: 'underline', cursor: 'pointer' }}
              //onClick={() => openExternalURL(EXTERNAL_ROUTES.RISK_FATORS)}
              >
                Risk Factors
              </span>{' '}
              with such offerings.
            </Typography>
          }
          control={control}
          checked={
            listing.subscriptionTemplateId
              ? investment?.isSubscribed
              : watch('isSubscribed')
          }
        />

        <PrimaryButton
          btnText="Complete Purchase"
          isDisabled={
            listing.subscriptionTemplateId
              ? !(investment?.isSubscribed && isAccredited)
              : !(watch('isSubscribed') && isAccredited)
          }
          isLoading={isLoading}
          onClick={handlePress}
          isOutlined
          isModal
        />
      </Box>
    </>
  );
}
