import { ReactComponent as BankAccountIcon } from '@assets/icons/bank.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/bin.svg';
import { Box, IconButton, Typography } from '@mui/material';
import { IPaymentMethod } from '@utils/models';

interface IProps {
  paymentMethod: IPaymentMethod;
  onPress: (paymentMethod: IPaymentMethod) => void;
  variant: 'Trash' | 'Select' | 'Modal';
  onRemove?: (token: string) => void;
  isFirst?: boolean;
}

export default function PaymentButton({
  paymentMethod,
  onPress,
  variant,
  onRemove,
  isFirst
}: IProps) {
  const { stripeToken, bankName, lastFour } = paymentMethod;
  const boarder = variant === 'Trash' && !isFirst ? '1px solid #FFFFFF4D' : '';
  const container =
    variant === 'Trash' || variant === 'Select'
      ? {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          py: variant === 'Select' ? '2rem' : '3rem',
          height: 60,
          borderTop: boarder
        }
      : {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#171717',
          border: '2px solid #323232',
          paddingY: '1rem',
          cursor: 'pointer',
          marginBottom: '1.5rem'
        };
  const textContentFlex =
    variant === 'Trash' || variant === 'Select' ? 1 : undefined;
  const selectable =
    variant != 'Trash'
      ? {
          cursor: 'pointer',
          top: 0,
          position: 'relative',
          transition: 'all 300ms ease-in-out',
          '&:hover': {
            boxShadow: '0px 16px 30px rgba(0, 0, 0, 0.1)',
            top: ' -0.5rem'
          }
        }
      : {};

  function renderBtn() {
    return (
      <Box sx={container}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <BankAccountIcon widths={34} height={34} />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          flex={textContentFlex}
        >
          <Typography variant="body1" ml="1rem">
            {bankName}
          </Typography>

          {variant === 'Select' && (
            <Typography variant="body2" ml="1rem" sx={{ fontWeight: 600 }}>
              ....{lastFour}
            </Typography>
          )}
        </Box>
        {variant === 'Trash' && (
          <Box display="flex" alignItems="center" ml={'2rem'}>
            <IconButton size="medium" onClick={() => onRemove(stripeToken)}>
              <TrashIcon width={24} />
            </IconButton>
          </Box>
        )}

        {variant === 'Select' && (
          <Typography variant="body1" mt="0.2rem" color="#FFFFFF">
            {'\u2192'}
          </Typography>
        )}
      </Box>
    );
  }

  if (variant !== 'Trash') {
    return (
      <Box onClick={() => onPress(paymentMethod)} sx={selectable}>
        {renderBtn()}
      </Box>
    );
  }
  return renderBtn();
}
