import PrimaryButton from '@components/buttons/PrimaryButton';
import GlossaryModal from '@components/modals/GlossaryModal';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { LISTING_PROFILE, PROPERTY_TYPE } from '@utils/constants';
import {
  LISTING_TYPES,
  PROPERTY_TYPES,
  STATISTICS_TERMS
} from '@utils/glossary';
import { IListing, IProperty } from '@utils/models';
import { useState } from 'react';

interface IProps {
  property: IProperty;
  listing: IListing;
}

export default function PropertySummary({ property, listing }: IProps) {
  const isMobile = useIsMobile();
  const { address, description, title, type } = property;
  const { profile, financials } = listing;
  const city = [address?.city, ', ', address?.state].join('').toUpperCase();
  const tags = [property.type, listing.profile];
  const [showModal, setShowModal] = useState(false);
  const [showGlossary, setShowGlossary] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);

  const propertyTypes = [
    PROPERTY_TYPE.COMMERCIAL,
    PROPERTY_TYPE.MULTIFAMILY,
    PROPERTY_TYPE.HOSPITALITY,
    PROPERTY_TYPE.INDUSTRIAL
  ];
  const listingProfiles = [
    LISTING_PROFILE.FIXED_INCOME,
    LISTING_PROFILE.CORE_PLUS,
    LISTING_PROFILE.VALUE_ADD,
    LISTING_PROFILE.OPPORTUNISTIC
  ];
  const propertyIdx = propertyTypes.indexOf(type);
  const listingIdx = listingProfiles.indexOf(profile);

  let FINANCIALS = [
    { label: 'Projected IRR', value: `${financials?.rateOfReturn}%` },
    {
      label: 'Cash Yield',
      value: `${Math.round(financials?.cashOnCash * 100 * 100) / 100}%`
    },
    { label: 'Equity Multiple', value: `${financials?.equityMultiple}x` },
    { label: 'Target Hold', value: `${financials?.targetHold} Yrs` }
  ];

  // OVERIDE ATX FUND
  const ATXFId = '6526fcb3f7fb5d577b1bc15f';
  const isATXF = listing.id === ATXFId;
  if (isATXF) {
    FINANCIALS = [
      {
        label: 'Projected IRR',
        value: '17-20%'
      },
      {
        label: 'Targeted Diversification',
        value: '10-15 Assets'
      },
      {
        label: 'Fund Size',
        value: '$50M'
      }
    ];
  }
  return (
    <>
      <Box mb={isMobile ? '2rem' : '4.25rem'}>
        <Typography variant="overline">{city}</Typography>
        <Typography variant={isMobile ? 'h3' : 'h1'}>{title}</Typography>
        {isATXF && (
          <Typography variant="body1" mt={isMobile ? '0.5rem' : '1rem'}>
            Interest rates are at their highest point in over two decades. This
            has created distress across real estate, opening rare opportunities
            for experienced investors.
          </Typography>
        )}
        <Typography variant="body1" mt={isMobile ? '0.5rem' : '1rem'}>
          {description}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: isMobile && 'wrap',
            marginY: '1.5rem'
          }}
        >
          {FINANCIALS.map(({ value, label }, idx) => (
            <Box
              key={idx}
              mr="2rem"
              onClick={() => {
                if (isATXF) return;
                setActiveIdx(idx);
                setShowGlossary(true);
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  cursor: 'pointer',
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: '#3152F5'
                  }
                }}
              >
                {value ? value : 'N/A'}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#3152F5'
                  }
                }}
              >
                {label}
              </Typography>
            </Box>
          ))}
        </Box>

        {!isATXF && (
          <Box mt={isMobile ? '1rem' : '2rem'}>
            {tags?.map((tag, idx) => (
              <PrimaryButton
                key={idx}
                btnText={tag?.split('_').join(' ')}
                onClick={() => {
                  setToggle(!!idx);
                  setShowModal(true);
                }}
                style={{ marginRight: '1rem', padding: '1rem' }}
                isOutlined
              />
            ))}
          </Box>
        )}
      </Box>

      <GlossaryModal
        title={
          toggle
            ? LISTING_TYPES[listingIdx].title
            : PROPERTY_TYPES[propertyIdx].title
        }
        description={
          toggle
            ? LISTING_TYPES[listingIdx].description
            : PROPERTY_TYPES[propertyIdx].description
        }
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <GlossaryModal
        title={STATISTICS_TERMS[activeIdx].title}
        description={STATISTICS_TERMS[activeIdx].description}
        showModal={showGlossary}
        setShowModal={setShowGlossary}
      />
    </>
  );
}
