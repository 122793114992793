import WaitlistModal from '@components/modals/WaitlistModal';
import { useAuth } from '@contexts/AuthContext';
import { ACCREDITATION_STATUS, ACCREDITATION_TYPE } from '@utils/constants';
import { openExternalURL } from '@utils/helpers';
import { EXTERNAL_ROUTES } from '@utils/routes';
import ActionCard from './cards/ActionCard';

export const CallToAction = () => {
  const { user } = useAuth();

  if (user?.accreditationType === ACCREDITATION_TYPE.NOT_ACCREDITED) {
    return <WaitlistModal />;
  } else if (
    !(
      (user && user.accreditationStatus === ACCREDITATION_STATUS.APPROVED) ||
      (user && user.isAccreditationVerified)
    )
  ) {
    return (
      <ActionCard
        title={
          user?.parallelMarkets?.id
            ? 'Awaiting Verification'
            : 'Action Required'
        }
        description="Verify identity to continue investing"
        onClick={() => openExternalURL(EXTERNAL_ROUTES.PARALLEL_MARKETS)}
      />
    );
  }
};
