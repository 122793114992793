import { ReactComponent as DownloadIcon } from '@assets/icons/download-circle-white.svg';
import { Accreditation } from '@components/account/Accreditation';
import { Documents } from '@components/account/Documents';
import { InvestorProfile } from '@components/account/InvestorProfile';
import { Notifications } from '@components/account/Notifications';
import { OrderHistory } from '@components/account/OrderHistory';
import { PaymentMethods } from '@components/account/PaymentMethods';
import PageLayout from '@components/layouts/PageLayout';
import PageHeader from '@components/PageHeader';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile, useIsTab } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { EXTERNAL_ROUTES } from '@utils/routes';
import { useState } from 'react';

export default function AccountPage() {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const { logout } = useAuth();
  const [active, setActive] = useState(0);
  const [activeLink, setActiveLink] = useState(0);

  const ACCOUNT_NAV = [
    {
      title: 'Account',
      links: [
        {
          subTitle: 'Profile',
          onClick: () => {
            setActive(0);
            setActiveLink(0);
          },
          component: <InvestorProfile />
        },
        {
          subTitle: 'Notifications',
          onClick: () => {
            setActive(0);
            setActiveLink(1);
          },
          component: <Notifications />
        },
        {
          subTitle: 'Payment Methods',
          onClick: () => {
            setActive(0);
            setActiveLink(2);
          },
          component: <PaymentMethods />
        }
      ]
    },
    {
      title: 'Investments',
      links: [
        {
          subTitle: 'Accreditation',
          onClick: () => {
            setActive(1);
            setActiveLink(0);
          },
          component: <Accreditation />
        },
        {
          subTitle: 'Documents',
          image: <DownloadIcon width={26} height={26} />,
          onClick: () => {
            setActive(1);
            setActiveLink(1);
          },
          component: <Documents />
        },
        {
          subTitle: 'History',
          onClick: () => {
            setActive(1);
            setActiveLink(2);
          },
          component: <OrderHistory />
        }
      ]
    },
    {
      title: 'Legal',
      links: [
        {
          subTitle: 'Terms of Service',
          onClick: () => window.open(EXTERNAL_ROUTES.TERMS),
          component: <></>
        },
        {
          subTitle: 'Privacy Policy',
          onClick: () => window.open(EXTERNAL_ROUTES.PRIVACY),
          component: <></>
        },
        {
          subTitle: 'Sign Out',
          onClick: () => logout(),
          component: <></>
        }
      ]
    }
  ];

  return (
    <PageLayout pageTitle="Account">
      <Grid
        container
        sx={{
          paddingX: isMobile ? '1rem' : '6rem',
          paddingBottom: '1rem',
          width: isTab ? '100vw' : '80vw'
        }}
      >
        <PageHeader title="Account" />

        <Grid item xs={3}>
          {ACCOUNT_NAV.map(({ title, links }, idx) => (
            <Box
              key={idx}
              sx={{
                paddingBottom: '2rem'
              }}
            >
              <Typography key={idx} variant="overline" color="#FFFFFF80">
                {title}
              </Typography>
              {links.map(({ subTitle, onClick }, idx) => (
                <Typography
                  key={idx}
                  variant="body1"
                  onClick={onClick}
                  sx={{
                    cursor: 'pointer',
                    paddingBottom: '1rem',
                    paddingTop: idx === 0 ? '1rem' : 0,
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: '#3152F5'
                    }
                  }}
                >
                  {subTitle}
                </Typography>
              ))}
            </Box>
          ))}
        </Grid>

        <Grid
          item
          xs={9}
          sx={{ backgroundColor: '#171717', border: '2px solid #323232' }}
        >
          <Box sx={{ padding: '20px' }}>
            <Typography variant="h5" paddingBottom={'30px'}>
              {ACCOUNT_NAV[active].links[activeLink].subTitle}
            </Typography>

            {ACCOUNT_NAV[active].links[activeLink].component}
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
