import { ReactComponent as CloseWhite } from '@assets/icons/close-white.svg';
import { ReactComponent as HamburgerWhite } from '@assets/icons/hamburger-white.svg';
import { ReactComponent as LogoWhite } from '@assets/icons/logo-white.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import { useAuth } from '@contexts/AuthContext';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { NAV_LINKS } from '@utils/constants';
import { isAuthenticated, isProfileComplete } from '@utils/helpers';
import { AUTH_ROUTES } from '@utils/routes';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function MobileNavbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user, logout } = useAuth();
  const isAuthenticatedUser = useMemo(() => isAuthenticated(user), [user]);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const renderNavLinks = () => {
    if (isProfileComplete(user)) {
      return NAV_LINKS.map((link) => {
        return (
          <Typography
            key={link.id}
            variant="body2"
            style={linkStyle}
            textAlign="center"
            color="#3152F5"
            onClick={() => navigate(link.route)}
          >
            {link.label}
          </Typography>
        );
      });
    }

    return null;
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingY="0.5rem"
        sx={{ width: '100%', backgroundColor: 'black' }}
      >
        <Link to="/" style={{ paddingLeft: '1rem' }}>
          <LogoWhite width="8rem" />
        </Link>

        {isDrawerOpen ? (
          <IconButton onClick={closeDrawer} size="large">
            <CloseWhite width={30} />
          </IconButton>
        ) : (
          <IconButton onClick={openDrawer} size="large">
            <HamburgerWhite width={30} />
          </IconButton>
        )}
      </Box>

      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={closeDrawer}
        sx={{ zIndex: 9 }}
      >
        {isAuthenticatedUser ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={'center'}
            onClick={closeDrawer}
            sx={{
              paddingTop: '6rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
              backgroundColor: '#323232'
            }}
          >
            {renderNavLinks()}
            <PrimaryButton
              style={{ marginBottom: '2rem' }}
              btnText="Sign Out"
              onClick={handleLogout}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={'center'}
            onClick={closeDrawer}
            sx={{
              paddingTop: '6rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
              backgroundColor: '#323232'
            }}
          >
            <Typography
              variant="body2"
              style={linkStyle}
              textAlign="center"
              color="#3152F5"
              onClick={() => navigate(AUTH_ROUTES.LOGIN)}
            >
              Login
            </Typography>

            <PrimaryButton
              style={{ marginBottom: '2rem' }}
              btnText="Get Started"
              onClick={() => navigate(AUTH_ROUTES.GET_STARTED)}
            />
          </Box>
        )}
      </Drawer>
    </>
  );
}

const linkStyle = {
  width: '100%',
  fontSize: 16,
  fontWeight: '600',
  paddingBottom: '2rem'
};
