import DesktopFooter from '@components/layouts/DesktopFooter';
import DesktopNavbar from '@components/layouts/DesktopNavbar';
import MobileNavbar from '@components/layouts/MobileNavbar';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box } from '@mui/material';
import { theme } from '@utils/theme';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  pageTitle: string;
  children: ReactNode;
}

export default function PageLayout({ pageTitle, children }: IProps) {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.dark
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="OwnProp"
          content="Tokenizing real-estate assets on the blockchain."
        />
        <meta
          name="description"
          content="OwnProp makes property ownership more accessible with low minimum investments and more options."
        />
        <meta
          content="OwnProp | Generate wealth by investing in fractional real estate"
          property="og:title"
        />
        <meta
          content="https://assets.website-files.com/62c74ee2c155535e38a00fc3/633201036b37c6453eb80782_Frame%203.png"
          property="og:image"
        />
        <meta
          content="OwnProp | Generate wealth by investing in fractional real estate"
          property="twitter:title"
        />
        <meta
          content="OwnProp makes property ownership more accessible with low minimum investments and more options."
          property="twitter:description"
        />
        <meta
          content="https://assets.website-files.com/62c74ee2c155535e38a00fc3/633201036b37c6453eb80782_Frame%203.png"
          property="twitter:image"
        />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
      </Helmet>

      <Box sx={{ maxWidth: '90rem' }}>
        {!isMobile ? (
          <DesktopNavbar />
        ) : (
          <Box sx={{ position: 'fixed', width: '100%', zIndex: 999 }}>
            <MobileNavbar />
          </Box>
        )}
        {children}
      </Box>
      <DesktopFooter />
    </Box>
  );
}
