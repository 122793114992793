import { useIsMobile } from '@hooks/useIsMobile';
import { Box } from '@mui/material';
import {
  GOOGLE_MAP_DEFAULT_CENTER,
  GOOGLE_MAP_DEFAULT_ZOOM
} from '@utils/constants';
import GoogleMapReact from 'google-map-react';
import { FC } from 'react';

interface IProps {
  label: string;
  location: { lat: number; lng: number };
  height?: number | string;
  width?: number | string;
  zoom?: number;
  center?: number[];
}

const GoogleMap: FC<IProps> = ({
  location,
  zoom,
  center,
  height,
  width = '100%'
}) => {
  const isMobile = useIsMobile();
  const defaultProps = {
    center: {
      lat: location?.lat,
      lng: location?.lng
    },
    zoom: zoom
  };

  return (
    <Box
      display="flex"
      width={width}
      height={isMobile ? '16rem' : height || '20rem'}
      sx={{ overflow: 'hidden', borderRadius: isMobile ? 0 : 1 }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={GOOGLE_MAP_DEFAULT_CENTER}
        defaultZoom={defaultProps.zoom || GOOGLE_MAP_DEFAULT_ZOOM}
        center={center}
        options={{
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#212121'
                }
              ]
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#212121'
                }
              ]
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#181818'
                }
              ]
            },
            {
              featureType: 'administrative.country',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#bdbdbd'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              featureType: 'poi.business',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#181818'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1b1b1b'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#2c2c2c'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8a8a8a'
                }
              ]
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#373737'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3c3c3c'
                }
              ]
            },
            {
              featureType: 'road.highway.controlled_access',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#4e4e4e'
                }
              ]
            },
            {
              featureType: 'road.local',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#000000'
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#3d3d3d'
                }
              ]
            }
          ]
        }}
      >
        <Marker lat={location?.lat} lng={location?.lng} />
      </GoogleMapReact>
    </Box>
  );
};

const Marker = ({ lat, lng }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '25px',
      height: '25px',
      backgroundColor: '#0f3beb',
      borderRadius: '50% 50% 50% 0',
      border: '4px solid #0f3beb',
      transform: 'rotate(-45deg)',
      margin: '-20px 0 0 -20px'
    }}
  ></Box>
);

export default GoogleMap;
