import { ReactComponent as LogoWhite } from '@assets/icons/logo-white.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import SecondaryButton from '@components/buttons/SecondaryButton';
import UserLoginBox from '@components/UserLoginBox';
import { useAuth } from '@contexts/AuthContext';
import { AppBar, Grid, Typography } from '@mui/material';
import { NAV_LINKS } from '@utils/constants';
import { isAuthenticated, isProfileComplete } from '@utils/helpers';
import { AUTH_ROUTES } from '@utils/routes';
import { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function DesktopNavbar() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticatedUser = useMemo(() => isAuthenticated(user), [user]);
  const isAuthFlow = location.pathname.includes('/auth/');
  const isListing = location.pathname.includes('/listings/');

  const renderNavLinks = () => {
    if (isProfileComplete(user)) {
      return NAV_LINKS.map((link) => {
        return (
          <Link
            key={link.id}
            style={{
              textDecoration: 'none',
              color: 'white',
              marginLeft: '3rem'
            }}
            to={link.route}
          >
            <Typography
              variant="button"
              sx={{
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: '#3152F5'
                }
              }}
            >
              {link.label}
            </Typography>
          </Link>
        );
      });
    }

    return null;
  };

  if (isAuthenticatedUser) {
    return (
      <>
        <AppBar
          color={isListing ? 'transparent' : 'secondary'}
          elevation={0}
          position="relative"
          style={{ zIndex: 99 }}
        >
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingX: '6rem',
              height: '6rem'
            }}
          >
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Link to="/">
                <LogoWhite />
              </Link>
              {renderNavLinks()}
            </Grid>
            <UserLoginBox />
          </Grid>
        </AppBar>
      </>
    );
  } else {
    return (
      <AppBar
        color={isListing ? 'transparent' : 'secondary'}
        elevation={0}
        position="relative"
        style={{ zIndex: 99 }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: isAuthFlow ? '2.5rem' : '6rem',
            height: '6rem'
          }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Link to="/">
              <LogoWhite />
            </Link>
          </Grid>

          {!user?.email && (
            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SecondaryButton
                btnText="Login"
                onClick={() => navigate(AUTH_ROUTES.LOGIN)}
                color="white"
              />

              <PrimaryButton
                style={{ marginLeft: '1rem' }}
                btnText="Get Started"
                onClick={() => navigate(AUTH_ROUTES.GET_STARTED)}
              />
            </Grid>
          )}
        </Grid>
      </AppBar>
    );
  }
}
