import { ReactComponent as OwnPropIcon } from '@assets/icons/ownprop-background.svg';
import { useAuth } from '@contexts/AuthContext';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { formatPrice, formatWallet } from '@utils/helpers';
import { theme } from '@utils/theme';

interface IProps {
  totalValue: number;
}

export default function PortfolioCard({ totalValue }: IProps) {
  const { user } = useAuth();
  const arbiscanLink = `https://${
    'Production' === 'Production' ? '' : 'testnet.'
  }arbiscan.io/address/${user?.wallet?.address}#tokentxns`;

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        borderRadius: '0.25rem',
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden'
      }}
    >
      <Grid item xs={8} sx={{ padding: '1.5rem' }}>
        <Typography
          variant="h5"
          sx={{ marginBottom: '0.5rem', color: '#FFFFFF' }}
        >
          Total Balance
        </Typography>

        <Typography
          variant="h2"
          mb="1rem"
          fontFamily="Hellix-Regular"
          color="#FFFFFF"
        >
          {totalValue ? formatPrice(totalValue) : '$0.00'}
        </Typography>

        <a
          href={arbiscanLink}
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <Box display="flex" flexDirection="row" style={{ cursor: 'pointer' }}>
            {user?.wallet && (
              <Typography variant="body1" mt="0.2rem" color="#FFFFFF">
                {formatWallet(user?.wallet?.address || '')} {'\u2192'}
              </Typography>
            )}

            <IconButton
              sx={{ marginTop: '0.2rem', marginLeft: '0.5rem' }}
              size="small"
            />
          </Box>
        </a>
      </Grid>

      <Grid item xs={4}>
        <OwnPropIcon
          style={{
            height: '13rem',
            zIndex: 99,
            position: 'relative',
            right: '4rem'
          }}
        />
      </Grid>
    </Grid>
  );
}
