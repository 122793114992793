import { ReactComponent as MailLetterIcon } from '@assets/icons/mail-letter-blue.svg';
import SecondaryButton from '@components/buttons/SecondaryButton';
import AuthLayout from '@components/layouts/AuthLayout';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { isAuthenticated } from '@utils/helpers';
import { ROOT_ROUTES } from '@utils/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user, email, login } = useAuth();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (isAuthenticated(user)) {
      navigate(ROOT_ROUTES.HOME);
    }
  }, [user]);

  return (
    <AuthLayout pageTitle="Check your email">
      <Box
        sx={{
          width: isMobile ? '80%' : '33rem',
          backgroundColor: '#171717',
          border: '2px solid #323232',
          padding: '2rem',
          marginLeft: isMobile ? '2.65rem' : undefined,
          marginTop: isMobile ? '3rem' : undefined,
          borderRadius: '0.25rem'
        }}
      >
        <Box
          textAlign={'center'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              width: '86px',
              height: '86px',
              backgroundColor: '#EFEFEF',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '2rem'
            }}
          >
            <MailLetterIcon />
          </Box>
        </Box>
        <Typography variant="h3" mb="1rem" textAlign={'center'}>
          Check Your Email
        </Typography>
        <Typography variant="body1" mb="1.5rem" textAlign={'center'}>
          We sent a secure login link to {email}.
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <SecondaryButton
            btnText={isSending ? 'Code Sent' : 'Send Again'}
            onClick={() => {
              setIsSending(true);
              login(email);
              setInterval(() => {
                setIsSending(false);
              }, 3000);
            }}
          />
        </Box>
      </Box>
    </AuthLayout>
  );
}
