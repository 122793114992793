import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface IProps {
  btnText: string;
  onClick: () => void;
  color?: string;
  icon?: ReactNode;
}

export default function SecondaryButton({
  btnText,
  onClick,
  color,
  icon
}: IProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <Typography
        variant="button"
        color={color ? color : 'primary'}
        mr="0.5rem"
      >
        {btnText}
      </Typography>
      {icon}
    </Box>
  );
}
