import AutocompleteField from '@components/inputs/AutocompleteInput';
import TextInput from '@components/inputs/TextInput';
import { useAxios } from '@contexts/AxiosContext';
import { Grid } from '@mui/material';
import { throttle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
interface IProps {
  control: any;
  setValue: any;
  clearErrors: any;
}
export default function AddressInput({
  control,
  setValue,
  clearErrors
}: IProps) {
  const { getLocations } = useAxios();
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [streetInputValue, setStreetInputValue] = useState('');
  const fetch = useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: (results?: any) => void
        ) => {
          const data = await getLocations(request.input);
          callback(data);
        },
        200
      ),
    [getLocations]
  );
  useEffect(() => {
    let active = true;
    if (streetInputValue === '') {
      setOptions([]);
      return undefined;
    }
    // Allow to enter manually
    setValue('address.street', streetInputValue, { shouldValidate: true });
    if (active && streetInputValue.length > 2) {
      fetch({ input: streetInputValue }, (results?) => {
        let newOptions: any = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      });
    }
    return () => {
      active = false;
    };
  }, [streetInputValue, fetch]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} mb="1.5rem">
        <AutocompleteField
          style={{ color: 'white' }}
          name={'address.street'}
          label={'Street Address'}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Street address is required'
            },
            maxLength: {
              value: 50,
              message: 'Street address is too long'
            }
          }}
          options={options}
          getOptionLabel={(option) =>
            option ? (option.street ? option.street : option) : ''
          }
          changeHandler={(event: any, newValue, onChange) => {
            setOptions(newValue ? [newValue, ...options] : options);
            if (newValue) {
              setValue('address.city', newValue.city);
              setValue('address.state', newValue.state);
              setValue('address.zipCode', newValue.zipCode);
              if (newValue.city) clearErrors('address.city');
              if (newValue.state) clearErrors('address.state');
              if (newValue.zipCode) clearErrors('address.zipCode');
              onChange(newValue.street);
            } else {
              onChange('');
            }
          }}
          inputChangeHandler={(event, newInputValue) => {
            setStreetInputValue(newInputValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.fullAddress}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <span>{option.fullAddress}</span>
                  </Grid>
                </Grid>
              </li>
            );
          }}
          open={open}
          onOpen={() => {
            streetInputValue.length > 1 ? setOpen(true) : setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      </Grid>

      <Grid item xs={6} mb="1.5rem">
        <TextInput
          name="address.city"
          placeholder="City"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'City is required'
            },
            maxLength: {
              value: 50,
              message: 'City is too long'
            }
          }}
          type="text"
          isSubmitFocus={true}
        />
      </Grid>

      <Grid item xs={12} mb="1.5rem">
        <TextInput
          name="address.suite"
          placeholder="Street Address 2"
          control={control}
          rules={{
            maxLength: {
              value: 50,
              message: 'Street Address 2 is too long'
            }
          }}
          type="text"
          isSubmitFocus={true}
        />
      </Grid>

      <Grid item xs={6} mb="1.5rem">
        <TextInput
          name="address.state"
          placeholder="State"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'State is required'
            },
            maxLength: {
              value: 50,
              message: 'State is too long'
            }
          }}
          type="text"
          isSubmitFocus={true}
        />
      </Grid>

      <Grid item xs={6} mb="1.5rem">
        <TextInput
          name="address.zipCode"
          placeholder="Zip"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Zip is required'
            },
            maxLength: {
              value: 5,
              message: 'Zip is too long'
            }
          }}
          type="number"
          isSubmitFocus={true}
        />
      </Grid>
    </Grid>
  );
}
