import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import TextInput from '@components/inputs/TextInput';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

interface IFormInput {
  routingNumber: string;
  accountNumber: string;
  amount: string;
}

export default function WithdrawalModel() {
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const { withdrawFunds } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const { control, handleSubmit, formState, setError } = useForm<IFormInput>({
    defaultValues: {
      routingNumber: '',
      accountNumber: '',
      amount: ''
    },
    mode: 'onChange'
  });

  const onSubmit = async (inputs: IFormInput) => {
    try {
      await withdrawFunds(
        inputs.accountNumber,
        inputs.routingNumber,
        inputs.amount
      );
      setShowModal(false);
    } catch (err: any) {
      setError('amount', {
        message: err.response.data.message
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: '1rem'
        }}
      >
        <PrimaryButton
          btnText={`Withdraw Funds`}
          isFullWidth
          onClick={() => setShowModal(true)}
        />
      </Box>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title=""
        children={
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: isMobile ? '90%' : '28rem',
              transform: 'translate(-50%, -50%)',
              bgcolor: '#323232',
              border: 'none',
              boxShadow: 24,
              borderRadius: '0.25rem',
              outline: 0
            }}
          >
            <IconButton
              onClick={() => {
                setShowModal(false);
              }}
              size="small"
              sx={{
                position: 'absolute',
                right: '2rem',
                top: '1rem',
                marginLeft: !isMobile ? -1 : 0
              }}
            >
              <CloseIcon width={20} stroke="white" />
            </IconButton>
            <Box
              sx={{
                padding: '3rem',
                color: 'white'
              }}
            >
              <Typography
                variant="h3"
                mb="1rem"
                color={'white'}
                textAlign="center"
              >
                Withdraw Funds
              </Typography>
              <Typography
                variant="body1"
                mb="1.5rem"
                color={'white'}
                textAlign="justify"
              >
                You can withdraw your funds by submitting your bank account /
                ACH details. Withdrawals may take 4-5 days to process.
              </Typography>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12} mb="1.5rem">
                    <TextInput
                      name="routingNumber"
                      placeholder="Routing Number"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Routing Number is required'
                        },
                        minLength: {
                          value: 9,
                          message: 'Account Number is too short'
                        },
                        maxLength: {
                          value: 9,
                          message: 'Routing Number is too long'
                        }
                      }}
                      type="number"
                      isSubmitFocus
                    />
                  </Grid>

                  <Grid item xs={12} mb="1.5rem">
                    <TextInput
                      name="accountNumber"
                      placeholder="Account Number"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Account Number is required'
                        },
                        minLength: {
                          value: 5,
                          message: 'Account Number is too short'
                        },
                        maxLength: {
                          value: 12,
                          message: 'Account Number is too long'
                        }
                      }}
                      type="number"
                      isSubmitFocus
                    />
                  </Grid>

                  <Grid item xs={12} mb="1.5rem">
                    <TextInput
                      name="amount"
                      placeholder="Amount"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Amount is required'
                        },
                        min: {
                          value: 50,
                          message: `${50} USD Minimum`
                        }
                      }}
                      type="number"
                      isSubmitFocus
                      isAmount
                    />
                  </Grid>

                  <PrimaryButton
                    btnText="Withdraw"
                    formState={formState}
                    isFullWidth
                  />
                </Grid>
              </form>
            </Box>
          </Box>
        }
      />
    </>
  );
}
