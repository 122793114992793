import { ReactComponent as UserIconWhite } from '@assets/icons/user-white.svg';
import PrimaryButton from '@components/buttons/PrimaryButton';
import StatusBadge from '@components/StatusBadge';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, IconButton, Menu, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function UserLoginBox() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuth();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };
  const open = Boolean(anchorEl);
  const ProfileIcon = UserIconWhite;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            mr="1rem"
          >
            <Typography variant="body2" fontWeight="bold" color="white">
              {user.entity?.legalName || user?.fullName || user?.email}
            </Typography>
          </Box>
        )}
        <IconButton
          size="small"
          onClick={handleClick}
          style={{ background: 'rgba(50, 50, 50, 0.05)' }}
        >
          <ProfileIcon height="1.5rem" width="1.5rem" />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: '14rem',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem'
              }}
            >
              <IconButton
                size="large"
                style={{ background: 'rgba(50, 50, 50, 0.05)' }}
              >
                <UserIconWhite height="1.5rem" width="1.5rem" />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                marginBottom: '1rem'
              }}
            >
              <StatusBadge />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0.5rem',
              marginTop: '1rem',
              marginBottom: '0.5rem'
            }}
            onClick={handleLogout}
          >
            <PrimaryButton btnText="Sign Out" />
          </Box>
        </Menu>
      </Box>
    </>
  );
}
