import StatusBadge from '@components/StatusBadge';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { IListing } from '@utils/models';
import { useNavigate } from 'react-router-dom';

interface IProps {
  listing: IListing;
}

export default function ListingCard({ listing }: IProps) {
  const property = listing?.property;
  const financials = listing?.financials;
  const title = property?.title;
  const address = property?.address;

  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const thumbnailUrl = `https://ownprop.mo.cloudinary.net/s3/public/listings/${listing?.id}`;

  let FINANCIALS = [
    { title: 'IRR', value: `${financials?.rateOfReturn}%` },
    {
      title: financials?.cashOnCash ? 'Cash' : 'Multiple',
      value: financials?.cashOnCash
        ? `${Math.round(financials?.cashOnCash * 100 * 100) / 100}%`
        : `${financials?.equityMultiple}x`
    }
  ];
  // OVERIDE ATX FUND
  const ATXFId = '6526fcb3f7fb5d577b1bc15f';
  const isATXF = listing.id === ATXFId;
  if (isATXF) {
    FINANCIALS = [
      {
        title: 'IRR',
        value: '17-20%'
      },
      {
        title: 'Fund Size',
        value: '$50M'
      }
    ];
  }

  const handleClick = () => {
    window.scrollTo({ top: 0 });
    navigate(`/listings/${listing?.id}`);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        zIndex: -1,
        height: '28rem',
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 1)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
        borderRadius: 2,
        backgroundImage: `url(${thumbnailUrl}/thumbnail_small.png)`,
        backgroundSize: 'cover',
        paddingX: isMobile ? '1rem' : '1.5rem',
        paddingY: '1.5rem'
      }}
    >
      <Grid item sx={{ maxWidth: '100%' }}>
        <Box>
          <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
            {`${address?.city || ''}, ${address?.state || ''}`}
          </Typography>
          <Typography variant="h5" color="#FFFFFF" mb="0.5rem">
            {title}
          </Typography>
        </Box>

        <Grid display="flex" mb="0.5rem">
          <Typography variant="body1" color="white">
            {FINANCIALS[0].value} {FINANCIALS[0].title} {' | '}{' '}
            {FINANCIALS[1].value} {FINANCIALS[1].title}
          </Typography>
        </Grid>
        <StatusBadge listing={listing} />
      </Grid>
    </Box>
  );
}
