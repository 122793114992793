import GlossaryModal from '@components/modals/GlossaryModal';
import { Box, Divider, Typography } from '@mui/material';
import { FINANCIALS_TERMS, TOKENOMICS_TERMS } from '@utils/glossary';
import { IListing } from '@utils/models';
import { useState } from 'react';

interface IProps {
  listing: IListing;
  title: string;
  data: any[];
  isLeft?: boolean;
}

export const FinancialsTable = ({ title, data, isLeft, listing }: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);

  let financeTerms = FINANCIALS_TERMS;
  // OVERIDE ATX FUND
  const ATXFId = '6526fcb3f7fb5d577b1bc15f';
  const isATXF = listing.id === ATXFId;
  if (isATXF) {
    financeTerms = [
      {
        title: 'Fund Size',
        description:
          'The total amount of capital that is raised or committed by investors for investment in real estate properties and related assets.'
      },
      {
        title: 'Net IRR',
        description:
          'Net Internal Rate of Return (Net IRR) in real estate refers to the rate of return that accounts for all cash flows, including fees, expenses, and taxes, providing a comprehensive measure of the profitability of a real estate investment.'
      },
      {
        title: 'Assets',
        description:
          'Assets refer to physical properties, such as land, residential or commercial buildings, and any permanent fixtures, whose primary purpose is to generate income, appreciate in value, or serve as a means of investment for their owners.'
      },
      {
        title: 'Preferred Return',
        description:
          'A contractual obligation to provide a specified rate of return to investors before the sponsor or general partner can receive their share of the profits.'
      }
    ];
  }
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Typography variant="h5" mb="0.8rem" color="#FFFFFF">
          {title}
        </Typography>
      </Box>

      <Divider
        sx={{
          marginBottom: '1.5rem',
          borderWidth: 1,
          borderColor: '#323232'
        }}
      />

      <Box mb="1.5rem">
        {data?.map(({ label, value }, idx) => (
          <Box
            key={idx}
            onClick={() => {
              setActiveIdx(idx);
              setShowModal(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Box mb="0.6rem" display="flex" justifyContent="space-between">
              <Typography
                variant="body1"
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: '#3152F5'
                  }
                }}
              >
                {label}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#FFFFFF',
                  '&:hover': {
                    color: '#3152F5'
                  }
                }}
              >
                {value ? value : 'N/A'}
              </Typography>
            </Box>

            {idx !== data.length - 1 && (
              <Divider
                sx={{
                  marginBottom: '1rem',
                  borderColor: 'rgba(255, 255, 255, 0.28)'
                }}
              />
            )}
          </Box>
        ))}
      </Box>

      <GlossaryModal
        title={
          isLeft
            ? financeTerms[activeIdx].title
            : TOKENOMICS_TERMS[activeIdx].title
        }
        description={
          isLeft
            ? financeTerms[activeIdx].description
            : TOKENOMICS_TERMS[activeIdx].description
        }
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};
