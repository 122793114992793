import FileButton from '@components/buttons/FileButton';
import PrimaryButton from '@components/buttons/PrimaryButton';
import { useAuth } from '@contexts/AuthContext';
import { useAxios } from '@contexts/AxiosContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { openExternalURL } from '@utils/helpers';
import { useEffect, useState } from 'react';

interface IProps {
  listingId: string;
}

export default function Documents({ listingId }: IProps) {
  const { queryListingFiles, getListingFileDownloadUrl } = useAxios();
  const [listingFiles, setListingFiles] = useState([]);
  const isMobile = useIsMobile();
  const { user } = useAuth();

  const fetchFiles = async () => {
    const res = await queryListingFiles(listingId);
    setListingFiles(res.files);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  if (!user || listingFiles?.length === 0) return null;

  const downloadAllFiles = () => {
    Promise.all(
      listingFiles.map(async (file) => {
        const res = await getListingFileDownloadUrl(file.key, listingId);
        openExternalURL(res?.url);
      })
    );
  };

  const renderDocuments = () => {
    return listingFiles?.map((file, idx) => (
      <Grid key={idx} item xs={12}>
        <FileButton
          fileName={file.key}
          textColor="#FFFFFF"
          onClick={async () => {
            const res = await getListingFileDownloadUrl(file.key, listingId);
            openExternalURL(res.url);
          }}
          borderColor="#323232"
          hideBox
          downloadOnly
          listingId={listingId}
        />
      </Grid>
    ));
  };

  return (
    <Box mb={isMobile ? '3rem' : '4.25rem'}>
      <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
        Documents
      </Typography>

      <Typography
        variant={isMobile ? 'h5' : 'h2'}
        mb={isMobile ? '1rem' : '1.5rem'}
        color="#FFFFFF"
      >
        Make an informed choice
      </Typography>

      <Grid
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        rowGap={0.5}
        mb="1.5rem"
      >
        {renderDocuments()}
      </Grid>

      <Box textAlign="center" width="100%">
        <PrimaryButton
          btnText="Download All Documents"
          onClick={downloadAllFiles}
          isOutlined
          isWide
        />
      </Box>
    </Box>
  );
}
