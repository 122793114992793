import { ReactComponent as WalletIcon } from '@assets/icons/wallet-input.svg';
import PaymentButton from '@components/buttons/PaymentButton';
import StripeIntegration from '@components/StripeIntegration';
import { useAxios } from '@contexts/AxiosContext';
import { Box, CircularProgress } from '@mui/material';
import { IPaymentMethod } from '@utils/models';
import { theme } from '@utils/theme';
import { useEffect, useState } from 'react';

export const PaymentMethods = () => {
  const { getPaymentMethods, removePaymentMethod } = useAxios();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPaymentMethods = async () => {
    setIsLoading(true);
    const accounts = await getPaymentMethods();
    setPaymentMethods(accounts);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const renderEmpty = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '4rem',
            borderRadius: '50%',
            width: '134px',
            height: '134px',
            backgroundColor: theme.palette.secondary.dark
          }}
        >
          <WalletIcon height={36} width={36} />
        </Box>
        <StripeIntegration
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
        />
      </Box>
    );
  };

  const renderPaymentMethods = () => {
    return (
      <>
        {paymentMethods.map((pay, idx) => (
          <PaymentButton
            key={idx}
            paymentMethod={pay}
            onRemove={async () => {
              await removePaymentMethod(pay.stripeToken);
              await fetchPaymentMethods();
            }}
            onPress={() => {}}
            variant={'Trash'}
            isFirst={idx === 0}
          />
        ))}
        <StripeIntegration
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
        />
      </>
    );
  };

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={30} sx={{ color: 'white' }} />
        </Box>
      ) : (
        <>
          {paymentMethods.length > 0 ? renderPaymentMethods() : renderEmpty()}
        </>
      )}
    </Box>
  );
};
