import { ReactComponent as HidePasswordIcon } from '@assets/icons/password-hide.svg';
import { ReactComponent as ShowPasswordIcon } from '@assets/icons/password-show.svg';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface IProps {
  name: string;
  label?: string;
  placeholder?: string;
  control: any;
  rules: any;
  mask?: string | Array<string | RegExp>;
  type?: string;
  isPhone?: boolean;
  isSubmitFocus?: boolean;
  style?: Object;
  isAmount?: boolean;
  isDarkMode?: boolean;
}

export default function TextInput({
  name,
  label,
  placeholder,
  rules,
  control,
  mask,
  type,
  isPhone,
  isSubmitFocus,
  style,
  isAmount,
  isDarkMode = true
}: IProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const isPassword = type === 'password';
  const maskChar = '*';

  // Manual unmask since InputMask as no support for unmaskValue
  const unmaskValue = (value: string) => {
    if (mask) {
      let unmaskedValue = '';
      for (let i = 0; i < value.length; i++) {
        const isValueChar =
          mask[i] === '9' || mask[i] === 'a' || mask[i] === '*';
        const isMaskChar = value[i] === maskChar;
        if (isValueChar && !isMaskChar) unmaskedValue += value[i];
      }

      return unmaskedValue;
    }

    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputMask
          mask={mask}
          maskPlaceholder={maskChar}
          value={value}
          onChange={(e: any) => {
            const currentValue = e.target.value;
            const unmask = unmaskValue(currentValue);
            onChange(unmask);
          }}
          onFocus={() => {
            if (
              isSubmitFocus &&
              document &&
              document.getElementById('sub_btn')
            ) {
              setTimeout(() => {
                document.getElementById('sub_btn')?.scrollIntoView(true);
              }, 300);
            }
          }}
          onBlur={() => setShowError(true)}
        >
          <TextField
            fullWidth
            style={style}
            color="primary"
            variant="standard"
            label={label}
            placeholder={placeholder}
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            error={showError && value?.length !== 0 && !!error}
            helperText={
              showError && value?.length !== 0 && error ? error.message : null
            }
            sx={{
              '& :hover:is(.MuiInput-underline):before': {
                borderBottomColor: isDarkMode
                  ? 'white !important'
                  : 'black !important'
              },
              '& .MuiInput-underline:before': {
                borderBottomColor: isDarkMode ? 'white' : 'black'
              }
            }}
            InputLabelProps={{
              sx: {
                color: isDarkMode ? '#989898' : 'black'
              }
            }}
            InputProps={{
              sx: {
                fontFamily: 'Hellix-Regular',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                color: isDarkMode ? 'white' : 'black'
              },
              startAdornment: isPhone ? (
                <InputAdornment position="start">+1</InputAdornment>
              ) : (
                isAmount && (
                  <InputAdornment position="start">
                    {' '}
                    <Typography variant="body2" color="white">
                      $
                    </Typography>
                  </InputAdornment>
                )
              ),
              endAdornment:
                isPassword ||
                (isAmount && (
                  <InputAdornment
                    position="end"
                    sx={{ padding: '0', pb: isAmount ? '0' : '1rem' }}
                  >
                    {isPassword && (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <ShowPasswordIcon />
                        ) : (
                          <HidePasswordIcon />
                        )}
                      </IconButton>
                    )}

                    {isAmount && (
                      <Typography variant="body2" color="white">
                        USD
                      </Typography>
                    )}
                  </InputAdornment>
                ))
            }}
          />
        </InputMask>
      )}
    />
  );
}
