export const STATISTICS_TERMS = [
  {
    title: 'Projected IRR',
    description:
      'The Internal Rate of Return (IRR) is the rate at which each invested dollar is projected to grow for each period it is invested. It differs from other metrics in that it accounts for the concept of the “time value of money”, or the fact that a dollar received and reinvested elsewhere today is worth more than a dollar expected to be received and reinvested next year. The IRR is one of the best ways for an investor to compare various investments based on their yield while holding other variables constant.'
  },
  {
    title: 'Cash Yield',
    description:
      'The cash-on-cash return calculates the cash income earned on the cash invested in a property. It’s sometimes also referred to as the cash yield. Cash-on-cash measures the return on the actual cash invested, whereas standard ROI take into account the total return on investment.'
  },
  {
    title: 'Equity Multiple',
    description:
      'The equity multiple is defined as the total cash distributions received from an investment, divided by the total equity invested. Essentially, it’s how much money an investor could make on their initial investment. An equity multiple less than 1.0x means you are getting back less cash than you invested. An equity multiple greater than 1.0x means you are getting back more cash than you invested. For instance, an equity multiple of 2.50x means that for every $1 invested into a CRE project, an investor could be expected to get back $2.50.'
  },
  {
    title: 'Target Hold',
    description:
      'The target hold is the time between when the investment is made and when the property sells. Since real estate investments are illiquid, investors are unable to sell their investment before the end of that hold period, unlike public stocks which can be sold at any time. Sponsors generally target a hold period of 3-5 years, although some investments target as long as 10 years.'
  }
];

export const LISTING_TYPES = [
  {
    title: 'Fixed Income',
    description:
      'Fixed income commercial real estate (CRE) investments are often considered one of the more stable types of real estate investment. They are typically fully leased to high credit tenants, generally have more stabilized returns, and require little to no major renovations. These properties are often located in highly desirable locations in major markets. With the increased stability and predictability of its cash flows, core holdings are generally not seen as risky as other CRE investments, but they tend to target lower annualized returns to investors.'
  },
  {
    title: 'Core Plus',
    description:
      'The term "core-plus" was originally defined as "core plus leverage". Leverage is the use of borrowed capital to purchase and/or increase the potential return of an investment. Core-plus properties may require some improvements in order to increase net operating income (NOI) by either decreasing operating costs, rising rents, and/or renting to a higher caliber of tenant. Core-plus commercial real estate (CRE) investments are often known as “growth and income” investments. The cash flow may be less predictable, but they often target a higher rate of return than core investments.'
  },
  {
    title: 'Value Add',
    description:
      'Properties are considered “value-add” when they have some level of management or operational problems, require some physical improvements, and/or suffer from capital constraints. By making physical improvements–e.g. remodeling the apartments in a multifamily property, installing more energy-efficient heating systems in a medical office, adding cold storage to an industrial space–to improve the quality of tenants and/or lowering operating expenses, the owner can hope to increase the property’s net operating income (NOI). This, in turn, can increase the “cap rate” of the property and, ultimately, the overall value of the building when it sells. Given the amount of work needed that would enable the property owner to command higher rents, value-add properties tend to target higher potential returns to compensate investors for the increased amount of risk.'
  },
  {
    title: 'Opportunistic',
    description:
      'Opportunistic real estate investments are often considered one of the higher risk/reward types of investment opportunities, often requiring major development work. Opportunistic properties tend to need significant rehabilitation or are being built from the ground up (also known as development). Due to the increased level of risk, they may provide higher overall returns to investors than other types of CRE projects,, but they have little to no in-place cash flow at the time of acquisition and typically have a more complicated business plan.'
  }
];

export const PROPERTY_TYPES = [
  {
    title: 'Commercial',
    description:
      'Office buildings come in all shapes and sizes, from 100-story glass and steel towers in Manhattan to a one-story bricker in Des Moines. Office properties are generally distinguished by height, location, and use. Employment growth is generally a big demand driver for office, especially in those industries that are heavy office users like finance, insurance, or tech.'
  },
  {
    title: 'Multifamily',
    description:
      '​In the past, multifamily properties (think apartment buildings) were more often grouped with other residential assets like single-family homes than rolled in with commercial real estate. However, multifamily assets now account for the second-largest share of institutional investors’ holdings behind the office sector, making up roughly 20% of the U.S. commercial real estate stock. Population growth—especially among demographics more likely to be renters—has a big effect on demand for multifamily. With the number of renters in the U.S. increasing, the range in apartment property offerings has expanded to fit their varied needs and tastes. Size, density, location, and amenities break up the multifamily asset class.'
  },
  {
    title: 'Hospitality',
    description:
      'The main type of property within the hospitality moniker is hotels. Hotels are defined primarily by the services and amenities that they offer, but also by the “flag” or operating brand of the property. This includes brands like Holiday Inn, Hilton, and Marriott, among others. Unsurprisingly, travel and tourism activity in a specific market drive the demand for hospitality assets in that location.'
  },
  {
    title: 'Industrial',
    description:
      'The industrial sector is arguably the least glamorous commercial real estate asset class. There are no elaborate architectural design features, resort-like amenities, or high-profile addresses. Instead, industrial real estate is intended to provide practical and efficient space to businesses that prioritize function over form. Industrial buildings are suitable for a variety of uses such as manufacturing, research and development, and the storage and distribution of goods. Demand for industrial assets is heavily dependent on the overall strength of the economy and growth—the more people shopping, shipping, and storing, generally the more industrial space is needed. Warehouse space in particular is also affected by export and import activity.'
  },
  {
    title: 'Retail',
    description:
      'Retail property types range from single-tenant buildings, like a stand-alone pharmacy, to full shopping centers with dozens or even hundreds of tenants. Retail centers that have more than a single tenant are grouped by size and tenant type. Demand for retail space is driven by consumer spending habits and trends.'
  }
];

export const FINANCIALS_TERMS = [
  {
    title: 'Net Asset Value',
    description: 'Net asset value (NAV)...'
  },
  {
    title: 'Net Operating Income',
    description:
      'Net operating income (NOI) equals all revenue from the property minus all operating expenses. In addition to rent, a property might generate revenue from parking and/or service fees such as laundry, housecleaning services, pet rent, and more. Operating expenses include the costs of running and maintaining the building and its grounds, including insurance, property management fees, legal fees, utilities, property taxes, repairs and janitorial fees. NOI is used to assess the initial value of the property by forecasting its cash flows.'
  },
  {
    title: 'Cap Rate',
    description:
      'The capitalization, or “cap”, rate is used in commercial real estate to indicate the rate of return that is expected to be generated on a real estate investment property. The calculation is based on the Net Operating Income the property generates divided by the Purchase Price. Lower cap rates (3-5%) generally point to safer / less risky investments and are most commonly found in primary cities (L.A., New York, Chicago) with stabilized, trophy assets. Higher cap rate deals are typically higher on the risk profile and are more likely to be in secondary or tertiary markets.'
  },
  {
    title: 'Loan-to-Value',
    description:
      'Loan-to-value ratio (LTV) is calculated by dividing the loan amount over the appraised property value. Typically, offerings with high LTV ratios are higher risk as the Sponsor will be liable for paying the loan service irrespective of whether the asset is or isn’t meeting its performance targets.'
  }
];

export const TOKENOMICS_TERMS = [
  {
    title: 'Ticker Symbol',
    description:
      'The ticker symbol or shortened name (typically in capital letters) that refer to a coin on a trading platform. For example: ETH'
  },
  {
    title: 'Market Cap',
    description:
      'The market capitalization (or market cap) refers to a metric that measures the relative size of a cryptocurrency. It is calculated by multiplying the current market price of a particular coin or token with the total number of coins in circulation. Market Cap = Current Price x Circulating Supply'
  },
  {
    title: 'Total Supply',
    description:
      'The total supply refers to the number of cryptocurrency coins or tokens that are publicly available and circulating in the market.'
  },
  {
    title: 'Token Price',
    description:
      'The token price is the current price at which an asset or service can be bought or sold. The market price of an asset or service is determined by the forces of supply and demand. The price at which quantity supplied equals quantity demanded is the market price.'
  }
];
