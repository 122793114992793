import PrimaryButton from '@components/buttons/PrimaryButton';
import CheckboxInput from '@components/inputs/CheckboxInput';
import TextInput from '@components/inputs/TextInput';
import AuthLayout from '@components/layouts/AuthLayout';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { Box, Grid, Typography } from '@mui/material';
import { EMAIL_REGEX } from '@utils/constants';
import { openExternalURL } from '@utils/helpers';
import { AUTH_ROUTES, EXTERNAL_ROUTES } from '@utils/routes';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  email: string;
  firstName: string;
  lastName: string;
  isAgreedTerms: boolean;
}

export default function GetStarted() {
  const navigate = useNavigate();
  const { signUp, user, setEmail } = useAuth();
  const [gclid, setGclid] = useState(null);
  const [utmSource, setUtmSource] = useState(null);
  const [utmMedium, setUtmMedium] = useState(null);
  const [utmCampaign, setUtmCampaign] = useState(null);
  const [utmContent, setUtmContent] = useState(null);
  const [utmTerm, setUtmTerm] = useState(null);
  const [inviteCode, setInviteCode] = useState(null);
  const isMobile = useIsMobile();
  const { control, handleSubmit, formState, setError } = useForm<IFormInput>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      isAgreedTerms: false
    },
    mode: 'onChange'
  });

  function getCookie(name: string) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) return parts.pop().split(';').shift();
  }

  useEffect(() => {
    const gclid = getCookie('gclid');
    const utmSource = getCookie('utmSource');
    const utmMedium = getCookie('utmMedium');
    const utmCampaign = getCookie('utmCampaign');
    const utmContent = getCookie('utmContent');
    const utmTerm = getCookie('utmTerm');
    const inviteCode = getCookie('inviteCode');

    if (gclid) setGclid(gclid);
    if (utmSource) setUtmSource(utmSource);
    if (utmMedium) setUtmMedium(utmMedium);
    if (utmCampaign) setUtmCampaign(utmCampaign);
    if (utmContent) setUtmContent(utmContent);
    if (utmTerm) setUtmTerm(utmTerm);
    if (inviteCode) setInviteCode(inviteCode);
  }, []);

  useEffect(() => {
    if (user?.isEmailVerified) {
      return navigate(AUTH_ROUTES.ACCREDITATION);
    }
  }, [user]);

  const onSubmit = async (inputs: IFormInput) => {
    let payload;
    try {
      if (
        gclid ||
        utmSource ||
        utmMedium ||
        utmCampaign ||
        utmContent ||
        utmTerm ||
        inviteCode
      ) {
        payload = {
          ...inputs,
          gclid,
          utmSource,
          utmMedium,
          utmCampaign,
          utmContent,
          utmTerm,
          inviteCode
        };
      } else {
        payload = inputs;
      }

      await signUp(payload);
      setEmail(payload.email);
      window.analytics.track('Signup Started');
      navigate(AUTH_ROUTES.VERIFY_EMAIL);
    } catch (err: any) {
      setError('email', {
        message: err.response.data.message
      });
    }
  };

  return (
    <AuthLayout pageTitle="Get Started">
      <Box
        sx={{
          width: isMobile ? '100%' : '30rem',
          backgroundColor: '#171717',
          border: '2px solid #323232',
          padding: '2rem',
          borderRadius: '0.25rem'
        }}
      >
        <Typography variant="h3" mb="1rem" textAlign={'center'}>
          Get Started
        </Typography>
        <Typography variant="body1" mb="1.5rem">
          Join thousands of investors generating passive income.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item xs={isMobile ? 12 : 5.5} mb="1.5rem">
                <TextInput
                  name="firstName"
                  placeholder="First"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'First name is required'
                    },
                    maxLength: {
                      value: 50,
                      message: 'First name is too long'
                    }
                  }}
                  isSubmitFocus={true}
                />
              </Grid>

              <Grid item xs={isMobile ? 12 : 5.5} mb="1.5rem">
                <TextInput
                  name="lastName"
                  placeholder="Last"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Last name is required'
                    },
                    maxLength: {
                      value: 50,
                      message: 'Last name is too long'
                    }
                  }}
                  isSubmitFocus={true}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} mb="1.5rem">
              <TextInput
                name="email"
                placeholder="Email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required'
                  },
                  maxLength: {
                    value: 50,
                    message: 'Email is too long'
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Email is invalid'
                  }
                }}
                type="email"
                isSubmitFocus={true}
              />
            </Grid>

            <CheckboxInput
              name="isAgreedTerms"
              label={
                <Typography variant="body1">
                  I agree to the{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => openExternalURL(EXTERNAL_ROUTES.TERMS)}
                  >
                    Terms of Service
                  </span>{' '}
                  and{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => openExternalURL(EXTERNAL_ROUTES.PRIVACY)}
                  >
                    Privacy Policy
                  </span>
                  .
                </Typography>
              }
              control={control}
            />
          </Grid>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <PrimaryButton
              btnText="Next"
              formState={formState}
              isWide={!isMobile}
              isFullWidth={isMobile}
            />
          </Box>
        </form>

        <Typography variant="body1" mt="1.5rem" textAlign={'center'}>
          Already have an account?{' '}
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => navigate(AUTH_ROUTES.LOGIN)}
          >
            Login
          </span>
        </Typography>
      </Box>
    </AuthLayout>
  );
}
