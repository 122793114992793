import PrimaryButton from '@components/buttons/PrimaryButton';
import { useIsMobile } from '@hooks/useIsMobile';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatString, openExternalURL, shortenNumber } from '@utils/helpers';
import { ISponsor } from '@utils/models';

interface IProps {
  sponsor: ISponsor;
}

export default function TheSponsor({ sponsor }: IProps) {
  const isMobile = useIsMobile();
  let PROFILE = [
    {
      label: 'Under Management',
      value: `${formatString(
        shortenNumber(sponsor?.profile?.totalAssets, 2),
        '$'
      )}`
    },
    {
      label: `Total Distributions`,
      value: `${formatString(
        shortenNumber(sponsor?.profile?.totalReturns, 2),
        '$'
      )}`
    },
    {
      label: 'Track Record',
      value: `${formatString(sponsor?.profile?.trackRecord, null, 'Yrs')}`
    },
    {
      label: `Successful Deals`,
      value: `${formatString(sponsor?.profile?.numDeals)}`
    }
  ];
  // OVERIDE ATX
  const isATX = sponsor.id === '633d980bdca4fbf570444dfd';
  if (isATX) {
    PROFILE = [
      { value: '$2.1B', label: 'Total Exit Value' },
      { value: '$2.0', label: 'Net Equity Multiple' },
      { value: '21.9%', label: 'Net IRR' },
      { value: '100+', label: 'Transactions*' }
    ];
  }

  return (
    <Box mb={isMobile ? '3rem' : '4.25rem'}>
      <Typography variant="overline" color="rgba(255, 255, 255, 0.5)">
        The Sponsor
      </Typography>

      <Typography
        variant={isMobile ? 'h5' : 'h2'}
        mb={isMobile ? '1rem' : '1.5rem'}
        color="#FFFFFF"
      >
        Meet the Team
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img
            src={sponsor?.imageUrl}
            style={{
              height: 60,
              width: 60,
              borderRadius: 50,
              padding: 4,
              marginRight: '0.8rem'
            }}
          />

          <Typography variant="h5" color="#FFFFFF">
            Deal offered by {sponsor?.name}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1" color="#FFFFFF" mb="1.5rem">
        {sponsor?.description}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          mb: '1.5rem'
        }}
      >
        {PROFILE.map(({ value, label }, index) => (
          <Box key={index}>
            <Typography variant="h3" color="#FFFFFF">
              {value ? value : 'N/A'}
            </Typography>

            <Typography
              variant="body1"
              color="rgba(255, 255, 255, 0.5)"
              fontWeight={500}
            >
              {label}
            </Typography>
          </Box>
        ))}

        {isATX && (
          <Typography
            variant="subtitle1"
            color="rgba(255, 255, 255, 0.5)"
            my="1rem"
          >
            *The track record detailed here is the investment results of Avesta
            Capital Partners LLC (also known as InvestRes) (“InvestRes”).
          </Typography>
        )}
      </Box>

      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <PrimaryButton
          btnText="View Sponsor Website"
          onClick={() => openExternalURL(sponsor?.website)}
          isOutlined
          isWide
        />
      </Box>
    </Box>
  );
}
